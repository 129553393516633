import Button from '../button'
import { Formik } from 'formik'
import { studentLoginSchema } from '../../schema'
import logo from '../../assets/icons/logo.png'
import waiting from '../../assets/icons/waiting.png'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import app_api from '../../config/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const StudentLoginWaiting = () => {
  const navigate = useNavigate()
  const [moldKey, setMoldKey] = useState(() => {
    try {
      const storedKey = localStorage.getItem('moldKey')
      return storedKey ? JSON.parse(storedKey) : null
    } catch {
      return null
    }
  })

  useEffect(() => {
    try {
      const storedKey = localStorage.getItem('moldKey')
      setMoldKey(storedKey ? JSON.parse(storedKey) : null)
    } catch {
      setMoldKey(null)
    }
  }, [])

  const checkStudentApprovedOrNot = async () => {
    const data = JSON.parse(localStorage.getItem('moldKey'))
    const username = data?.username
    if (username) {
      try {
        const res = await app_api.get(`login-session/username/${username}`)
        console.log(res?.data?.isApproved)
        if (res?.data?.isApproved) {
          navigate('/my-exams')
        }
      } catch (err) {
        console.error('Error checking approval status:', err)
      }
    }
  }

  useEffect(() => {
    // Initial check
    checkStudentApprovedOrNot()

    // Poll every 5 seconds
    const interval = setInterval(() => {
      checkStudentApprovedOrNot()
    }, 5000)

    // Cleanup on component unmount
    return () => clearInterval(interval)
  }, [])

  const tryToLoginAgainHandler = ()=>{
    localStorage.clear()
    navigate('/login')
  }

  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex flex-col w-full md:w-[45%] h-full">
          <div className="h-100 w-full min-h-screen p-5">
            <div className="mt-3 flex items-center justify-center">
              <img className="h-20" src={logo} alt="Your Company" />
            </div>
            <div className="h-[90%]">
              <div className="flex justify-center items-center">
                <img className="" src={waiting} alt="Your Company" />
              </div>
              <div className='flex mt-3 justify-center items-center'>
                <Button
                  type="button"
                  className="text-center w-[50%]"
                  onClick={tryToLoginAgainHandler}
                >
                  Try to Login Again
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 md:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://ik.imagekit.io/bizwem110d/mold/Illustrations_jZRHXfAlAZ.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1671184411043"
            alt=""
          />
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </>
  )
}

export default StudentLoginWaiting
