import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import app_api from '../config/api'

export const Logout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    LogoutUser()
  }, [])

  const LogoutUser = () => {
    let user = JSON.parse(localStorage.getItem('moldKey'))
    if (user.email || user.username) {
      let payload = {};
      user.username ? payload.username = user.username : payload.email = user.email 
      app_api
        .post('/contact/logout', payload)
        .then((res) => {
          localStorage.removeItem('moldKey');
          navigate("/login")
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  
}
