import React from 'react'
import { familyStudentSchema } from '../../schema'
import { toast } from 'react-toastify'
import BigSlideover from '../../components/big-slideover'
import Select from 'react-select'
import Input from '../../components/input'
import { Switch } from '@headlessui/react'
import { Formik } from 'formik'
import moment from 'moment'
import Button from '../../components/button'
import app_api from '../../config/api'
const AddStudentModal = (props) => {
  const { type, modalState, data, edit_id } = props.modal
  const {setError, classNames, setModal, studentEnabled, cleanModalData, getContactData, renderEditModal, onFamilyEditOpen, family, grade, location, handleStudentCheckedChanges} = props
  return (
    <Formik
      initialValues={data}
      validationSchema={familyStudentSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (type === 'add') {
          app_api
            .post('/student-master', {
              studentsFirstName: values.firstName,
              studentsLastName: values.lastName,
              studentsEmail: values.email,
              familyId: values.selectFamily,
              studentPhone: values.mobileNumber,
              grade: values.grade,
              center: values.center,
              type: 'family',
              archive: 0,
              active: studentEnabled.toString(),
              createdBy: JSON.parse(localStorage.getItem('moldKey')).email,
            })
            .then((res) => {
              toast.success('Student Added Successfully')
              cleanModalData()
              getContactData()
              setSubmitting(false)
              resetForm()
            })
            .catch((err) => {
              setError(err?.response?.data?.message || 'error creating student')
              if (err.response.data.statusCode == 424) {
                toast.error('Student Already Exist')
                // cleanModalData()
                getContactData()
                setSubmitting(false)
              } else {
                toast.error(err.response.data.message)
                cleanModalData()
                getContactData()
                setSubmitting(false)
              }
            })
        } else {
          app_api
            .patch(`/student-master/${edit_id}`, {
              studentsFirstName: values.firstName,
              studentsLastName: values.lastName,
              studentsEmail: values.email,
              center: values.center,
              grade: values.grade,
              studentPhone: values.mobileNumber,
              active: studentEnabled,
              updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
            })
            .then((res) => {
              if (res.data.active == 'Active') {
                toast.success('Student Activated Successfully')
              } else {
                toast.success('Student updated successfully')
              }
              getContactData()
              setSubmitting(false)
              resetForm()
              renderEditModal == true
                ? onFamilyEditOpen(res.data.familyId)
                : cleanModalData()
            })
            .catch((err) => {
              toast.error(err.response.data.message)
            })
        }
      }}
    >
      {({
        handleBlur,
        Formik,
        handleChange,
        handleSubmit,
        setFieldTouched,
        setValues,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }) => (
        <BigSlideover
          open={modalState}
          setOpen={() => {
            setModal((prev) => ({ ...prev, modalState: false }))
            cleanModalData()
          }}
          title={type === 'add' ? 'Add Student' : 'Edit Student'}
          handleClick={''}
          data={''}
        >
          <form onSubmit={handleSubmit} noValidate>
            <div className="text-left">
              <div>
                {/* Start Existing Parent */}
                <div className="mt-4">
                  <div className="flex">
                    <label
                      htmlFor="selectFamily"
                      className="mb-1 text-sm text-gray-700 font-medium"
                    >
                      Select Family
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="selectFamily"
                    id="selectFamily"
                    isSearchable
                    loadingMessage="Getting Family Details..."
                    placeholder="Select Family..."
                    value={
                      values.selectFamily
                        ? family?.map((l) => ({
                            value: family?.find(
                              (e) => e.id == values.selectFamily
                            )?.id,
                            label:
                              family?.find((e) => e.id == values.selectFamily)
                                ?.lastName +
                              ', ' +
                              family?.find((e) => e.id == values.selectFamily)
                                ?.firstName,
                          }))
                        : null
                    }
                    options={family.map((l) => ({
                      ...l,
                      label: l.lastName + ', ' + l.firstName,
                      value: l.id,
                    }))}
                    isDisabled={type == 'edit'}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        selectFamily: option.value,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={() => setFieldTouched('selectFamily', false)}
                  />
                </div>
                {touched.selectFamily && (
                  <p className="text-red-700 error_msg">
                    {errors.selectFamily}
                  </p>
                )}
                <div className="mt-4"></div>
                <div className="inline-grid grid-cols-1  md:grid-cols-2 gap-4 w-full">
                  <div>
                    <div className="flex">
                      <label className="mb-1 text-sm text-gray-700 font-medium">
                        Student First Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="firstName"
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      className="mb-2"
                      placeholder="Enter First Name"
                      onFocus={() => setFieldTouched('firstName', false)}
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.firstName && (
                      <p className="text-red-700 error_msg mb-2">
                        {errors.firstName}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex">
                      <label className="mb-1 text-sm text-gray-700 font-medium">
                        Student Last Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Input
                      name="lastName"
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      className="mb-2"
                      onFocus={() => setFieldTouched('lastName', false)}
                      placeholder="Enter Last Name"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                    />
                    {touched.lastName && (
                      <p className="text-red-700 error_msg mb-2">
                        {errors.lastName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="inline-grid grid-cols-1  md:grid-cols-2 gap-4 w-full">
                  <div>
                    <div className=""></div>
                    <label
                      htmlFor="contactId"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Student's Phone No.
                    </label>
                    <Input
                      name="mobileNumber"
                      // label="Student's Phone No."
                      onFocus={() => setFieldTouched('mobileNumber', false)}
                      value={values.mobileNumber}
                      placeholder="Enter student phone number"
                      // onChange={(event) =>
                      //   onModalHandleChange(event.target.name, event.target.value)
                      // }
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          mobileNumber: e.target.value,
                        })
                      }}
                    />
                    {touched.mobileNumber && (
                      <p className="text-red-700 mb-2 error_msg">
                        {errors.mobileNumber}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="">
                      <label className="text-sm font-medium text-gray-700">
                        Select Grade
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="grade"
                      id="grade"
                      isSearchable
                      loadingMessage="Getting grade..."
                      placeholder="Select a grade..."
                      value={
                        values.grade
                          ? grade?.map((l) => ({
                              value: grade?.find((e) => e.id == values.grade)
                                ?.id,
                              label: grade?.find((e) => e.id == values.grade)
                                ?.grade,
                            }))
                          : null
                      }
                      options={grade?.map((l) => ({
                        ...l,
                        label: l.grade,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          grade: option.value,
                          vce: option.vce,
                        })
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      onFocus={() => setFieldTouched('grade', false)}
                      maxMenuHeight={110}
                    />
                    {touched.grade && (
                      <p className="text-red-700 error_msg mb-2 mt-2">
                        {errors.grade}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4 inline-grid grid-cols-1  md:grid-cols-2 gap-4 w-full">
                  <div className="">
                    <div className="flex">
                      <label
                        htmlFor="selectFamily"
                        className="mb-1 text-sm text-gray-700 font-medium"
                      >
                        Email
                      </label>
                      <span className="text-red-700 ml-1"></span>
                    </div>
                    <Input
                      name="email"
                      autoComplete="off"
                      onChange={handleChange}
                      value={values.email}
                      onFocus={() => setFieldTouched('email', false)}
                      className="mb-2"
                      placeholder="Enter Email"
                    />

                    {touched.email && (
                      <p className="text-red-700 error_msg">{errors.email}</p>
                    )}
                  </div>
                  <div className="">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Location
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="center"
                      id="center"
                      isSearchable
                      loadingMessage="Getting location..."
                      placeholder="Select a location..."
                      isMulti={true}
                      value={
                        values.center
                          ? values?.center?.map((l) => ({
                              value: l,
                              label: location.find((e) => e.id == l)?.name,
                            }))
                          : null
                      }
                      options={location.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          center: option.map((l) => l.value),
                        })
                      }}
                      isClearable={true}
                      onFocus={() => setFieldTouched('center', false)}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                    {touched.center && (
                      <p className="text-red-700 error_msg">{errors.center}</p>
                    )}
                  </div>
                </div>

                {/* End Existing Parent */}
              </div>
              <div className="mt-4">
                <Switch.Group
                  as="div"
                  className="flex items-center justify-start"
                >
                  <span className="flex flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Activate Student
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={studentEnabled == 'Active'}
                    onChange={() => {
                      handleStudentCheckedChanges()
                    }}
                    className={classNames(
                      studentEnabled == 'Active'
                        ? 'bg-indigo-600'
                        : 'bg-gray-200',
                      'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        studentEnabled == 'Active'
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
              {type == 'edit' && (
                <>
                  <div className="mt-8 mb-4">
                    <div className="flex mb-4">
                      <p className="font-bold text-xl">Class</p>
                      <d className="text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center mt-1">
                        <p>{data?.class?.length}</p>
                      </d>
                    </div>                    
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-left text-gray-500">
                            <thead class="text-gray-700 bg-stone-300">
                                <tr>
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      Class Name
                                    </th>
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      Subject
                                    </th>
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      Classes Attended
                                    </th>
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      Start Date
                                    </th>                                    
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      End Date
                                    </th>                                    
                                </tr>
                            </thead>
                            <tbody>
                            {data?.class?.map((c, idx) => {
                              return (
                              <tr class="bg-white border-b hover:bg-gray-50">
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {c?.className?.className}
                                  </td>
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {c?.className?.subject?.subject}
                                  </td>
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {data?.att?.filter((index) => index.attendance === 1).length}
                                  </td>
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {moment(c?.className?.sessions[0]?.date).format("DD-MM-YYYY")}
                                  </td>
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {moment(c?.className?.sessions[c.className.sessions.length - 1]?.date).format("DD-MM-YYYY")}
                                  </td>
                                </tr>
                          )})}                                
                            </tbody>
                        </table>
                    </div>
                  </div>
                  <div className="mt-8 mb-4">
                    <div className="flex mb-4">
                      <p className="font-bold text-xl">Attendance</p>
                      <d className="text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center mt-1">
                        <p>
                          {
                            data.att.filter((index) => index.attendance == 1)
                              .length
                          }
                        </p>
                      </d>
                    </div>
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-left text-gray-500">
                            <thead class="text-gray-700 bg-stone-300">
                                <tr>
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      Class Name
                                    </th>
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      Subject
                                    </th>
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      Date of attended class
                                    </th>
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      Start Time
                                    </th>                                    
                                    <th scope="col" class="px-6 py-3 min-w-[260px]">
                                      End Time
                                    </th>                                    
                                </tr>
                            </thead>
                            <tbody>
                            {data?.att
                        ?.filter((index) => index.attendance == 1)
                        .map((c, idx) => {
                              return (
                              <tr class="bg-white border-b hover:bg-gray-50">
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {c?.class?.className}
                                  </td>
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {c?.class?.subject.subject}
                                  </td>
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {moment(c.date).format('DD-MM-YYYY')}
                                  </td>
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {moment(c?.from_time).format('h:mm A')}
                                  </td>
                                  <td class="px-6 py-4 min-w-[260px]">
                                    {moment(c?.end_time).format('h:mm A')}
                                  </td>
                                </tr>
                          )})}                                
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="mt-1 w-full border">
                      <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                        <div class="grid grid-cols-5 gap-4">
                          <div className="flex justify-center mt-2 font-semibold">
                            Class Name
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Subject
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Date of attended class
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            Start Time
                          </div>
                          <div className="flex justify-center mt-2 font-semibold">
                            End Time
                          </div>
                        </div>
                      </div>
                      {data?.att
                        ?.filter((index) => index.attendance == 1)
                        .map((c, idx) => (
                          <div className="w-full h-10 bg-gray-100 shadow-sm">
                            <div class="grid grid-cols-5 gap-4">
                              <span className="cursor-pointer hover:text-indigo-800">
                                <div className="flex justify-center mt-2">
                                  {c?.class?.className}
                                </div>
                              </span>
                              <div className="flex justify-center mt-2">
                                {c?.class?.subject.subject}
                              </div>
                              <div className="flex justify-center mt-2">
                                {moment(c.date).format('DD-MM-YYYY')}
                              </div>
                              <div className="flex justify-center mt-2">
                                {moment(c?.from_time).format('h:mm A')}
                              </div>
                              <div className="flex justify-center mt-2">
                                {moment(c?.end_time).format('h:mm A')}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div> */}
                  </div>
                </>
              )}
              <div className="flex justify-end gap-2">
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="button"
                    className={'bg-red-500 hover:bg-red-700'}
                    onClick={() => {
                      {
                        setModal((prev) => ({ ...prev, modalState: false }))
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting' : 'Submit'}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </BigSlideover>
      )}
    </Formik>
  )
}
export default AddStudentModal