import * as Yup from 'yup'
const moment = require('moment')

export const loginSchema = Yup.object({
  username_or_email: Yup.string().required('Email or Username is required'),
  password: Yup.string().required('Password is required'),
})

export const studentLoginSchema = Yup.object({
  username: Yup.string().required('Username is required').min(3, 'Username must be at least 3 characters'),
  student_password: Yup.string().required('Student password is required'),
  manager_password: Yup.string().required('Manager password is required'),
})

export const ResetPasswordSchema = Yup.object({
  currentPassword: Yup.string().required('Please enter your current password'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})',
      'Password must be 10 characters long and must contain 1 Special Character, 1 number and 1 Letter should be capital'
    ),
  confirmPassword: Yup.string()
    .required('Please enter the password again to confirm')
    .oneOf([Yup.ref('password'), null], 'Password must match'),
})

export const contactSchema = Yup.object({
  parentFirstName: Yup.string()
    .required("Please enter parent's first name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentLastName: Yup.string()
    .required("Please enter parent's last name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentEmail: Yup.string()
    .email('Please enter a valid email address')
    .required("Parent's email is required"),
  parentMobileNo: Yup.string().required("Parent's mobile number is required").matches(/^[0-9\s]+$/, "Only numbers are allowed").matches(/^04.*$/, 'Mobile number should start with 04').test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  studentsFirstName: Yup.string()
    .required('Please enter student first name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  studentsLastName: Yup.string()
    .required('Please enter student last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
})

export const familyStudentSchema = Yup.object({
  selectFamily: Yup.number()
    .required("Please select family"),
    firstName: Yup.string()
    .required("Please enter student first name"),
    lastName: Yup.string()
    .required("Please enter student last name"),
    email: Yup.string().email('Please enter a valid email address').nullable(),
    grade: Yup.string().required('Please select the grade'),
    mobileNumber: Yup.string()
  .matches(/^[0-9\s]+$/, 'Only numbers are allowed')
  .matches(/^04.*$/, 'Mobile number should start with 04')
  .test('exact 10', 'Mobile number should be 10 digits', (value) => {
    if (!value) {
      return true; 
    }
    const numericValue = value.split(' ').join('');
    return numericValue.length === 10;
  }).nullable(),
})

export const independentStudentSchema = Yup.object({
  firstName: Yup.string().required('Please enter first name'),
  lastName: Yup.string().required('Please enter last name'),
  email: Yup.string().email('Please enter a valid email address').required('Please enter email address'),
  studentPhone: Yup.string().required('Please enter phone number').matches(/^[0-9\s]+$/, "Only numbers are allowed").matches(/^04.*$/, 'Mobile number should start with 04').test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  // address1: Yup.string().required('Please enter your address'),
  parentFirstName: Yup.string().required('Please enter parent first name'),
  parentLastName: Yup.string().required('Please enter parent last name'),
  parentEmail: Yup.string().email('Please enter valid email address').required('Please enter email address'),
  parentPhone: Yup.string().required('Please enter parent phone number').matches(/^[0-9\s]+$/, "Only numbers are allowed").matches(/^04.*$/, 'Mobile number should start with 04').test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  // studentState: Yup.string().required('Please select the state'),
  // city: Yup.string().required('Please enter city'),
  // postCode: Yup.string().required('Please enter post code').matches(/^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/, 'Enter a correct Postcode'),
  grade: Yup.string().required('Please select the grade')
})

export const locationSchema = Yup.object({
  name: Yup.string().required('Please enter Location Name'),
  address: Yup.string().required('Please enter Address Name'),
  accountName: Yup.string().required('Please enter Account Name'),
  BSB: Yup.string()
    .required('Please enter Bank BSB Number')
    .matches('^[0-9]*$', 'Only numbers are allowed')
    .max(6,'The BSB should be exact 6 digits').min(6,'The BSB should be exact 6 digits'),
  accountNumber: Yup.string().required('Please enter Bank Account Number')
    .matches('^[0-9]*$', 'Only numbers are allowed'),
  abn: Yup.string().required('Please enter Business Name'),
  // gst: Yup.string().required('Please enter Business GST Number'),
  phoneNumber: Yup.string()
  .required('Please enter phone number')
  .matches(/^[0-9\s]+$/, 'Only numbers are allowed')
  .matches(/^04.*$/, 'Mobile number should start with 04')
  .test('exact 10', 'Mobile number should be 10 digits', (value) => {
    if (!value) {
      return false; 
    }
    const numericValue = value.split(' ').join('');
    return numericValue.length === 10;
  }),
  // phoneNumber: Yup.string().required('Please enter Phone Number').matches(/^[0-9\s]+$/, "Only numbers are allowed").matches(/^04.*$/, 'Mobile number should start with 04').test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  emailAddress: Yup.string().email('Enter a valid email address').required('Please enter Email Address'),
  state: Yup.string().nullable().required('Please select your state'),
  city: Yup.string().nullable().required('Please enter your city'),
  postCode: Yup.string().matches(/^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/, 'Enter a correct Postcode'),

})

export const servicesTypeSchema = Yup.object({
  name: Yup.string().required('Please enter the Name'),
  serviceType: Yup.string().required('Please select the Service Type'),
  cost: Yup.number().required('Please enter the Cost'),
})

export const servicesTypeExamSchema = Yup.object({
  name: Yup.string().required('Please enter the Name'),
  exam_id: Yup.number().required('Please select the Exam'),
  cost: Yup.number().required('Please enter the Cost'),
})

export const servicesTypeClassesSchema = Yup.object({
  name: Yup.string().required('Please enter the Name'),
  grade: Yup.string()
    .when('name', {
      is: (name) => name !== 'Admin Charge',
      then: Yup.string().required('Please select a Grade'),
    }).nullable(),
  sessionType: Yup.string().when('name', {
    is: (name) => name !== 'Admin Charge',
    then: Yup.string().required('Please select a Group Size'),
  }).nullable(),
  cost: Yup.number().required('Please enter the Cost'),
})

export const batchSchema = Yup.object({
  name: Yup.string().required('Please enter the Name'),
  start_timestamp: Yup.string().required('Start time is required'),
  end_timestamp: Yup.string()
    .required('End time is required')
    .test('is-greater', 'End time should be greater', function (value) {
      const { start_timestamp } = this.parent
      return moment(value).isSameOrAfter(moment(start_timestamp))
    }),
  location_id: Yup.string().required('Please select any location'),
})

export const termMasterSchema = Yup.object({
  name: Yup.string().required('Please enter the Name'),
  startDate: Yup.string().required('Start Date is required'),
  endDate: Yup.string()
    .required('End Date is required')
    .test('is-greater', 'End Date should be greater', function (termsValue)
    {
      const { startDate } = this.parent
      return moment(termsValue).isAfter(moment(startDate))
    }),
  // state : Yup.string('Please select a location'),
})

export const examSchema = Yup.object({
  name: Yup.string().required('Please enter the name'),
  duration: Yup.number().required('Please enter the duration').nullable(),
  status: Yup.string().required('Please select the status').nullable(),
  type: Yup.string().required('Please select the type').nullable(),
  expireInDays: Yup.number()
    .required('Please enter exam expiration period')
    .nullable(),
})

export const falsEnrolmentSchema = Yup.object({
  serviceId: Yup.number().required('please select a service'),
  contactId: Yup.string().required('Please select a contact'),
})
export const truEnrolmentSchema = Yup.object({
  // batchId: Yup.string().required('Please select a batch'),
  serviceId: Yup.number().required('please select a service'),
  parentFirstName: Yup.string()
    .required("Please enter parent's First Name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentLastName: Yup.string()
    .required("Please enter parent's Last Name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter parent email address'),
  parentMobileNo: Yup.string().required("Please enter parent's mobile phone").matches(/^[0-9\s]+$/, "Only numbers are allowed").matches(/^04.*$/, 'Mobile number should start with 04').test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  // addressLine1: Yup.string().required('Please enter address line 1'),
  // city: Yup.string().required('Please enter the city'),
  // postCode: Yup.string().matches(/^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/, 'Enter a correct Postcode'),
  studentsFirstName: Yup.string()
    .required('Please enter the students first name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  studentsLastName: Yup.string()
    .required('Please enter the students last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  studentsEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter student email address'),
  studentsMobileNo: Yup.string().required(
    "Please enter student's mobile phone"
  ).matches(/^04.*$/, 'Mobile number should start with 04').matches(/^[0-9\s]+$/, "Only numbers are allowed").test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  dob: Yup.date().required('Please enter student date of birth'),
  grade: Yup.string().required('Please enter the students grade'),
  // studentState: Yup.string().required('Please enter the state'),
})
export const truestEnrolmentSchema = Yup.object({
  serviceId: Yup.number().required('please select a service'),
  parentFirstName: Yup.string()
    .required("Please enter parent's First Name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentLastName: Yup.string()
    .required("Please enter parent's Last Name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter parent email address'),
  parentMobileNo: Yup.string().required("Please enter parent's mobile phone").matches(/^[0-9\s]+$/, "Only numbers are allowed").matches(/^04.*$/, 'Mobile number should start with 04').test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  // addressLine1: Yup.string().required('Please enter address line 1'),
  // city: Yup.string().required('Please enter the city'),
  // postCode: Yup.string().matches(/^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/, 'Enter a correct Postcode'),
  studentsFirstName: Yup.string()
    .required('Please enter the students first name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  studentsLastName: Yup.string()
    .required('Please enter the students last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  studentsEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter student email address'),
  studentsMobileNo: Yup.string().required(
    "Please enter student's mobile phone"
  ).matches(/^04.*$/, 'Mobile number should start with 04').matches(/^[0-9\s]+$/, "Only numbers are allowed").test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  dob: Yup.date().required('Please enter student date of birth'),
  grade: Yup.string().required('Please enter the students grade'),
  // studentState: Yup.string().required('Please enter the state'),
})

export const categorySchema = Yup.object({
  category: Yup.string().required('Please enter the category'),
  categoryType: Yup.string().required('Please enter the category type'),
})

export const discountDescriptionSchema = Yup.object({
  description: Yup.string().required('Please enter the description'),
})

export const tuitionSchema = Yup.object({
  tuition: Yup.string().required('Please enter the tuition-purpose'),
})

export const familySchema = Yup.object({
  firstName: Yup.string().required('First name is required').matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  lastName: Yup.string().required('Last name is required').matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  email: Yup.string().email('Please enter a valid email address').required('Email is required'),
  mobileNo: Yup.string()
  .required('Please enter phone number')
  .matches(/^[0-9\s]+$/, 'Only numbers are allowed')
  .matches(/^04.*$/, 'Mobile number should start with 04')
  .test('exact 10', 'Mobile number should be 10 digits', (value) => {
    if (!value) {
      return false; 
    }
    const numericValue = value.split(' ').join('');
    return numericValue.length === 10;
  }).nullable(),
  center: Yup.string().required('Please select a preferred center'),
  address1: Yup.string().nullable(),
  parentState: Yup.string().nullable(),
  city: Yup.string().nullable(),
  // postCode: Yup.string().matches(/^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/, 'Enter a correct Postcode'),
})

export const gradeSchema = Yup.object({
  grade: Yup.string().required('Please enter the grade'),
})

export const subjectSchema = Yup.object({
  subject: Yup.string().required('Please enter the subject'),
})

export const sessionTypeSchema = Yup.object({
  sessionType: Yup.string().required('Please enter the session'),
})

export const leadsSchema = Yup.object({
  parentFirstName: Yup.string()
    .required('Please enter parent first name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentLastName: Yup.string()
    .required('Please enter parent last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter parent email address'),
  parentMobileNo: Yup.string().required('Please enter phone number').matches(/^[0-9\s]+$/, 'Only numbers are allowed').matches(/^04.*$/, 'Mobile number should start with 04')
  .test('exact 10', 'Mobile number should be 10 digits', (value) => {
    if (!value) {
      return false; 
    }
    const numericValue = value.split(' ').join('');
    return numericValue.length === 10;
  }),
  // parentMobileNo: Yup.string().required("Please enter parent's mobile phone").matches(/^[0-9\s]+$/, "Only numbers are allowed").matches(/^04.*$/, 'Mobile number should start with 04').test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  studentsFirstName: Yup.string()
    .required('Please enter students first name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  studentsEmail: Yup.string().email('Please enter valid email').nullable(),
  grade: Yup.number().required('Please enter the grade'),
  center: Yup.number().required('Please select the center'),
  study_subjects: Yup.string().required('Please select the subject'),
  help: Yup.string().required('Please select the purpose of attending tuition'),
  // postCode: Yup.string().required('Please enter postcode').matches(/^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/, 'Enter a correct Postcode'),
  // studentState: Yup.string().required('Please select state'),
  // city: Yup.string().required('Please select city'),
  // address1: Yup.string().required('Please enter your address'),
  groupSizeOptionsValue: Yup.string().required('Please select type of class')
})

export const familyLeadsSchema = Yup.object({
  parentFirstName: Yup.string()
    .required('Please enter parent first name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentLastName: Yup.string()
    .required('Please enter parent last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter parent email address'),
    parentMobileNo: Yup.string().required('Please enter phone number').matches(/^[0-9\s]+$/, 'Only numbers are allowed').matches(/^04.*$/, 'Mobile number should start with 04')
  .test('exact 10', 'Mobile number should be 10 digits', (value) => {
    if (!value) {
      return false; 
    }
    const numericValue = value.split(' ').join('');
    return numericValue.length === 10;
  }),
  center: Yup.number().required('Please select the center'),
  postCode: Yup.string().matches(/^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/, 'Enter a correct Postcode'),
  address1: Yup.string(),
})

// export const bulkUploadSchema = Yup.object().shape({
//   upload_files: Yup.mixed().required('File is required'),
// })

export const userSchema = Yup.object({
  name: Yup.string()
    .required('Please enter last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter email address'),
  role: Yup.string().required('Please select the role'),
  locationId: Yup.number().required('Please select the location'),
})

export const adminSchema = Yup.object({
  name: Yup.string()
    .required('Please enter last name')
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter email address'),
  role: Yup.string().required('Please select the role'),
})

export const referenceSchema = Yup.object({
  name: Yup.string().required('Please enter the Name'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Referer email is required'),
})

export const singleExamSchema1 = Yup.object({
  editorState: Yup.string().required('Please enter the question'),
  marks: Yup.array().required('Please enter the marks'),
  category: Yup.string().required('Please enter the category'),
})
export const singleExamSchema2 = Yup.object({
  answer: Yup.array().required('Please enter the answer'),
  is_correct: Yup.boolean(),
})

export const forgotPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .required('Password is required')
    .matches(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})',
      'Password must be 10 characters long and must contain 1 Special Character, 1 number and 1 Letter should be capital'
    ),
  confirmPassword: Yup.string()
    .required('Please enter the password again to confirm')
    .oneOf([Yup.ref('newPassword'), null], 'Password must match'),
})

export const openEnrollment = Yup.object({
  parentFirstName: Yup.string()
    .required("Please enter parent's First Name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentLastName: Yup.string()
    .required("Please enter parent's Last Name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter parent email address'),
  parentMobileNo: Yup.string().required("Please enter parent's mobile phone").matches(/^[0-9\s]+$/, "Only numbers are allowed").matches(/^04.*$/, 'Mobile number should start with 04').test('exact 10','Mobile number should be 10 digits', value => value.split(' ').join('').length == 10),
  // addressLine1: Yup.string().required('Please enter address line 1'),
  // city: Yup.string().required('Please enter the city'),
  // studentState: Yup.string().required('Please enter the state'),
  // postCode: Yup.number().required('Please enter the post code')
})

export const enquiryFormSchema = Yup.object({
  parentFirstName: Yup.string()
    .required("Please enter parent's First Name")
    .matches('^[a-zA-Z ]*$','Numbers are not allowed'),
  parentLastName: Yup.string()
    .required("Please enter parent's Last Name")
    .matches('^[a-zA-Z ]*$', 'Numbers are not allowed'),
  parentMobileNo: Yup.string().required("Please enter parent's mobile phone").matches(/^[0-9\s]+$/, 'Only numbers are allowed').matches(/^04.*$/, 'Mobile number should start with 04')
  .test('exact 10', 'Mobile number should be 10 digits', (value) => {
    if (!value) {
      return false; 
    }
    const numericValue = value.split(' ').join('');
    return numericValue.length === 10;
  }),
  preferredLocation: Yup.string().required('Please select a center'),
  parentEmail:Yup.string().required('Please enter email address'),
})

export const qrCode = Yup.object({
  token: Yup.number().required('Enter 6 digit code'),
})

export const markAsPaidSchema = Yup.object({
  paymentMethod: Yup.string().required('Select a payment method').nullable(),
  amountPayable: Yup.number(),
  amount: Yup.number().required('Enter a amount').nullable(),
  date: Yup.string().required('Select a date').nullable()
})

export const teacherSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  wwcNo:Yup.string().max(9,'WWC number should be 9 digits').min(9,'WWC number should be 9 digits'),
  vitNumber:Yup.string().max(8,'VIT number should be between 6 to 8 digits').min(6,'VIT number should be between 6 to 8 digits'),
  email: Yup.string().email().required('Email address is required'),
  mobileNo: Yup.string().required('Phone number is required').matches(/^[0-9\s]+$/, 'Only numbers are allowed').matches(/^04.*$/, 'Mobile number should start with 04')
  .test('exact 10', 'Mobile number should be 10 digits', (value) => {
    if (!value) {
      return false; 
    }
    const numericValue = value.split(' ').join('');
    return numericValue.length === 10;
  }),
  ratePerHour: Yup.number().required('Please enter the rate').nullable(),
  BSB: Yup.string()
  .required('Please enter Bank BSB Number')
  .matches('^[0-9]*$', 'Only numbers are allowed')
  .max(6,'The BSB should be exact 6 digits').min(6,'The BSB should be exact 6 digits'),
  accountNo: Yup.string().required('Please enter Bank Account Number')
  .matches('^[0-9]*$', 'Only numbers are allowed'),
  abn: Yup.string(),
  tfn: Yup.string().when('abn',{
    is:(abn) => !abn,
    then: Yup.string().required('TFN is required')
    .test('no-special-symbols', 'Special symbols are not allowed', (value) => {
      if (value) {
        return !/[^A-Za-z0-9]/.test(value)
      }
      return true;
    })
    .test('exact 9', 'TFN number should be 9 digits', (value) => {
      if (!value) {
        return false; 
      }
      const numericValue = value.replace(/\D/g, '');
      return numericValue.length === 9;
    }),
  }).nullable(),
  super:Yup.string().when('tfn', {
    is: (tfn) => tfn,
    then: Yup.string().required('Please enter the super number').max(9,'Super number must be 9 digits').min(9,'Super number must be 9 digits').matches(/^[0-9\s]+$/,'Only numbers are allowed'),
  }).nullable(),
  membershipNumber: Yup.string().when('tfn', {
    is: (tfn) => tfn,
    then: Yup.string().required('Please enter a membership number').matches(/^[A-Za-z0-9]*$/,'Not allowed any special symbols')
    .min(6,'Membership number must be in between 6 - 12 alphanumeric character')
    .max(12,'Membership number must be in between 6 - 12 alphanumeric character'),
  }).nullable(),
  abnorusi:Yup.string().when('tfn', {
    is: (tfn) => tfn,
    then: Yup.string().max(11,'ABN number must be 11 digit').min(11,'ABN number must be 11 digit').matches(/^[0-9\s]+$/,'Only numbers are allowed'),
  }).nullable(),
  usi:Yup.string().when(['tfn', 'abnorusi'], {
    is: (tfn, abnorusi) => !!tfn && !abnorusi,
    then: Yup.string().required('Please enter a USI number').max(10,'USI number must be 10 digit').min(10,'USI number must be 10 digit').matches(/^[a-zA-Z0-9]*$/, 'Only numbers are allowed')
  }).nullable(), 
  locationId:Yup.number().required('Please select a location').nullable(),
  subjectId:Yup.number().required('Please select a subject').nullable()
})

export const creditNoteSchema = Yup.object({
  amount: Yup.number().required('Enter a amount'),
  description: Yup.string().required('Please select a description').nullable(),

})

export const refundSchema=Yup.object({
  paymentMethod:Yup.string().required('Select a payment method').nullable(),
  BSB: Yup.string().nullable(),
})


export const classSchema = Yup.object({
  className:Yup.string().required('Please enter the name of the class').nullable(),
  serviceId:Yup.number().required('Please select the service').nullable(),
  classMode:Yup.string().required('Please select the class mode').nullable(),
  locationId:Yup.string().required('Please select the location').nullable(),
  startDate:Yup.string().required('Please select the start date').nullable(),
  // endDate:Yup.string().required('Please select the end date').nullable(),
  subjectId:Yup.number().required('Please select the subject').nullable(),
  teacherId:Yup.number().required('Please select the teacher').nullable(),
  teacherHourlyRate:Yup.number().required('Please enter the teachers hourly rate').nullable(),
  duration:Yup.number().required('Please enter the duration in minutes').typeError('Please enter a valid number')
  .test(
    'is-valid-duration',
    'Please enter a valid duration in minutes',
    value => (typeof value === 'number' && !isNaN(value) && /^[0-9]+$/.test(value))
  ).nullable(),
  // term:Yup.number().required('Please select the term').nullable(),
})

export const classDateSchema = Yup.object({
  startDate:Yup.string().required('Please select the date').nullable(),
  startTime:Yup.string().required('Please select the time').nullable(),
})