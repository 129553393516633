import React, { useState, useRef, useEffect, Fragment } from 'react'
import { Sidebar } from '../components/navigation/sidebar'
import Button from '../components/button'
import { classNames } from '../helpers/classname'
import { capitalize } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'
import app_api from '../config/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment/moment'
import ExamDialog from '../components/dialog/exam_warning'
import ResetPasswordStudent from '../components/dialog/reset_pass_student'

const Exams = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [exams, setExams] = useState([])
  const [name, setName] = useState('')
  const [open, setOpen] = useState(true)

  const [user, setUser] = useState({})
  const [examData, setExamData] = useState([])
  const cancelButtonRef = useRef(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [resetDialog, setResetDialog] = useState(false)
  const [studentResetData, setStudentResetData] = useState([])

  const openWarningModal = (value) => {
    setExamData(value)
    setConfirmationDialog(true)
  }

  useEffect(() => {
    let user
    // if(!JSON.parse(localStorage.getItem('moldKey')).last_reset_password){
    //   setResetDialog(true)
    // }
    setStudentResetData(JSON.parse(localStorage.getItem('moldKey')))
    setName(JSON.parse(localStorage.getItem('moldKey')))
    try {
      user = JSON.parse(localStorage.getItem('moldKey'))
      setUser(user.username)
      app_api
        .post('enrol-exam/contact/username', { username: user.username })
        .then((res) => {
          setLoading(false)
          setError(null)
          setExams(res.data)
        })
        .catch((err) => {
          setLoading(false)
          setError(err?.response?.data?.message || 'Error getting exams list')
        })
    } catch (err) {}
  }, [])

  const goToExam = (enrolmentid, examid, attempted, previewMode) => {
    if (!attempted) navigate(`${enrolmentid}/${examid}/questions`)
    if (attempted && previewMode) navigate(`${enrolmentid}/${examid}/preview`)
    else toast.error('preview is locked')
  }

  const getExamData = () => {
          let user = JSON.parse(localStorage.getItem('moldKey'))
          app_api
            .post('enrol-exam/contact/username', { username: user.username })
            .then((res) => {
              setLoading(false)
              setError(null)
              setExams(res.data)
            })
            .catch((err) => {
              setLoading(false)
              setError(err?.response?.data?.message || 'Error getting exams list')
            })
  }

  const ResetExam = (enrolmentid, examid, attempted, previewMode) => {
    app_api
        .patch(`enrol-exam/reset/${enrolmentid}`)
        .then((res) => {
          getExamData()
          toast.success('Exam reset successfully')
        })
        .catch((err) => {
          setLoading(false)
          setError(err?.response?.data?.message || 'Error getting exams list')
        })
  }

  return (
    <>
      <ResetPasswordStudent
        setOpen={setResetDialog}
        open={resetDialog}
        data={studentResetData}
      />
      <ExamDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        data={examData}
      />
      <Sidebar>
        <div className="max-w-[1640px ] mx-auto p-4">
          <div className="px-2 sm:px-6 lg:px-8">
            <div className="sm:flex mb-12 sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-lg font-semibold text-gray-900 dashboardTitle">
                  Welcome {name?.name}
                </h1>
                <p className="my-2 text-sm text-gray-700 ml-2">
                  Here's your list of Exam
                </p>
              </div>
            </div>
            {exams == '' && !loading && (
              <>
                <div className='w-100 bg-orange-200 px-2 py-2 text-center rounded-full'>
                  <span>No Exam were found.</span>
                </div>
              </>
            )
              
            }
            {loading ? (
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) 
            : (
              <div
                role="list"
                className="grid grid-cols-1 gap-5 mt-3 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2 "
              >
                {exams.map((ex, idx) => (
                  <div key={idx} className="xl:max-w-5xl bg-white flex">
                    {/* <div className="rounded-l-lg h-24 w-28 grid place-content-center bg-[#DA2877] ">
                    <div className="text-white">
                      {ex?.exam?.name.slice(0, 2).toUpperCase()}
                    </div>
                  </div> */}

                    <div className="flex flex-column text-left  justify-between w-full rounded-xl border px-3 py-2 shadow-md">
                      <div className=" inline-flex justify-between items-center">
                        <p className="font-bold mb-2  my-auto  ">
                          {ex?.exam?.name}
                        </p>
                        {ex.type != "workshop" && (<>
                          <p
                          className={classNames(
                            ex.hasAttempted && moment(ex.resettable_expiry) >= moment()
                              ? 'bg-green-100 text-[#027A48]'
                              : !ex.hasAttempted && moment(ex.resettable_expiry) >= moment() ? 'bg-orange-100 text-[#B54708]' : 'bg-red-100 text-red-600',
                            'py-[2px] rounded-full px-2.5 ml-2 text-xs text-end shadow-md'
                          )}
                        >
                          {ex.hasAttempted && moment(ex.resettable_expiry) >= moment() ? 'Completed' : !ex.hasAttempted && moment(ex.resettable_expiry) >= moment() ? 'Pending' : 'Expired'}
                        </p>
                        </>)}
                      </div>
                      <p>
                        Questions:&nbsp;
                        <span className="font-semibold text-sm ">
                          {ex.exam?.questions?.length}
                        </span>{' '}
                      </p>
                      {ex?.duration ? (
                        <p>
                          Duration:&nbsp;
                          <span className="font-semibold text-sm ">
                            {ex.exam?.duration}min
                          </span>{' '}
                        </p>
                      ) : (
                        <p>
                          <span className="font-semibold text-sm ">
                            {ex.exam?.expires_at}
                          </span>{' '}
                        </p>
                      )}
                        <p>
                          Scored:&nbsp;
                          <span className="font-semibold text-sm ">
                            {ex?.out_of_score ? (ex?.out_of_score * 100 / ex?.total_marks).toFixed(2) + "%" : "0%"} 
                          </span>{' '}
                        </p>
                      <div className="mt-3 flex flex-inline gap-2 justify-center items-center">
                        {(() => {
                          const inputDate = ex.date;
                          let today = moment();
                          const formattedDate = moment(inputDate, "DD/MM/YYYY");
                          if(ex.type != "workshop")
                          {
                            if (ex.hasAttempted && ex.exam.previewMode) {
                              if(moment(ex.resettable_expiry) >= moment() && ex.enrolment_type == 'online'){
                                return (
                                  <>
                                  <button
                                    // disabled={ex.hasAttempted}
                                    className="rounded-md text-red-600 px-2 py-1 flex cursor-pointer"
                                    onClick={() =>
                                      ResetExam(
                                        ex?.id,
                                        ex?.exam?.id,
                                        ex.hasAttempted,
                                        ex.exam.previewMode
                                      )
                                    }
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                    <span className='ml-1 text-sm'>Reappear</span>
                                  </button>
                                  <button
                                    // disabled={ex.hasAttempted}
                                    className="rounded-md text-teal-500 py-1 flex cursor-pointer"
                                    onClick={() =>
                                      goToExam(
                                        ex?.id,
                                        ex?.exam?.id,
                                        ex.hasAttempted,
                                        ex.exam.previewMode
                                      )
                                    }
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <span className='ml-1 text-sm'>All Answers</span>
                                  </button>
                                  {ex.certificate_url && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-1 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.certificate_url}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Certificate</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  {ex.grade_card && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.grade_card}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Scorecard</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  
                                  </>
                                )
                              }
                              else{
                                return (
                                  <>
                                  <button
                                    // disabled={ex.hasAttempted}
                                    className="rounded-md text-teal-500 px-2 py-1 flex cursor-pointer"
                                    onClick={() =>
                                      goToExam(
                                        ex?.id,
                                        ex?.exam?.id,
                                        ex.hasAttempted,
                                        ex.exam.previewMode
                                      )
                                    }
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <span className='ml-1 text-sm'>All Answers</span>
                                  </button>
                                  {ex.certificate_url && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.certificate_url}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='text-sm'>Certificate</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  {ex.grade_card && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.grade_card}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Scorecard</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  </>
                                )
                              }
                              
                            } else if (
                              !ex.hasAttempted &&
                              (moment(ex.resettable_expiry) >= moment() || ex.resettable_expiry == '')
                            ) {
                              return (
                                <button
                                  // disabled={ex.hasAttempted}
                                  className="text-green-700 py-1 rounded-md w-100 flex justify-center item-center cursor-pointer"
                                  onClick={
                                    () => openWarningModal(ex)
                                    // goToExam(
                                    //   ex?.id,
                                    //   ex?.exam?.id,
                                    //   ex.hasAttempted,
                                    //   ex.exam.previewMode
                                    // )
                                  }
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                  </svg>
                                  <span className='ml-1 text-sm'>Start</span>
                                </button>
                              )
                            } else if (ex.hasAttempted && !ex.previewMode && ex.enrolment_type == 'offline') {
                              return (
                                <>
                                  {ex.certificate_url && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.certificate_url}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Certificate</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  {ex.grade_card && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.grade_card}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Scorecard</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  
                                </>
                              )
                            } else if(ex.hasAttempted && !ex.previewMode && ex.enrolment_type == 'online' && moment(ex.resettable_expiry)>= moment()){
                              return (
                                <>
                                  <button
                                    // disabled={ex.hasAttempted}
                                    className="rounded-md text-red-600 px-2 py-1 flex cursor-pointer"
                                    onClick={() =>
                                      ResetExam(
                                        ex?.id,
                                        ex?.exam?.id,
                                        ex.hasAttempted,
                                        ex.exam.previewMode
                                      )
                                    }
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                    <span className='ml-1 text-sm'>Reappear</span>
                                  </button>
                                  {ex.certificate_url && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.certificate_url}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Certificate</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  {ex.grade_card && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.grade_card}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Scorecard</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  </>
                              )
                            }
                            else {
                              if(ex.hasAttempted && moment(ex.resettable_expiry) <=moment(ex)){
                                return (
                                  <>
                                  {ex.certificate_url && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.certificate_url}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Certificate</span>
                                        </span>
                                        
                                      </a>
                                    </>
                                  )}
                                  {ex.grade_card && (
                                    <>
                                      <a
                                        className="text-indigo-200 font-medium text-sm text-black p-2 cursor-pointer "
                                        href={`${process.env.REACT_APP_STATIC_URL}${ex?.grade_card}`}
                                        target="_blank"
                                        // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                                      >
                                        <span className='flex text-indigo-800'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                        </svg>
                                        <span className='ml-1 text-sm'>Scorecard</span>
                                        </span>
                                      </a>
                                    </>
                                  )}
                                  </>
                                )
                              }
                              else{
                                return (
                                  <>
                                    <p className="text-center py-1 cursor-no-drop bg-red-200 rounded-md w-100">
                                      Expired
                                    </p>
                                    
                                  </>
                                )
                              }
                            }
                          }
                          else
                          {
                            if(!ex.hasAttempted && formattedDate.isSameOrBefore(today))
                            {
                              return (
                                <button
                                  // disabled={ex.hasAttempted}
                                  className="text-green-700 py-1 rounded-md w-100 flex justify-center item-center cursor-pointer"
                                  onClick={
                                    () => openWarningModal(ex)
                                    // goToExam(
                                    //   ex?.id,
                                    //   ex?.exam?.id,
                                    //   ex.hasAttempted,
                                    //   ex.exam.previewMode
                                    // )
                                  }
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                  </svg>
                                  <span className='ml-1 text-sm'>Start</span>
                                </button>
                              )
                            }
                            if(ex.hasAttempted)
                              {
                                return (
                                  <div
                                    // disabled={ex.hasAttempted}
                                    className="text-green-700 py-1 rounded-md w-100 flex justify-center item-center cursor-pointer"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <span className='ml-1 text-sm'>Completed</span>
                                  </div>
                                )
                              }
                            if(!ex.hasAttempted && formattedDate.isAfter(today))
                            {
                              return (
                                <div
                                  // disabled={ex.hasAttempted}
                                  className="text-orange-700 bg-orange-100 py-1 rounded-md w-100 flex justify-center item-center cursor-pointer"
                                >
                                  <span className='ml-1 text-sm'>Upcoming</span>
                                </div>
                              )
                            }
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
      </Sidebar>
    </>
  )
}

export default Exams
