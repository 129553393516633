import React, { useEffect, useState } from 'react'
import app_api from '../config/api'
import { Navigate, useNavigate } from 'react-router-dom'
import Resetpassword from './Resetpass'

const ResetAuth = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const Reset = (values) => {
    const userData = JSON.parse(localStorage.getItem('moldKey'))
    setLoading(true)
    app_api
      .patch(`/users/${userData.id}/password`, {
        "currentPassword" : values.currentPassword,
        "password": values.password
      })
      .then((res) => {
        let existing = JSON.parse(localStorage.getItem('moldKey'))
        existing['last_reset_password'] = res.data.last_reset_password
        localStorage.setItem('moldKey', JSON.stringify(existing));
        setLoading(false)
        setError(null)
        if (res.data.role == 'student') navigate('/my-exams')
        else if (res.data.role == 'manager') navigate('/leads')
        else navigate('/leads')
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error logging in')
      })
  }

  return (
    <div>
      <Resetpassword loading={loading} error={error} reset={Reset} />
    </div>
  )
}

export default ResetAuth
