import { toast } from 'react-toastify'
import app_api from '../../config/api'
import BigSlideover from '../../components/big-slideover'
import Button from '../../components/button'
import Select from 'react-select'
import logo from '../../assets/icons/logo.png'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CurrencyInput from 'react-currency-input-field'
import { Formik } from 'formik'
import {
  CheckCircleIcon,
  InformationCircleIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'

const InvoiceModal = (props) => {
  const { type, state, edit_id, data } = props.invoiceModal
  const {
    discountError,
    finalPayload,
    discount,
    descriptionValue,
    parentValue,
    invoiceDueDate,
    payload,
    totalAmount,
    amountPayable,
    serviceId,
    cleanInvoiceModalData,
    getContactData,
    invoiceData,
    invoiceModal,
    family,
    setLocationAddress,
    setParentValue,
    setPayload,
    getStudentData,
    wholeFormDisable,
    studentValue,
    student,
    setStudentValue,
    setError,
    studentDataChanged,
    typeOfServiceOptions,
    location,
    setClassPayload,
    setClassSelect,
    invoiceDate,
    setInvoiceDueDate,
    quantityEdit,
    amountEdit,
    removeItems,
    modal,
    classSelect,
    classOption,
    partPayment,
    calculateDiscount,
    discountDescription,
    setDescriptionValue,
    onMarkAsPaid,
    onDeleteInvoiceOpen,
    markPaidTeachwork,
    setInvoiceModal,
    handleClickDiv,
  } = props
  return (
    <Formik
      initialValues={data}
      validationSchema={null}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (discountError == false) {
          if (discount > 0) {
            if (descriptionValue != null) {
              if (type == 'add') {
                finalPayload = {
                  customerId: parentValue,
                  invoiceDate: values.date,
                  dueDate: invoiceDueDate,
                  servicePayload: payload,
                  subtotal: totalAmount,
                  discount: discount,
                  amountPayable: amountPayable,
                  serviceId: serviceId,
                  descriptionId: parseInt(descriptionValue),
                  locationId: values.locationId,
                  typeOfService: values.typeOfService,
                  // charges: charges,
                  createdBy: JSON.parse(localStorage.getItem('moldKey')).email,
                }
                app_api
                  .post('/enrolment/testEnrolment', finalPayload)
                  .then((res) => {
                    setSubmitting(false)
                    resetForm()
                    cleanInvoiceModalData()
                    toast.success('Invoice Created Successfully')
                    getContactData()
                  })
                  .catch((err) => {
                    if (err.response.status == 424) {
                      toast.error('Duplicate Entry')
                    } else {
                      toast.error('Something Went Wrong')
                    }
                    cleanInvoiceModalData()
                    setSubmitting(false)
                  })
              } else {
                finalPayload = {
                  customerId: parentValue,
                  invoiceDate: values.date,
                  dueDate: invoiceDueDate,
                  servicePayload: payload,
                  subtotal: totalAmount,
                  discount: discount,
                  amountPayable: amountPayable,
                  serviceId: serviceId,
                  descriptionId: parseInt(descriptionValue),
                  locationId: values.locationId,
                  typeOfService: values.typeOfService,
                  // charges: charges,
                  updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
                }
                app_api
                  .patch(`/enrolment/testEnrolment/${edit_id}`, finalPayload)
                  .then((res) => {
                    getContactData()
                    setSubmitting(false)
                    resetForm()
                    cleanInvoiceModalData()
                    toast.success('Invoice Updated Successfully')
                  })
                  .catch((err) => {
                    if (err.response.status == 424) {
                      toast.error('Duplicate Entry')
                    } else {
                      toast.error('Something Went Wrong')
                    }
                    cleanInvoiceModalData()
                    setSubmitting(false)
                  })
              }
            } else {
              toast.error('Select a discount description')
              setSubmitting(false)
            }
          } else {
            if (type == 'add') {
              finalPayload = {
                customerId: parentValue,
                invoiceDate: values.date,
                dueDate: invoiceDueDate,
                servicePayload: payload,
                subtotal: totalAmount,
                discount: discount,
                amountPayable: amountPayable,
                serviceId: serviceId,
                descriptionId: parseInt(descriptionValue),
                locationId: values.locationId,
                typeOfService: values.typeOfService,
                // charges: charges,
                createdBy: JSON.parse(localStorage.getItem('moldKey')).email,
              }
              app_api
                .post('/enrolment/testEnrolment', finalPayload)
                .then((res) => {
                  setSubmitting(false)
                  resetForm()
                  cleanInvoiceModalData()
                  toast.success('Invoice Created Successfully')
                  getContactData()
                })
                .catch((err) => {
                  if (err.response.status == 424) {
                    toast.error('Duplicate Entry')
                  } else {
                    toast.error('Something Went Wrong')
                  }
                  cleanInvoiceModalData()
                  setSubmitting(false)
                })
            } else {
              finalPayload = {
                customerId: parentValue,
                invoiceDate: values.date,
                dueDate: invoiceDueDate,
                servicePayload: payload,
                subtotal: totalAmount,
                discount: discount,
                amountPayable: amountPayable,
                serviceId: serviceId,
                descriptionId: parseInt(descriptionValue),
                locationId: values.locationId,
                typeOfService: values.typeOfService,
                // charges: charges,
                updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
              }
              app_api
                .patch(`/enrolment/testEnrolment/${edit_id}`, finalPayload)
                .then((res) => {
                  setSubmitting(false)
                  resetForm()
                  cleanInvoiceModalData()
                  toast.success('Invoice Updated Successfully')
                  getContactData()
                })
                .catch((err) => {
                  if (err.response.status == 424) {
                    toast.error('Duplicate Entry')
                  } else {
                    toast.error('Something Went Wrong')
                  }
                  cleanInvoiceModalData()
                  setSubmitting(false)
                })
            }
          }
        } else {
          toast.error('Discount is greater than total amount')
          setSubmitting(false)
        }
      }}
    >
      {({
        handleBlur,
        Formik,
        handleChange,
        handleSubmit,
        setValues,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }) => (
        <BigSlideover
          open={state}
          setOpen={() => {
            setInvoiceModal((prev) => ({ ...prev, state: false }))
          }}
          title={type === 'add' ? 'Create Invoice' : `${invoiceData}`}
          handleClick={''}
          data={invoiceModal.data}
        >
          <form onSubmit={handleSubmit} noValidate>
            <div className="text-left">
              <div className="block md:hidden">
                <div className="h-20 flex justify-center items-center mt-3">
                  <img className="w-48" src={logo} alt="Your Company" />
                </div>
              </div>
              <div className="flex w-full gap-3">
                <div className="inline-grid grid-cols-1 md:grid-cols-2 gap-4 w-[100%] md:w-[80%]">
                  <div className="">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Select a Customer
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <div className="">
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        name="customerId"
                        id="customerId"
                        isSearchable
                        loadingMessage="Getting Customer..."
                        placeholder="Select a customer..."
                        value={
                          parentValue
                            ? family.map((l) => ({
                                value: family.find((e) => e.id == parentValue)
                                  ?.id,
                                label:
                                  family.find((e) => e.id == parentValue)
                                    ?.lastName +
                                  ', ' +
                                  family.find((e) => e.id == parentValue)
                                    ?.firstName,
                              }))
                            : null
                        }
                        options={family?.map((l) => ({
                          ...l,
                          label: l.lastName + ', ' + l.firstName,
                          value: l.id,
                        }))}
                        onChange={(option) => {
                          setValues({
                            ...values,
                            customerId: option.value,
                          })
                          setLocationAddress(option.center)
                          setParentValue(option.value)
                          setPayload([])
                          getStudentData(option.value)
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        isDisabled={type == 'edit' || wholeFormDisable}
                        styles={{
                          singleValue: (provided) => ({
                            ...provided,
                            fontWeight: 'bold',
                            fontSize: '14px',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Select a Student
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <div className="">
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        isSearchable
                        loadingMessage="Getting Student..."
                        placeholder="Select a student..."
                        value={
                          studentValue
                            ? student.map((l) => ({
                                value: student.find(
                                  (e) => e.studentMaster.id == studentValue
                                )?.studentMaster?.id,
                                label: student.find(
                                  (e) => e.studentMaster.id == studentValue
                                )?.studentMaster?.studentsFirstName,
                              }))
                            : null
                        }
                        options={student?.map((l) => ({
                          ...l,
                          label: l.studentMaster.studentsFirstName,
                          value: l.studentMaster?.id,
                        }))}
                        isDisabled={type == 'edit' ? true : !parentValue}
                        onChange={(option) => {
                          setStudentValue(option.value)
                          setError(false)
                          studentDataChanged()
                        }}
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                        styles={{
                          singleValue: (provided) => ({
                            ...provided,
                            fontWeight: 'bold',
                            fontSize: '14px',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Type of Service
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="typeOf"
                      id="typeOf"
                      isSearchable
                      loadingMessage="Getting Group Size..."
                      placeholder="Select a type of service..."
                      value={
                        values.typeOfService
                          ? typeOfServiceOptions?.map((l) => ({
                              value: typeOfServiceOptions?.find(
                                (e) => e.id == values.typeOfService
                              )?.id,
                              label: typeOfServiceOptions?.find(
                                (e) => e.id == values.typeOfService
                              )?.name,
                            }))
                          : null
                      }
                      options={typeOfServiceOptions.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          typeOfService: option.value,
                        })
                        studentDataChanged()
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                      isDisabled={!studentValue || type == 'edit'}
                      styles={{
                        singleValue: (provided) => ({
                          ...provided,
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }),
                      }}
                    />
                    {touched.typeOf && (
                      <p className="text-red-700 error_msg ">{errors.typeOf}</p>
                    )}
                  </div>
                  <div className="">
                    <div className="flex flex-col w-full">
                      <div className="">
                        <div className="">
                          <label className="text-sm font-medium text-gray-700">
                            Select Location
                          </label>
                          <span className="text-red-700 ml-1">*</span>
                        </div>
                        <Select
                          className="text-left w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-white shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="locationAddress"
                          id="locationAddress"
                          isSearchable
                          loadingMessage="Getting Location..."
                          placeholder="Select a location..."
                          value={
                            values.locationId
                              ? location?.map((l) => ({
                                  value: location?.find(
                                    (e) => e.id == values.locationId
                                  )?.id,
                                  label: location?.find(
                                    (e) => e.id == values.locationId
                                  )?.name,
                                }))
                              : null
                          }
                          isDisabled={type == 'edit' ? true : !studentValue}
                          options={location
                            ?.filter((l) =>
                              values?.studentLocation?.includes(l.id)
                            )
                            ?.map((l) => ({
                              ...l,
                              label: l.name,
                              value: l.id,
                            }))}
                          onChange={(option) => {
                            // setLocationAddress(option.value)
                            setValues({
                              ...values,
                              location: option.value,
                            })
                            setPayload([])
                            setClassPayload([])
                            setClassSelect([])
                          }}
                          onBlur={handleBlur}
                          menuPlacement="auto"
                          maxMenuHeight={110}
                          styles={{
                            singleValue: (provided) => ({
                              ...provided,
                              fontWeight: 'bold',
                              fontSize: '16px',
                            }),
                          }}
                        />
                      </div>
                      <div className="flex mt-2 mb-4">
                        <p className="font-semibold text-sm w-full">
                          {values.locationId &&
                            location.find((l) => l.id == values.locationId)
                              ?.address}
                          <br />
                          {values.locationId &&
                            location.find((l) => l.id == values.locationId)
                              ?.city}{' '}
                          {values.locationId &&
                            location.find((l) => l.id == values.locationId)
                              ?.state}{' '}
                          {values.locationId &&
                            location.find((l) => l.id == values.locationId)
                              ?.postCode}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[20%] hidden md:block">
                  <div className="h-20 flex justify-center items-center mt-3">
                    <img className="w-48" src={logo} alt="Your Company" />
                  </div>
                </div>
              </div>
              {parentValue ? (
                <>
                  <div className="w-full mt-1 mb-3 font-semibold text-sm">
                    {family.find((l) => l.id == parentValue)?.address1}
                    <br />
                    <span>
                      {family.find((l) => l.id == parentValue)?.city}{' '}
                      {family.find((l) => l.id == parentValue)?.parentState}{' '}
                      {family.find((l) => l.id == parentValue)?.postCode}
                    </span>
                    <br />
                    <span>
                      {family
                        .find((l) => l.id == parentValue)
                        ?.country?.slice(0)
                        .toUpperCase()}
                    </span>
                  </div>
                </>
              ) : null}
              {/* {touched.category && (
                <p className="text-red-700 error_msg">{errors.category}</p>
              )} */}

              <div className="inline-grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 text-left">
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Invoice Date
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <DatePicker
                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    name="date"
                    dateFormat="dd-MM-yyyy"
                    id="date"
                    selected={invoiceDate}
                    disabled={true}
                    isClearable={false}
                    onChange={(date) => {
                      setValues((prev) => ({
                        ...prev,
                        date: date,
                      }))
                    }}
                    placeholderText="Date"
                  />
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Due Date
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <DatePicker
                    className="text-left h-9 block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border-gray-300 border-1 px-2"
                    name="invoiceDueDate"
                    dateFormat="dd-MM-yyyy"
                    id="invoiceDueDate"
                    selected={invoiceDueDate}
                    disabled={type == 'edit' ? true : false}
                    onChange={(date) => {
                      setValues((prev) => ({
                        ...prev,
                        dueDate: date,
                      }))
                      setInvoiceDueDate(date)
                    }}
                    minDate={new Date()}
                    isClearable={type == 'edit' ? false : true}
                    placeholderText="Due Date"
                  />
                  {touched.categoryType && (
                    <p className="text-red-700 error_msg">
                      {errors.categoryType}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* Spacer */}
            <div className="mt-4 overflow-x-auto">
              {/* Spacer */}
              {invoiceModal.data.source != 'Teachwork' && (
                <div className="flex items-center h-10 bg-stone-300 rounded-t-md min-w-[800px]">
                  <div className="w-1/4 ml-4 text-black font-semibold text-md">
                    <span>Class</span>
                  </div>
                  <div className="w-1/4 ml-4 text-black font-semibold text-md text-center">
                    <span>Number of sessions</span>
                  </div>
                  <div className="w-1/4 ml-4 text-black font-semibold text-md text-center">
                    <span>Unit Price</span>
                  </div>
                  <div className="w-1/4 mr-4 text-black font-semibold text-md text-center">
                    <span>Amount</span>
                  </div>
                  <div className="w-10 mr-4 text-black font-semibold text-md text-right"></div>
                </div>
              )}
              {payload != null && payload.length > 0 ? (
                <>
                  <div className="overflow-x-auto bg-slate-50 rounded-b-md shadow-md min-w-[800px]">
                    {payload.map((item, index) => (
                      <>
                        <div className="flex items-center h-10">
                          <div className="w-1/4 ml-4 text-black text-sm">
                            <span>{item.service}</span>
                          </div>
                          <div className="w-1/4 ml-4 text-black text-sm">
                            {/* <span>{item.quantity}</span> */}
                            <input
                              type="number"
                              name="quantity"
                              min={1}
                              value={item?.quantity}
                              onChange={(e) => {
                                quantityEdit(e, index)
                              }}
                              max={item?.maxSession}
                              disabled={wholeFormDisable}
                              onBlur={handleBlur}
                              className="w-full text-center appearance-none sm:text-sm px-2 py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                            />
                          </div>
                          <div className="w-1/4 ml-4 text-black text-sm text-center">
                            <CurrencyInput
                              id="amount"
                              name="amount"
                              decimalsLimit={2}
                              value={item?.amount}
                              className="w-full text-center appearance-none sm:text-sm px-2 py-3 focus:bg-none focus:outline-none focus:ring-none bg-slate-50"
                              prefix="$"
                              onValueChange={(value) => {
                                amountEdit(value, index)
                              }}
                            />
                          </div>
                          <div className="w-1/4 mr-4 text-black text-sm text-center">
                            <span>$ {item.amount * item.quantity}</span>
                          </div>
                          {!wholeFormDisable &&
                            data.xero_invoice_balance == data.amountPayable &&
                            !data.familyInvoiceItem
                              ?.map((l) => l.service)
                              .includes('Admin Charge') &&
                            payload.length > 1 && (
                              <div
                                className="w-10 mr-4 text-red-400 hover:text-red-500 duration-300 cursor-pointer font-semibold text-md"
                                onClick={() => {
                                  removeItems(index)
                                }}
                              >
                                <XCircleIcon className="w-6 h-6" />
                              </div>
                            )}
                        </div>
                        <hr className="text-slate-400"></hr>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {invoiceModal.data.source == 'Teachwork' &&
                    invoiceModal.data.xero_invoice_balance != 0 && (
                      <>
                        <div className=" h-10 bg-orange-200 rounded-lg items-center ">
                          <div className="ml-4 text-black text-lg flex justify-center items-center place-content-center text-center ">
                            <div className="text-center mt-2">
                              <InformationCircleIcon className="w-6 text-red-400 " />
                            </div>
                            <div className="text-center mt-2">
                              <span className="">
                                This invoice was created in Teachworks. You can
                                only register a payment here.
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {invoiceModal.data.source == 'Teachwork' &&
                    invoiceModal.data.xero_invoice_balance == 0 && (
                      <>
                        <div className=" h-10 bg-green-200 rounded-lg items-center ">
                          <div className="ml-4 text-black text-lg flex justify-center items-center place-content-center text-center ">
                            <div className="text-center mt-2">
                              <CheckCircleIcon className="w-6 text-green-800 " />
                            </div>
                            <div className="text-center mt-2">
                              <span className="">PAID</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
            {!wholeFormDisable &&
              invoiceModal.data.source != 'Teachwork' &&
              !modal.data.familyInvoiceItem
                ?.map((l) => l.service)
                .includes('Admin Charge') && (
                <>
                  <div className="inline-grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full">
                    <div className="sm:col-start-1 md:col-start-2 lg:col-start-3">
                      <Select
                        className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        isSearchable
                        loadingMessage="Getting Class..."
                        placeholder="Select class..."
                        value={
                          classSelect
                            ? {
                                value: classOption?.find(
                                  (e) => e.id == classSelect
                                )?.id,
                                label: classOption?.find(
                                  (e) => e.id == classSelect
                                )?.className,
                              }
                            : null
                        }
                        options={classOption
                          // ?.filter((l) => !classPayload?.some((f) => f.classId === l.id) && l.locationId == values.location)
                          .map((l) => ({
                            ...l,
                            label: l.className,
                            value: l.id,
                          }))}
                        onChange={(option) => {
                          setClassSelect(option.value)
                        }}
                        isDisabled={
                          !studentValue ||
                          values.xero_invoice_status === 'PAID' ||
                          values.xero_invoice_status === 'VOIDED'
                        }
                        onBlur={handleBlur}
                        menuPlacement="auto"
                        maxMenuHeight={110}
                      />
                    </div>
                    <div
                      className="ml-4 h-8 w-8 flex items-center justify-center rounded-full bg-indigo-900 text-white"
                      onClick={() => {
                        handleClickDiv()
                      }}
                    >
                      <PlusIcon className="w-6 h-6" />
                    </div>
                  </div>
                  <div className="flex">
                    {/* <div className="w-1/6">
                       {groupErrors && (
                         <>
                           <p className="text-sm text-yellow-600">Please select a class</p>
                         </>
                       )}
                     </div> */}

                    {/* <div className="w-1/6 ml-4">
                       {gradeError && (
                         <>
                           <p className="text-sm text-yellow-600 text-right">No Grade details were found for the student, Please provide the same.</p>
                         </>
                       )}
                     </div> */}
                  </div>
                </>
              )}
            {payload.length > 0 && (
              <div className="inline-grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full">
                <div className="bg-gray-200 rounded-lg shadow border mt-4 sm:col-start-1 md:col-start-2 lg:col-start-3">
                  <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2">
                    <p>Cart Subtotal:</p>
                    <p className="pr-2">${totalAmount?.toFixed(2)}</p>
                  </div>
                  {type == 'edit' && (
                    <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-4">
                      <p>Amount Paid:</p>
                      <p className="pr-2">${partPayment?.toFixed(2)}</p>
                    </div>
                  )}
                  {/* <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 pt-3">
                      <p>Charge:</p>
                      <CurrencyInput
                        decimalsLimit={2}
                        value={charges}
                        disabled={wholeFormDisable}
                        maxLength={totalAmount.toString().length}
                        className="block w-20 bg-gray-200 appearance-none rounded-md py-2 text-right px-2"
                        prefix="$"
                        onValueChange={(value) => {
                          calculateCharges(value)
                        }}
                      />
                    </div> */}
                  <div className="flex justify-between items-center px-2 text-sm font-mono text-gray-700 pt-3">
                    <p>Discount:</p>
                    <CurrencyInput
                      decimalsLimit={2}
                      value={discount}
                      disabled={wholeFormDisable}
                      maxLength={totalAmount.toString().length}
                      className="block w-20 bg-gray-200 appearance-none rounded-md py-2 text-right px-2"
                      prefix="$"
                      onValueChange={(value) => {
                        calculateDiscount(value)
                      }}
                    />
                  </div>
                  {discountError && (
                    <>
                      <p className="text-sm text-red-600 text-right">
                        Discount is more then Total Payable Amount
                      </p>
                    </>
                  )}
                  <div className="flex justify-between bg-gray-200 items-center px-2 text-sm font-mono text-gray-700 pt-3">
                    <p className="">Discount Description:</p>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      isSearchable
                      isDisabled={wholeFormDisable || discount == 0}
                      loadingMessage="Getting Discount Description..."
                      placeholder="Description"
                      value={
                        descriptionValue
                          ? {
                              value: discountDescription.find(
                                (l) => l.id == descriptionValue
                              )?.id,
                              label: discountDescription.find(
                                (l) => l.id == descriptionValue
                              )?.description,
                            }
                          : null
                      }
                      options={discountDescription.map((l) => ({
                        ...l,
                        label: l.description,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setDescriptionValue(option.value)
                      }}
                      onBlur={handleBlur}
                      menuPlacement="auto"
                      maxMenuHeight={110}
                    />
                  </div>
                  <hr class="h-px my-2 mx-2 bg-black border-0" />
                  <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1 mb-2">
                    <p>Total Amount Payable:</p>
                    <p>${amountPayable}</p>
                  </div>
                </div>
              </div>
            )}
            {invoiceModal.data.source == 'Teachwork' && (
              <div className="inline-grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full">
                <div className="bg-gray-200 rounded-lg shadow border mt-4 sm:col-start-1 md:col-start-2 lg:col-start-3">
                  <div className="flex justify-between px-2 text-sm font-mono text-gray-700 mt-2">
                    <p>Cart Subtotal:</p>
                    <p className="pr-2">
                      ${invoiceModal.data.amountPayable?.toFixed(2)}
                    </p>
                  </div>
                  <hr class="h-px my-2 mx-2 bg-black border-0" />
                  <div className="flex justify-between px-2 text-sm font-mono text-gray-700 pt-1 mb-2">
                    <p>Total Amount Payable:</p>
                    <p>${amountPayable?.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-between items-center bottom-4 mt-3">
              <div className="w-2/4 bottom-4 left-auto">
                <p className="text-semibold text-md text-gray-500">
                  Terms and Condition
                </p>
                <p className="text-sm text-gray-400">
                  All invoices must be paid within 7 days of issue. <br />
                  You can pay by credit card by clicking on the link provided in
                  the invoice. <br />
                  Please note, payment done by Credit Card will incur a service
                  charge by the provider.
                  <br />
                  To avoid the surcharge, you may pay us directly into our
                  account or pay by cash. <br />
                  Our account details are given below. <br />
                  <b>
                    <u>
                      Please make sure that you put the invoice number in the
                      reference when doing a bank transfer
                    </u>
                  </b>
                  .
                </p>
                <br></br>
                {values.locationId && (
                  <>
                    <p className="text-sm text-gray-500 mb-4">
                      Account Name:{' '}
                      {
                        location.find((l) => l.id == values.locationId)
                          ?.accountName
                      }{' '}
                      <br />
                      BSB:{' '}
                      {
                        location.find((l) => l.id == values.locationId)?.BSB
                      }{' '}
                      <br />
                      Account Number:{' '}
                      {
                        location.find((l) => l.id == values.locationId)
                          ?.accountNumber
                      }{' '}
                      <br />
                    </p>
                  </>
                )}
              </div>
              {/* {!wholeFormDisable && (
                  <> */}
              <div className="flex items-center">
                {type == 'edit' &&
                  invoiceModal.data.source != 'Teachwork' &&
                  invoiceModal.data.xero_invoice_status != 'VOIDED' &&
                  invoiceModal.data.xero_invoice_balance != 0 && (
                    <>
                      <div className="mt-4 sm:mt-6 bottom-3 right-3">
                        <Button
                          type="button"
                          className="bg-green-500 hover:bg-green-600 duration-500 cursor-pointer"
                          onClick={() => {
                            onMarkAsPaid(invoiceModal.data.id, null)
                          }}
                          disabled={
                            amountPayable == 0 ||
                            invoiceModal.data.source == 'Teachwork' ||
                            invoiceModal.data.xero_invoice_status !=
                              'AWAITING PAYMENT'
                          }
                        >
                          Register a payment
                        </Button>
                      </div>
                    </>
                  )}
                {type == 'edit' &&
                  invoiceModal.data.source != 'Teachwork' &&
                  invoiceModal.data.xero_invoice_status != 'VOIDED' &&
                  invoiceModal.data.paymentMaster.length == 0 && (
                    <div className="mt-4 sm:mt-6 bottom-3 right-3">
                      <Button
                        type="button"
                        className="bg-red-600 hover:bg-red-700 duration-500 cursor-pointer"
                        disabled={invoiceModal.data.paymentMaster.length > 0}
                        onClick={() => {
                          onDeleteInvoiceOpen(invoiceModal.data.id, null)
                        }}
                      >
                        Void
                      </Button>
                    </div>
                  )}
                {type == 'edit' &&
                  invoiceModal.data.source == 'Teachwork' &&
                  invoiceModal.data.xero_invoice_balance != 0 && (
                    <div className="mt-4 sm:mt-6 bottom-3 right-3">
                      <Button
                        onClick={() => {
                          markPaidTeachwork(invoiceModal.data.id, null)
                        }}
                        className="text-indigo-900 bg-indigo-100 hover:bg-indigo-400 duration-500 cursor-pointer"
                      >
                        <span className="text-indigo-900">Mark as paid</span>
                      </Button>
                    </div>
                  )}
                <div className="mt-4 sm:mt-6 bottom-3 right-3">
                  <Button
                    type="submit"
                    disabled={
                      invoiceModal.data.source == 'Teachwork' ||
                      (type == 'edit' &&
                        invoiceModal.data.amountPayable !=
                          invoiceModal.data.xero_invoice_balance)
                    }
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Submitting...'
                        : 'Approving...'
                      : type === 'add'
                      ? 'Submit Invoice'
                      : 'Approve Invoice'}
                  </Button>
                </div>
              </div>
              {/* </>
                )} */}
            </div>
            {/* </div> */}
          </form>
        </BigSlideover>
      )}
    </Formik>
  )
}
export default InvoiceModal
