import { toast } from 'react-toastify'
import app_api from '../../config/api'
import { familySchema } from '../../schema'
import BigSlideover from '../../components/big-slideover'
import Select from 'react-select'
import { Formik } from 'formik'
import Input from '../../components/input'
import { Switch } from '@headlessui/react'
import moment from 'moment'
import Button from '../../components/button'

const EditFamilyModal = (props) => {
  const { type, state, edit_id, data } = props.familyModal
  const {
    parentEnabled,
    cleanFamilyModalData,
    getContactData,
    setFamilyModal,
    titles,
    studentCity,
    studentState,
    getCities,
    checkPostCode,
    location,
    handleParentCheckedChanges,
    familyDetails,
    onStudentEditOpen,
    onEditInvoiceOpen,
    postCodeError,
    defaultState,
    classNames,
    onEditCreditOpen = { onEditCreditOpen },
  } = props
  return (
    <Formik
      initialValues={data}
      validationSchema={familySchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (type === 'add') {
          app_api
            .post('/family-master', {
              ...values,
              active: parentEnabled,
              createdBy: JSON.parse(localStorage.getItem('moldKey')).email,
            })
            .then((res) => {
              cleanFamilyModalData()
              getContactData()
              toast.success('Successfully Added Family')
              setSubmitting(false)
              resetForm()
            })
            .catch((err) => {
              cleanFamilyModalData()
              toast.error(err.response.data.message)
              setSubmitting(false)
            })
        } else {
          app_api
            .patch(`/family-master/${edit_id}`, {
              ...values,
              active: parentEnabled,
              updatedBy: JSON.parse(localStorage.getItem('moldKey')).email,
            })
            .then((res) => {
              cleanFamilyModalData()
              getContactData()
              setSubmitting(false)
              toast.success('Successfully Updated Family')
              resetForm()
            })
            .catch((err) => {
              cleanFamilyModalData()
              toast.error(err.response.data.message)
            })
        }
      }}
    >
      {({
        handleBlur,
        Formik,
        handleChange,
        handleSubmit,
        setValues,
        setFieldTouched,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }) => (
        <BigSlideover
          open={state}
          setOpen={() => {
            setFamilyModal((prev) => ({ ...prev, state: false }))
          }}
          title={'Family'}
          handleClick={''}
          data={''}
        >
          <form onSubmit={handleSubmit} noValidate>
            <div className="mt-4 text-left">
              <div className="inline-grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Title
                    </label>
                    {/* <span className='text-red-700 ml-1'>*</span> */}
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="title"
                    id="title"
                    isSearchable
                    loadingMessage="Getting Title..."
                    placeholder="Title..."
                    value={
                      values?.title
                        ? titles?.map((l) => ({
                            value: titles?.find((e) => e.id == values.title)
                              ?.id,
                            label: titles?.find((e) => e.id == values.title)
                              ?.name,
                          }))
                        : null
                    }
                    options={titles.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        title: option.value,
                      })
                    }}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                    onFocus={() => setFieldTouched('title', false)}
                  />
                  {touched.title && (
                    <p className="text-red-700 error_msg">{errors.title}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      First Name
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter First Name..."
                    onFocus={() => setFieldTouched('firstName', false)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {touched.firstName && (
                    <p className="text-red-700 error_msg">{errors.firstName}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Last Name
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Last Name..."
                    onFocus={() => setFieldTouched('lastName', false)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {touched.lastName && (
                    <p className="text-red-700 error_msg">{errors.lastName}</p>
                  )}
                </div>
              </div>

              <div className="inline-grid grid-cols-1 md:grid-cols-3 mt-4 w-full gap-3">
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="email"
                    label="Email Address"
                    value={values.email}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Email Address..."
                    onFocus={() => setFieldTouched('email', false)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {touched.email && (
                    <p className="text-red-700 error_msg">{errors.email}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Mobile No.
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Input
                    id="mobileNo"
                    name="mobileNo"
                    placeholder="Enter Mobile Number"
                    type="tel"
                    value={values.mobileNo}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        mobileNo: e.target.value,
                      })
                    }}
                    onFocus={() => setFieldTouched('mobileNo', false)}
                  />
                  {touched.mobileNo && (
                    <p className="text-red-700 mb-2 error_msg">
                      {errors.mobileNo}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Address Line 1
                    </label>
                    {/*  <span className="text-red-500 ml-1">*</span> */}
                  </div>
                  <Input
                    name="address1"
                    // label="Address Line 1"
                    value={values.address1}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Address Line 1..."
                  />
                  {touched.address1 && (
                    <p className="text-red-700 error_msg">{errors.address1}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      City
                    </label>
                    {/*  <span className="text-red-500 ml-1">*</span> */}
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="city"
                    id="city"
                    isSearchable
                    loadingMessage="Getting city..."
                    placeholder="Select city..."
                    value={
                      values.city
                        ? studentCity?.map((l) => ({
                            value: studentCity?.find(
                              (e) => e.name == values.city
                            )?.isoCode,
                            label: studentCity?.find(
                              (e) => e.name == values.city
                            )?.name,
                          }))
                        : null
                    }
                    options={studentCity?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        city: option.value,
                      })
                    }}
                    onFocus={() => setFieldTouched('city', false)}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                  {touched.city && (
                    <p className="text-red-700 error_msg">{errors.city}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      State
                    </label>
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="parentState"
                    id="parentState"
                    isSearchable
                    loadingMessage="Getting state..."
                    placeholder="Select state..."
                    value={
                      values.parentState
                        ? studentState?.map((l) => ({
                            value: studentState?.find(
                              (e) => e.name == values.parentState
                            )?.isoCode,
                            label: studentState?.find(
                              (e) => e.name == values.parentState
                            )?.name,
                          }))
                        : defaultState
                        ? studentState?.map((l) => ({
                            value: studentState?.find(
                              (e) => e.name == defaultState[0].name
                            )?.isoCode,
                            label: studentState?.find(
                              (e) => e.name == defaultState[0].name
                            )?.name,
                          }))
                        : null
                    }
                    options={studentState?.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.name,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        parentState: option.value,
                      })
                      getCities(option.countryCode, option.isoCode)
                      checkPostCode(values.postCode, option.value)
                    }}
                    onFocus={() => setFieldTouched('parentState', false)}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                  {touched.parentState && (
                    <p className="text-red-700 error_msg">
                      {errors.parentState}
                    </p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Postcode
                    </label>
                    {/*  <span className="text-red-500 mx-1">*</span> */}
                  </div>
                  <input
                    name="postCode"
                    label="Postcode"
                    value={values.postCode}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      console.log(values.parentState)
                      setValues({
                        ...values,
                        postCode: e.target.value,
                      })
                      checkPostCode(e.target.value, values.parentState)
                    }}
                    maxLength={4}
                    placeholder="Enter Postcode..."
                    onFocus={() => setFieldTouched('postCode', false)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {touched.postCode && (
                    <p className="text-red-700 error_msg">{errors.postCode}</p>
                  )}
                  {postCodeError && (
                    <p className="text-red-700 error_msg">{postCodeError}</p>
                  )}
                </div>
                <div className="">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Preferred location
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <Select
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    name="center"
                    id="center"
                    isSearchable
                    loadingMessage="Getting location..."
                    placeholder="Select a location..."
                    value={
                      values.center
                        ? location?.map((l) => ({
                            value: location?.find((e) => e.id == values.center)
                              ?.id,
                            label: location?.find((e) => e.id == values.center)
                              ?.name,
                          }))
                        : null
                    }
                    options={location.map((l) => ({
                      ...l,
                      label: l.name,
                      value: l.id,
                    }))}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        center: option.value,
                      })
                    }}
                    onFocus={() => setFieldTouched('center', false)}
                    onBlur={handleBlur}
                    menuPlacement="auto"
                    maxMenuHeight={110}
                  />
                  {touched.center && (
                    <p className="text-red-700 error_msg">{errors.center}</p>
                  )}
                </div>
                <div className="mt-8">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-start"
                  >
                    <span className="flex flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Activate Parent
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={parentEnabled == 'Active'}
                      onChange={() => {
                        handleParentCheckedChanges()
                      }}
                      className={classNames(
                        parentEnabled == 'Active'
                          ? 'bg-indigo-600'
                          : 'bg-gray-200',
                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          parentEnabled == 'Active'
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              </div>
              {type == 'edit' && (
                <>
                  <div className="">
                    <hr className="mt-4 w-full"></hr>
                    <div className="mt-4">
                      <div className="flex mb-2">
                        <p>Students</p>
                        <d className="text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center">
                          <p>{familyDetails.studentMaster.length}</p>
                        </d>
                      </div>
                      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-left text-gray-600">
                            <thead class="text-gray-700 bg-stone-300">
                                <tr>
                                    <th scope="col" class="px-6 py-3 min-w-[100px]">
                                      Student Name
                                    </th>
                                    <th scope="col" class="px-6 py-3 min-w-[100px]">
                                      Grade
                                    </th>
                                    <th scope="col" class="px-6 py-3 min-w-[100px]">
                                      Status
                                    </th>                                                                      
                                </tr>
                            </thead>
                            <tbody>
                              {familyDetails.studentMaster.map((answer, idx) => {
                                return (
                                <tr class="bg-white border-b hover:bg-gray-50">
                                    <td class="px-6 py-4 min-w-[100px]">
                                      <span
                                        className="cursor-pointer hover:text-indigo-800"
                                        onClick={() => onStudentEditOpen(answer.id)}
                                      >
                                        {answer.studentsFirstName +
                                          ' ' +
                                          answer.studentsLastName}
                                      </span>
                                    </td>
                                    <td class="px-6 py-4 min-w-[100px]">
                                      {answer.gradeMaster?.grade}
                                    </td>
                                    <td class="px-6 py-4 min-w-[100px]">
                                      {answer.active}
                                    </td>                                      
                                  </tr>
                                )})}
                            </tbody>
                          </table>
                        </div>
                    </div>
                    <hr className="mt-4 w-full"></hr>
                    <div className="mt-4">
                      <div className="flex mb-2">
                        <p>Invoice</p>
                        <d className="text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center">
                          <p>{familyDetails.crmInvoice.length}</p>
                        </d>
                      </div>
                      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-left text-gray-600">
                          <thead class="text-gray-700 bg-stone-300">
                              <tr>
                                <th scope="col" class="px-6 py-3 min-w-[100px]">
                                  Invoice Number
                                </th>
                                <th scope="col" class="px-6 py-3 min-w-[100px]">
                                  Total
                                </th>
                                <th scope="col" class="px-6 py-3 min-w-[100px]">
                                  Balance
                                </th>                                                                      
                                <th scope="col" class="px-6 py-3 min-w-[100px]">
                                  Status
                                </th>                                                                      
                              </tr>
                          </thead>
                          <tbody>
                          {familyDetails.crmInvoice.map((answer, idx) => {
                            if (answer.xero_invoice_url != null) {
                              return (
                                <tr class="bg-white border-b hover:bg-gray-50">
                                  <td class="px-6 py-4 min-w-[100px]">
                                    <span
                                        className="cursor-pointer hover:text-indigo-800"
                                        onClick={() =>
                                          onEditInvoiceOpen(answer.id)
                                        }
                                      >
                                        {answer.xero_invoice_no}
                                      </span>
                                  </td>                                  
                                  <td class="px-6 py-4 min-w-[100px]">
                                    ${answer.amountPayable}
                                  </td>                                      
                                  <td class="px-6 py-4 min-w-[100px]">
                                    ${answer.xero_invoice_balance}
                                  </td>                                      
                                  <td class="px-6 py-4 min-w-[100px]">
                                    {answer.xero_invoice_status}
                                  </td>                                      
                                </tr>
                              )
                            }
                          })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr className="mt-4 w-full"></hr>
                    <div className="mt-4">
                      <div className="flex mb-2">
                        <p>Payments</p>
                        <d className="text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center">
                          <p>
                            {familyDetails.crmInvoice.reduce(
                              (total, item) =>
                                total + item.paymentMaster.length,
                              0
                            )}
                          </p>
                        </d>
                      </div>
                      {/* <div className="mt-1 w-full border">
                        <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                          <div class="grid grid-cols-6 gap-4">
                            <div className="flex justify-center mt-2 font-semibold">
                              ID
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Invoice Number
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Amount
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Date
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Payment Method
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Description
                            </div>
                          </div>
                        </div>
                        {familyDetails.crmInvoice.map((answer, idx) => {
                          if (answer.xero_invoice_url != null) {
                            return (
                              <>
                                {answer.paymentMaster.map((payment, idx) => {
                                  let numId = payment.id.toString()
                                  let locationName = payment.location.name
                                  let prefix
                                  while (numId.length < 6) {
                                    numId = '0' + numId
                                  }
                                  if (
                                    locationName == 'Ringwood (Head Office)' ||
                                    locationName == 'Ringwood' ||
                                    locationName == 'Online' ||
                                    locationName == 'Narre Warren' ||
                                    locationName == 'Officer' ||
                                    locationName == 'Beaconsfield'
                                  ) {
                                    prefix = 'RWPAY-'
                                  } else {
                                    prefix = 'CNPAY-'
                                  }
                                  return (
                                    <div class="grid grid-cols-6 gap-4">
                                      <div className="flex justify-center mt-2">
                                        {prefix}
                                        {numId}
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        {answer.xero_invoice_no}
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        ${payment.amount}
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        {moment(payment.date).format(
                                          'DD-MM-YYYY'
                                        )}
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        {payment.paymentMethod}
                                      </div>
                                      <div className="flex justify-center mt-2">
                                        {payment.description}
                                      </div>
                                    </div>
                                  )
                                })}
                              </>
                            )
                          }
                        })}
                      </div> */}
                      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-left text-gray-600">
                          <thead class="text-gray-700 bg-stone-300">
                            <tr>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">ID</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Invoice Number</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Amount</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Date</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Payment Method</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {familyDetails.crmInvoice.map((answer) =>
                              answer.paymentMaster.map((payment) => {
                                let numId = payment.id.toString().padStart(6, '0');
                                let prefix = ['Ringwood (Head Office)', 'Ringwood', 'Online', 'Narre Warren', 'Officer', 'Beaconsfield'].includes(payment.location.name) ? 'RWPAY-' : 'CNPAY-';
                                return (
                                  <tr class="bg-white border-b hover:bg-gray-50">
                                    <td class="px-6 py-4">{prefix}{numId}</td>
                                    <td class="px-6 py-4">{answer.xero_invoice_no}</td>
                                    <td class="px-6 py-4">${payment.amount}</td>
                                    <td class="px-6 py-4">{moment(payment.date).format('DD-MM-YYYY')}</td>
                                    <td class="px-6 py-4">{payment.paymentMethod}</td>
                                    <td class="px-6 py-4">{payment.description}</td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr className="mt-4 w-full" />
                    <div className="mt-4">
                      <div className="flex mb-2">
                        <p>Credit Notes</p>
                        <d className="text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center">
                          <p>{familyDetails.creditNote.length}</p>
                        </d>
                      </div>
                      {/* <div className="mt-1 w-full border">
                        <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                          <div class="grid grid-cols-5 gap-4">
                            <div className="flex justify-center mt-2 font-semibold">
                              Credit Number
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Credit Amount
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Credit Balance
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Status
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Description
                            </div>
                          </div>
                        </div>
                        {familyDetails.creditNote?.map((answer, idx) => {
                          if (answer.creditId != null) {
                            return (
                              <div className="w-full bg-gray-100 shadow-sm">
                                <div class="grid grid-cols-5 gap-4">
                                  <div className="flex justify-center mt-2">
                                    <span
                                      className="cursor-pointer hover:text-indigo-300"
                                      onClick={() =>
                                        onEditCreditOpen(answer.id)
                                      }
                                    >
                                      {answer.creditId}
                                    </span>
                                  </div>
                                  <div className="flex justify-center mt-2">
                                    ${answer.amount}
                                  </div>
                                  <div className="flex justify-center mt-2">
                                    ${answer.balanceAmount}
                                  </div>
                                  <div className="flex justify-center mt-2">
                                    {answer.status}
                                  </div>
                                  <div className="flex justify-center mt-2">
                                    {answer.description}
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div> */}
                      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-left text-gray-600">
                          <thead class="text-gray-700 bg-stone-300">
                            <tr>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Credit Number</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Credit Amount</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Credit Balance</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Status</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {familyDetails.creditNote.map((answer) => (
                              <tr class="bg-white border-b hover:bg-gray-50">
                                <td class="px-6 py-4 cursor-pointer hover:text-indigo-800" onClick={() => onEditCreditOpen(answer.id)}>{answer.creditId}</td>
                                <td class="px-6 py-4">${answer.amount}</td>
                                <td class="px-6 py-4">${answer.balanceAmount}</td>
                                <td class="px-6 py-4">{answer.status}</td>
                                <td class="px-6 py-4">{answer.description}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr className="mt-4 w-full" />
                    <div className="mt-4">
                      <div className="flex mb-2">
                        <p>Refunds</p>
                        <d className="text-white text-md ml-2 bg-indigo-300 w-6 h-6 rounded-full flex justify-center">
                          <p>
                            {familyDetails.creditNote.reduce(
                              (total, item) => total + item.refund.length,
                              0
                            )}
                          </p>
                        </d>
                      </div>
                      {/* <div className="mt-1 w-full border">
                        <div className="w-full h-10 bg-gray-200 bg-stone-300 border-b">
                          <div class="grid grid-cols-4 gap-4">
                            <div className="flex justify-center mt-2 font-semibold">
                              Credit Number
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Amount
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Date
                            </div>
                            <div className="flex justify-center mt-2 font-semibold">
                              Payment Method
                            </div>
                          </div>
                        </div>
                        {familyDetails.creditNote.map((answer, idx) => {
                          if (answer.creditId != null) {
                            return (
                              <>
                                {answer.refund.map((id, idx) => {
                                  return (
                                    <div className="w-full bg-white shadow-sm">
                                      <div class="grid grid-cols-4 gap-4">
                                        <div className="flex justify-center mt-2">
                                          {answer.creditId}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          ${id.amount}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {moment(id.date).format('DD-MM-YYYY')}
                                        </div>
                                        <div className="flex justify-center mt-2">
                                          {id.status}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </>
                            )
                          }
                        })}
                      </div> */}
                      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-left text-gray-600">
                          <thead class="text-gray-700 bg-stone-300">
                            <tr>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Credit Number</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Amount</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Date</th>
                              <th scope="col" class="px-6 py-3 min-w-[100px]">Payment Method</th>
                            </tr>
                          </thead>
                          <tbody>
                            {familyDetails.creditNote.map((answer) =>
                              answer.refund.map((refund) => (
                                <tr class="bg-white border-b hover:bg-gray-50">
                                  <td class="px-6 py-4">{answer.creditId}</td>
                                  <td class="px-6 py-4">${refund.amount}</td>
                                  <td class="px-6 py-4">{moment(refund.date).format('DD-MM-YYYY')}</td>
                                  <td class="px-6 py-4">{refund.status}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="mt-5 sm:mt-6">
                <Button type="submit" disabled={isSubmitting || postCodeError}>
                  {isSubmitting
                    ? type === 'add'
                      ? 'Adding...'
                      : 'Saving...'
                    : type === 'add'
                    ? 'Add Family'
                    : 'Update Family'}
                </Button>
              </div>
            </div>
          </form>
        </BigSlideover>
      )}
    </Formik>
  )
}

export default EditFamilyModal
