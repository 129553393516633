import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PencilIcon, XCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import Editor from 'jodit-react'
import Button from '../components/button'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import Input from '../components/input'
import Modal from '../components/modals'
import { Sidebar } from '../components/navigation/sidebar'
import app_api from '../config/api'
import '../theme/style.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { editorConfig } from '../config/editor_config'
import 'react-accessible-accordion/dist/fancy-example.css'
import moment from 'moment'
import Select from 'react-select'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import {
  EditableMathField,
  addStyles as addMathquillStyles,
} from 'react-mathquill'
import TexSymbols from '../config/TexSymbols'
addMathquillStyles()

const SingleExamPage = () => {
  const [open, setOpen] = useState(false)
  const [modal, setModalType] = useState('')
  const [currentTab, setCurrentTab] = useState(1)
  const [questionField, setQuestionFields] = useState({})
  const [answerFields, setAnswerFields] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [singleExam, setSingleExam] = useState([])
  const [catLoading, setCatLoading] = useState(false)
  const [catError, setCatError] = useState(null)
  const [category, setCategory] = useState([])

  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const params = useParams()
  const editorRef = useRef()

  const interval = 2500

  const getActiveColor = (active) => {
    return active ? 'bg-green-600' : ''
  }

  const getActiveTextColor = (active) => {
    return active ? 'text-white' : 'text-gray-700'
  }

  const handleFormChange = (index, event) => {
    let data = [...answerFields]
    data.forEach((item, idx) => {
      data[index]['answer'] = event
    })
    setAnswerFields(data)
  }

  const handleFormAnswerChange = (index, event) => {
    let data = [...answerFields]
    if (event.target.name === 'is_correct') {
      data.forEach((item, idx) => {
        data[idx][event.target.name] = false
      })
      data[index][event.target.name] = true
    } else {
      data[index][event.target.name] = event.target.value
    }
    setAnswerFields(data)
  }

  const addFields = () => {
    let newfield = { name: '', is_correct: null }
    setAnswerFields([...answerFields, newfield])
  }

  const removeFields = (index) => {
    let data = [...answerFields]
    data.splice(index, 1)
    setAnswerFields(data)
  }

  const onContentStateChange = (content, id) => {
    setQuestionFields((prev) => ({
      ...prev,
      question: content,
      id: id,
    }))
  }

  const onJustificationChange = (content, id) => {
    setQuestionFields((prev) => ({
      ...prev,
      justification: content,
      id: id,
    }))
  }

  const getCategoryData = () => {
    app_api
      .get('/category')
      .then((res) => {
        setCatLoading(false)
        setCatError(null)
        setCategory(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setCatError(err?.response?.data?.message || 'error getting categories')
      })
  }

  const renderType = (questionData, answerData) => {
    return {
      1: (
        <div className="mt-6 text-left z-auto">
          <Editor
            config={editorConfig}
            className="jodit-status-bar__item"
            ref={editorRef}
            value={questionField.question}
            onChange={(newContent) =>
              onContentStateChange(newContent, questionData.id)
            }
          />

          {/* {addFormula && (
            <div className="flex items-center gap-4 mt-3">
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 rounded-md shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => {
                  injectMathIntoEditor('question', null)
                  setMathLatex('')
                }}
              >
                Add formula in question
              </button>
            </div>
          )} */}

          <div className="mt-4">
            <Input
              name="marks"
              label="Marks"
              placeholder="Enter marks..."
              type="number"
              autoComplete="off"
              className="mb-4"
              value={questionData?.marks}
              onChange={(event) =>
                setQuestionFields((prev) => ({
                  ...prev,
                  marks: event.target.value,
                }))
              }
            />
          </div>

          <div>
            <label htmlFor="category-choice">Category</label>
            <Select
              id="category-choice"
              name="category"
              className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={
                questionData?.category
                  ? category.map((l) => ({
                      value: category.find(
                        (e) => e.category == questionData.category
                      )?.id,
                      label: category.find(
                        (e) => e.category == questionData.category
                      )?.category,
                    }))
                  : null
              }
              placeholder="Select a Category"
              autoComplete="off"
              onChange={(option) => {
                setQuestionFields((prev) => ({
                  ...prev,
                  category: option.label,
                }))
              }}
              options={category?.map((l) => ({
                ...l,
                label: l.category,
                value: l.id,
              }))}
            ></Select>
            <div className="mt-4">
              <label htmlFor="justification">Justification</label>
              <Editor
                config={editorConfig}
                className="jodit-status-bar__item"
                ref={editorRef}
                value={questionField.justification}
                onChange={(newContent) =>
                  onJustificationChange(newContent, questionData.id)
                }
              />
              {/* {addFormula && (
                <div className="flex items-center gap-4 mt-3">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 rounded-md shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      injectMathIntoEditor('justification', null)
                      setMathLatex('')
                    }}
                  >
                    Add formula justification
                  </button>
                </div>
              )} */}
            </div>
          </div>

          <div className="mt-6">
            {answerData.map((answer, index) => {
              return (
                <>
                  <div
                    className="flex items-center justify-between mb-2 text-center"
                    key={index}
                  >
                    <Editor
                      config={editorConfig}
                      className="jodit-status-bar__item"
                      name="answer"
                      ref={editorRef}
                      value={answer.answer}
                      onChange={(content) => handleFormChange(index, content)}
                    />
                    <div key={index} className="flex items-center">
                      <input
                        id={answer.answer}
                        name="is_correct"
                        type="radio"
                        onChange={(event) =>
                          handleFormAnswerChange(index, event)
                        }
                        defaultChecked={answer.is_correct}
                        className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor={answer.answer}
                        className="block ml-1 text-sm font-medium text-gray-700"
                      >
                        Correct
                      </label>
                    </div>
                    <XCircleIcon
                      onClick={() => removeFields(index)}
                      className="w-8 h-full ml-2 -mr-1 text-red-600 hover:cursor-pointer"
                      aria-hidden="true"
                    />
                  </div>
                  {/* <div className="flex items-center gap-4 mt-3 mb-3">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 rounded-md shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => {
                        injectMathIntoEditor('answer', index)
                        setMathLatex('')
                      }}
                    >
                      Add formula in answer
                    </button>
                  </div> */}
                </>
              )
            })}

            {/* {!addFormula ? (
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-white bg-indigo-600 rounded-md shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => {
                  toggleAddFormula()
                }}
              >
                Create Math Formula
              </button>
            ) : (
              <div className={'math-quill-container mt-3'}>
                <EditableMathField
                  className="w-full min-h-50"
                  latex={mathLatex || ''}
                  onChange={handleChange}
                />
                <hr />
                <TexSymbols onClick={handleInsertSymbol} />
                <hr className="my-4" />
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 rounded-md shadow-sm sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      toggleAddFormula()
                    }}
                  >
                    Cancel Math Formula
                  </button>
                </div>
              </div>
            )} */}

            <div className="flex mt-4">
              <Button
                className="mr-2"
                disabled={answerFields.length < 2}
                onClick={() => {
                  modal === 'add'
                    ? addQuestion()
                    : onUpdateQuestion(questionData.id)
                }}
              >
                {modal === 'add' ? 'Submit' : 'Submit'}
              </Button>
              <Button className="ml-2" onClick={() => addFields()}>
                Add Option
              </Button>
            </div>
          </div>
        </div>
      ),
    }
  }

  const addQuestion = () => {
    app_api
      .post('/question', {
        question: questionField.question,
        marks: Number(questionField.marks),
        exam_id: Number(params.id),
        justification: questionField?.justification,
        type: 'single',
        category: questionField?.category ? questionField?.category : 'others',
        is_active: true,
      })
      .then((res) => {
        let updateSingleExam = { ...singleExam }
        updateSingleExam.questions.push({
          ...res.data,
          answers: [],
        })

        const question_id = res.data.id
        addAnswers(question_id)
        setQuestionFields((prev) => ({
          ...prev,

          id: res.data.id,
          question: res.data.question,
        }))
        toast.success('Question Added Successfully..')
      })
      .catch((err) => {
        let message = err.response.data.message[0]
        toast.error(`${message}`)
      })
  }

  const addAnswers = (data) => {
    app_api
      .post(`/answer/bulk`, {
        answers: answerFields,
        question_id: Number(data),
      })
      .then((res) => {
        setOpen(false)
        cleanModalData()
        getExamData()
      })
      .catch((err) => {
        toast.error('Unable to add answer. Please try again')
      })
  }

  const renderEditModal = (type) => {
    return (
      <Modal
        className="max-w-lg-2"
        title={modal.toUpperCase()}
        open={open}
        setOpen={() => {
          clearData()
          cleanModalData()
          setOpen(false)
        }}
      >
        <div className="mt-4">
          <div>{renderType(questionField, answerFields)[type]}</div>
        </div>
      </Modal>
    )
  }

  const cleanModalData = () => {
    setQuestionFields({})
    setAnswerFields([])
  }

  const onUpdateQuestion = (id) => {
    app_api
      .patch(`/question/${id}`, {
        question: questionField.question,
        category: questionField.category,
        justification: questionField?.justification,
        marks: Number(questionField?.marks),
      })
      .then((res) => {
        setOpen(false)
        toast.success('Updated Successfully')
        let updateSingleExam = { ...singleExam }
        updateSingleExam.questions[questionField.index].question =
          questionField.question
        updateSingleExam.questions[questionField.index].category.category =
          questionField.category
        updateSingleExam.questions[questionField.index].justification =
          questionField?.justification
        updateSingleExam.questions[questionField.index].marks =
          questionField?.marks
        addUpdateQuestion(questionField.id, answerFields)
        setSingleExam(updateSingleExam)
        cleanModalData()
        // window.location.reload(false);
        // getExamData()
      })
      .catch((err) => {
        setOpen(false)
        toast.error('Something went wrong')
      })
  }

  const addUpdateQuestion = (id, answer) => {
    app_api
      .post(`/answer/bulk`, {
        answers: answer,
        question_id: Number(id),
      })
      .then((res) => {
        setOpen(false)
        cleanModalData()
        getExamData()
      })
      .catch((err) => {
        toast.error('Unable to add answer. Please try again')
      })
  }

  const getExamData = () => {
    app_api
      .get(`/exam/${params.id}`)
      .then((res) => {
        setSingleExam(res.data)
        setLoading(false)
        setError(null)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const clearData = () => {
    setQuestionFields({})
    setAnswerFields([])
  }

  const deleteQuestion = () => {
    const id = questionField.id
    app_api
      .delete(`/question/${id}`)
      .then((res) => {
        getExamData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  function convert_from_sqrt_to_styled(text) {
    const pattern = /\$sqrt\((\d+)\)/g

    const replacedText = text?.replace(pattern, (match, number) => {
      return `<span style="white-space: nowrap; font-size:larger">
              &radic;<span style="text-decoration:overline;">&nbsp;${number}&nbsp;</span>
            </span>`
    })
    return replacedText
  }

  useEffect(() => {
    getExamData()
    getCategoryData()
  }, [])

  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const sidebarRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarVisible(false)
      }
    }
    if (isSidebarVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSidebarVisible])

  const [addFormula, setAddFormula] = useState(false)
  const toggleAddFormula = (modalName) => {
    setAddFormula(!addFormula)
  }

  const [mathLatex, setMathLatex] = useState('')

  const injectMathIntoEditor = (type, index) => {
    const mathHTML = katex.renderToString(mathLatex, {
      throwOnError: false,
      output: 'mathml',
    })
    if (type == 'question') {
      return setQuestionFields((prevFields) => {
        const question = prevFields.question
        const lastClosingPIndex = question.lastIndexOf('</p>')
        if (lastClosingPIndex === -1) return prevFields
        const updatedQuestion =
          question.slice(0, lastClosingPIndex) +
          mathHTML +
          question.slice(lastClosingPIndex)

        return { ...prevFields, question: updatedQuestion }
      })
    }
    if (type == 'justification') {
      return setQuestionFields((prev) => {
        const justification = prev.justification
        const lastClosingPIndex = justification.lastIndexOf('</p>')

        if (lastClosingPIndex === -1) return prev
        const updatedJustification =
          justification.slice(0, lastClosingPIndex) +
          mathHTML +
          justification.slice(lastClosingPIndex)

        return {
          ...prev,
          justification: updatedJustification,
        }
      })
    }

    if (type == 'answer') {
      setAnswerFields((prevAnswerFields) => {
        const updatedAnswers = [...prevAnswerFields];
        if (index >= 0 && index < updatedAnswers.length) {
          const answer = updatedAnswers[index]['answer'];
          const lastClosingPIndex = answer.lastIndexOf('</p>');
    
          if (lastClosingPIndex !== -1) {
            const updatedAnswer =
              answer.slice(0, lastClosingPIndex) +
              mathHTML +
              answer.slice(lastClosingPIndex);
            updatedAnswers[index] = {
              ...updatedAnswers[index],
              answer: updatedAnswer,
            };
          }
        }    
        return updatedAnswers;
      });
    }
  }

  function handleChange(mathField) {
    setMathLatex(mathField.latex())
  }
  function handleInsertSymbol(symbol) {
    setMathLatex((oldFormula) => `${oldFormula} ${symbol}`.trim())
  }

  useEffect(() => {
    if (!addFormula) {
      setMathLatex('')
      setAddFormula(false)
    }
    if (!open) {
      setMathLatex('')
      setAddFormula(false)
    }
  }, [addFormula, open])

  return (
    <Sidebar>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={deleteQuestion}
        clearDataOnClose={clearData}
      />
      <div className=" flex flex-column justify-between">
        <div className="sm:flex sm:items-center w-full top-0 h-[100px]">
          <div className="sm:flex-auto fixed-h1-margin">
            <h1 className="text-3xl font-semibold text-gray-900">Question</h1>          
            {
              JSON.parse(localStorage.getItem('moldKey')).role !== 'exam_view_admin' ? <>
              <p className="my-2 text-sm text-gray-700">
                Drag and Drop to rearrange.
              </p>              
              </> : <></>
            }
          </div>
          {
              JSON.parse(localStorage.getItem('moldKey')).role !== 'exam_view_admin' ? 
              <>
                <div className="sm:mt-0 mx-2 sm:flex-none ">
                  <Button
                    onClick={() => {
                      setModalType('add')
                      setOpen(true)
                    }}
                    className="flex justify-center items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mt-0 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Add
                  </Button>
                </div>
              </> : <></>
          }
          <div
            className={`mx-2 sm:mt-0 sm:flex-none ${
              isSidebarVisible ? 'hidden' : 'block'
            } hidden md:block`}
          >
            <button
              onClick={() => setIsSidebarVisible(!isSidebarVisible)}
              className={`text-white bg-indigo-800 font-medium rounded-lg text-sm px-4 py-2 outline-none transition-all duration-500 ease-in-out transform ${
                isSidebarVisible
                  ? 'opacity-0 scale-95 pointer-events-none'
                  : 'opacity-100 scale-100'
              }`}
            >
              {isSidebarVisible ? 'Hide Exam Details' : 'Show Exam Details'}
            </button>
          </div>
        </div>
        <div className="w-full mb-10">
          {renderEditModal(currentTab)}
          {singleExam?.questions?.length > 0 &&
            singleExam?.questions?.map((singleExamData, index) => {              
              return (
                <>
                  <div className="bg-white border shadow-md mr-3 ml-3 mt-5 mb-3 rounded-lg pb-3 fontStandard">
                    <div className="mx-3 my-3 relative items-center block border-b-1 rounded-lg ring-0 ring-indigo-500 focus:outline-none">
                      <div className="flex flex-col-reverse sm:flex-col-reverse md:flex-row gap-3 sm:gap-3 justify-between">
                        <div className="flex justify-start">
                          <div className="mr-2">
                            <span>Q.{index + 1} </span>
                          </div>
                          <div
                            className="inline-first-p"
                            dangerouslySetInnerHTML={{
                              __html: convert_from_sqrt_to_styled(
                                singleExamData.question
                              ),
                            }}
                          ></div>
                        </div>
                        {
                          JSON.parse(localStorage.getItem('moldKey')).role !== 'exam_view_admin' ? 
                          <>
                            <div className="flex">
                              <PencilIcon
                                onClick={() => {
                                  setModalType('edit')
                                  setOpen(true)
                                  setQuestionFields({
                                    question: singleExamData.question,
                                    id: singleExamData.id,
                                    index: index,
                                    marks: singleExamData?.marks,
                                    category: singleExamData?.category?.category,
                                    justification: singleExamData?.justification,
                                  })

                                  setAnswerFields(singleExamData.answers)
                                }}
                                className="w-6 h-6 mx-2 text-gray-600 hover:cursor-pointer"
                                aria-hidden="true"
                              />

                              <TrashIcon
                                onClick={() => {
                                  setQuestionFields({
                                    question: singleExamData.question,
                                    id: singleExamData.id,
                                    index: index,
                                    marks: singleExamData?.marks,
                                  })
                                  setConfirmationDialog(true)
                                }}
                                className="w-6 h-6 mx-2 text-red-600 hover:cursor-pointer"
                                aria-hidden="true"
                              />
                            </div>
                          </> 
                          : <></>
                        }
                      </div>
                    </div>
                    <fieldset>
                    <p className='px-5 font-semibold'>Justification:</p>
                    <div className="px-5 py-3"
                      dangerouslySetInnerHTML={{
                        __html: convert_from_sqrt_to_styled(
                          singleExamData.justification
                        ),
                      }}
                    >                      
                      {/* <p>Justification - {singleExamData.justification ? singleExamData.justification : ""}</p> */}
                    </div>
                    </fieldset>
                    <fieldset>
                      <div className="mt-2 mx-12 border-t border-b border-gray-200 divide-y divide-gray-200">
                        {singleExamData?.answers?.map((answer, idx) => (
                          <div
                            key={idx}
                            className={`relative flex items-start py-2 px-2 rounded-sm ${getActiveColor(
                              answer.is_correct
                            )}`}
                          >
                            <div className="flex-1 min-w-0 text-sm">
                              <label
                                htmlFor={`${answer}`}
                                className={`select-none font-medium ${getActiveTextColor(
                                  answer.is_correct
                                )} flex`}
                              >
                                {`${idx + 1}. `}
                                <span
                                  className="ml-2"
                                  dangerouslySetInnerHTML={{
                                    __html: convert_from_sqrt_to_styled(
                                      answer.answer
                                    ),
                                  }}
                                ></span>
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                </>
              )
            })}
        </div>
        {!open && !confirmationDialog && (
          <button
            onClick={() => setIsSidebarVisible(!isSidebarVisible)}
            className={`fixed top-4 right-4 z-50 text-white bg-indigo-800 font-medium rounded-lg text-sm px-4 py-2 outline-none transition-all duration-500 ease-in-out transform ${
              isSidebarVisible
                ? 'opacity-0 scale-95 pointer-events-none'
                : 'opacity-100 scale-100'
            } md:hidden`}
          >
            {isSidebarVisible ? 'Hide Exam Details' : 'Show Exam Details'}
          </button>
        )}
        <div
          ref={sidebarRef}
          className={`fixed right-0 top-0 z-20 h-[750px] w-[300px] p-4 bg-white border-l transition-transform duration-300 ${
            isSidebarVisible ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          {isSidebarVisible && (
            <button
              class="flex items-center justify-center w-8 h-8 text-gray-500 bg-gray-100 rounded-full hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={() => setIsSidebarVisible(!isSidebarVisible)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
          <h1 className="text-xl text-center font-bold">Exam Details</h1>
          <div>
            <div className="flex flex-row justify-between mt-4">
              <h1 className="font-bold text-gray-900 text-capitalize">
                <span className="text-sm mt-4 mb-2 font-semibold">Name:</span>
                <br></br>
                <span className="text-lg rounded-md text-blue-900 w-max py-1">
                  {singleExam?.name}
                </span>
              </h1>
            </div>

            <h4 className="text-md mt-4 mb-2 font-semibold">Questions:</h4>
            <p className="text-white rounded-md bg-blue-900 px-3 text-md py-1 mb-2 text-center w-max">
              {singleExam?.questions?.length}
            </p>
            <h4 className="text-md mt-3 mb-2 font-semibold">Duration:</h4>
            <p className="text-white rounded-md bg-blue-900 px-3 text-md py-1 mb-2 text-center w-max">
              {singleExam?.duration + ' Min'}
            </p>
          </div>
          <label className="mt-3 font-semibold">Status:</label>
          <br></br>
          {singleExam?.status == 'active' ? (
            <div className="flex flex-row">
              <div className="flex flex-row items-center">
                <div className="w-4 h-4 bg-green-600 rounded-full mr-2"></div>
              </div>
              Active
            </div>
          ) : (
            <div className="flex flex-row">
              <div className="flex flex-row items-center">
                <div className="w-4 h-4 bg-red-700 rounded-full mr-2"></div>
              </div>
              Draft
            </div>
          )}
          <div>
            <h4 className="text-md mt-3 mb-2 font-semibold">Last Edited</h4>
            <p className="rounded-md w-max bg-yellow-200 px-2 text-yellow-800 font-semibold text-sm">
              {moment(singleExam?.updatedAt).format('DD MMM YYYY hh:mm A')}
            </p>
          </div>
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </Sidebar>
  )
}

export default SingleExamPage
