import React, { useEffect, useState } from 'react'
import app_api from '../config/api'
import Login from '../components/auth/login'
import { useNavigate } from 'react-router-dom'

const Auth = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [forgotError, setForgotError] = useState(null)
  const [error, setError] = useState(null)
  const [statusCode, setStatusCode] = useState(0)
  const [mfaMesage, setMFAMesage] = useState(null)
  const [authDataURL, setAuthDataURL] = useState()
  const [success, setSuccess] = useState(null)
  const [email, setEmail] = useState(null)
  const [isStudent, setIsStudent] = useState(false)

  const login = (values) => {
    const payload = {}
    const isEmail =
      values?.username_or_email.includes('@') &&
      values?.username_or_email.includes('.')
    if (isEmail) {
      payload.email = values.username_or_email
      payload.password = values.password
      payload.isStudent = false
    } else {
      payload.username = values.username_or_email
      payload.password = values.password
      payload.isStudent = true
    }
    console.log(payload)
    setLoading(true)
    if (process.env.REACT_APP_NODE != 'production') {
      app_api
        .post('/auth/login', payload)
        .then((res) => {
          if (res.data.role == 'student') {
            setIsStudent(true)
          }
          setLoading(false)
          setError(null)
          if (res.status == 202 && res.data.role !== 'student') {
            setEmail(values.username_or_email)
            setStatusCode(res.status)
            setMFAMesage(res?.data?.message)
            if (res.data.role === 'student') {
              navigate('/login-student-waiting')
            } else if (res.data.role === 'exam_view_admin') {
              navigate('/exam')
            } else {
              if (res.data.role == 'manager') {
                navigate('/leads')
              } else {
                if (res.data.role == 'teacher') {
                  navigate('/teacher-schedule')
                } else {
                  if (res.data.role == 'parent') {
                    navigate('/schedule')
                  } else {
                    navigate('/leads')
                  }
                }
              }
            }
          } else if (res.status == 201 && res.data.role !== 'student') {
            if (res.data.role === 'exam_view_admin') {              
              localStorage.setItem('moldKey',JSON.stringify(res.data))
              navigate('/exam')
            } else {
              setEmail(values.username_or_email)
              setStatusCode(res.status)
              setAuthDataURL(res?.data)
            }
            // if (res.data.role === 'student'){
            //   navigate('/my-exams')
            // }
            // else{
            //   if(res.data.role == 'manager'){
            //     navigate('/leads')
            //   }
            //   else{
            //     if(res.data.role == 'teacher'){
            //       navigate('/teacher-schedule')
            //     }else{if(res.data.role == 'parent'){
            //       navigate('/schedule')
            //     }else{
            //       navigate('/leads')
            //     }
            //     }
            //   }
            // }
          } else if (res.status == 201 && res.data.role == 'student') {
            localStorage.setItem('moldKey', JSON.stringify(res.data))
            navigate('/login-student-waiting')
          }
        })
        .catch((err) => {
          setLoading(false)
          setError(
            err?.response?.data?.message || 'Please enter your correct password'
          )
        })
    } else {
      app_api
        .post('/auth/login', payload)
        .then((res) => {
          setLoading(false)
          setError(null)
          if (res.status == 202 && res.data.role !== 'student') {
            setStatusCode(res.status)
            setMFAMesage(res?.data?.message)
          } else if (res.status == 201 && res.data.role !== 'student') {
            setStatusCode(res.status)
            setAuthDataURL(res?.data)
          } else if (res.status == 201 && res.data.role == 'student') {
            localStorage.setItem('moldKey', JSON.stringify(res.data))
            navigate('/login-student-waiting')
          }
        })
        .catch((err) => {
          setLoading(false)
          setError(
            err?.response?.data?.message || 'Please enter your correct password'
          )
        })
    }
  }

  const forgotPasssword = (email) => {
    setLoader(true)
    app_api
      .post('/forgot-password', { email: email.forgotEmail })
      .then((res) => {
        setLoader(false)
        setForgotError(null)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailRegex.test(email.forgotEmail)) setSuccess('An email has been sent to the registered email address')
        else setSuccess('Password reset successfully, your username will be your password')
      })
      .catch((err) => {
        setLoader(false)
        setForgotError(
          err?.response?.data?.message || 'Please check your email address'
        )
      })
  }

  const genORCode = (values) => {
    console.log(values)
  }

  return (
    <div>
      <Login
        loading={loading}
        loader={loader}
        authDataURL={authDataURL}
        mfaMesage={mfaMesage}
        statusCode={statusCode}
        forgotError={forgotError}
        error={error}
        login={login}
        genORCode={genORCode}
        forgotPasssword={forgotPasssword}
        success={success}
        userEmail={email}
        isStudent={isStudent}
      />
    </div>
  )
}

export default Auth
