import { Routes, Route } from 'react-router-dom'
import './App.css'
import Auth from './pages/auth'
import Enrolments from './pages/enrolments'
import Services from './pages/services'
import Batch from './pages/batch'
import Location from './pages/location'
import Exam from './pages/exam'
import SingleExamPage from './pages/single_exam_page'
import InvoiceView from './pages/invoice.jsx'
import Contacts from './pages/contact.jsx'
import ProtectedRoutes from './components/protected routes/ProtectedRoutes'
import Category from './pages/category'
import { Logout } from './pages/logout'
import GradeSheet from './pages/gradesheet'
import { ProSidebarProvider } from 'react-pro-sidebar';
import NotFound from './pages/NotFound'
import { AccessControl } from './components/auth/accessControl'
import Exams from './pages/exams'
import ExamQuestions from './pages/examQuestions'
import { Preview } from './pages/preview'
import BatchStudent from './pages/batchStudent'
// import Dashboard from './pages/dashboard'
import ResetAuth from './pages/resetAuth'
import Reference from './pages/reference'
import ForgotPasssword from './pages/forgotPassword'
import EnrolmentPage from './pages/enrolmentPage'
import BuyMore from './pages/buy_more'
import LoginSession from './pages/loginSession'
import LeadsPage from './pages/leadsPage'
import UserPage from './pages/userPage'
import GradeMaster from './pages/grade-master'
import SubjectMAster from './pages/subject-master'
import SessionTypeMaster from './pages/session-type-master'
import FamilyMaster from './pages/FamilyMaster/FamilyMaster'
import Reports from './pages/reports'
import InvoiceMaster from './pages/invoiceMaster'
import TermMaster from './pages/TermMaster/termMasterNew.jsx'
import Student from './pages/StudentMaster/StudentMaster.jsx'
import CRMService from './pages/crmService'
import DiscountDescripton from './pages/discount-description'
import TuitionPurpose from './pages/tutionMaster'
import Payments from './pages/payments'
import MailLog from './pages/mail-log'
import TeacherMaster from './pages/teacherMaster'
import Credit from './pages/creditMaster'
import Refund from './pages/refund'
import ClassMaster from './pages/ClassMaster/ClassMaster'
import MyClass from './pages/myClass.jsx'
import TeacherSchedule from './pages/TeacherSchedule.jsx'
import TeacherInvoice from './pages/teacherInvoice.jsx'
import MyPayments from './pages/teacherPayments.jsx'
import ParentSchedule from './pages/parents/parentsSchedule.jsx'
import ParentInvoices from './pages/parents/parentsInvoices.jsx'
import MyChild from './pages/parents/mychild.jsx'
import Login_steps from './pages/login-steps'
import UploadedFiles from './pages/uploadFiles.jsx'
import SettingPage from './pages/setting.jsx'
import { useEffect, useState } from 'react'
import ReportPage from './pages/reportsPage.jsx'
import Dashboard from './pages/Dashboard/Dashboard.jsx'
import StudentLoginWaiting from './components/auth/StudentLoginWaiting.jsx'
import StudentLoginSession from './pages/StudentLoginSession/StudentLoginSession.jsx'

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Auth />} />
      <Route path="/invoice-view/:enrolment_id" element={<InvoiceView />} />
      <Route path="/" element={<ProtectedRoutes />}>
      {localStorage.getItem('moldKey') ?
          JSON.parse(localStorage.getItem('moldKey')).role === 'admin' || JSON.parse(localStorage.getItem('moldKey')).role === 'manager' ?
            <Route
              path="/" index={true}
              element={
                <AccessControl requiredRole={['admin', 'manager']}>
                  <Dashboard />
                </AccessControl>
              }
            />
            : JSON.parse(localStorage.getItem('moldKey')).role === 'parent' ?
              <Route
                path='/'
                element={
                  <AccessControl requiredRole={['parent']}>
                    <ParentSchedule />
                  </AccessControl>
                }
              /> : <Route
                path='/'
                element={
                  <AccessControl requiredRole="teacher">
                    <TeacherSchedule />
                  </AccessControl>
                }
              /> : null
        }

        <Route path="logout" element={<Logout />} />
        <Route
          path="/enrolments"
          element={
            <AccessControl requiredRole={['admin']}>
              <Enrolments />
            </AccessControl>
          }
        />
        <Route
          path="/invoice-master"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <InvoiceMaster />
            </AccessControl>
          }
        />
        <Route
          path="/batch-student/:id"
          element={
            <AccessControl requiredRole={['admin']}>
              <BatchStudent />
            </AccessControl>
          }
        />
        <Route
          path="/student"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <Student />
            </AccessControl>
          }
        />
        <Route
          path="/setting/crm-service"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <CRMService />
            </AccessControl>
          }
        />
        <Route
          path="/setting/discount-master"
          element={
            <AccessControl requiredRole={['admin']}>
              <DiscountDescripton />
            </AccessControl>
          }
        />
        <Route
          path="/setting/tuition-master"
          element={
            <AccessControl requiredRole={['admin']}>
              <TuitionPurpose />
            </AccessControl>
          }
        />
        <Route
          path="/contact"
          element={
            <AccessControl requiredRole={['admin']}>
              <Contacts />
            </AccessControl>
          }
        />
        <Route
          path="/email-log"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <MailLog />
            </AccessControl>
          }
        />
        <Route
          path="/payment-master"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <Payments />
            </AccessControl>
          }
        />
        <Route
          path="/services"
          element={
            <AccessControl requiredRole={['admin']}>
              <Services />
            </AccessControl>
          }
        />
        <Route
          path="/batch"
          element={
            <AccessControl requiredRole={['admin']}>
              <Batch />
            </AccessControl>
          }
        />
        <Route
          path="/setting/term-master"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <TermMaster />
            </AccessControl>
          }
        />
        <Route
          path="/category"
          element={
            <AccessControl requiredRole={['admin']}>
              <Category />
            </AccessControl>
          }
        />
        <Route
          path="/location"
          element={
            <AccessControl requiredRole={['admin']}>
              <Location />
            </AccessControl>
          }
        />
        <Route
          path="/referral"
          element={
            <AccessControl requiredRole={['admin']}>
              <Reference />
            </AccessControl>
          }
        />
        <Route
          path="/exam"
          element={
            <AccessControl requiredRole={['admin', 'exam_view_admin']}>
              <Exam />
            </AccessControl>
          }
        />
        <Route
          path="/exam/:id"
          element={
            <AccessControl requiredRole={['admin', 'exam_view_admin']}>
              <SingleExamPage />
            </AccessControl>
          }
        />
        <Route
          path="/setting/grade-master"
          element={
            <AccessControl requiredRole={['admin']}>
              <GradeMaster />
            </AccessControl>
          }
        />
        <Route
          path="/reports"
          element={
            <AccessControl requiredRole={['admin']}>
              <Reports />
            </AccessControl>
          }
        />
        <Route
          path="/setting/subject-master"
          element={
            <AccessControl requiredRole={['admin']}>
              <SubjectMAster />
            </AccessControl>
          }
        />
        <Route
          path="/session-type"
          element={
            <AccessControl requiredRole={['admin']}>
              <SessionTypeMaster />
            </AccessControl>
          }
        />
        <Route
          path="login-session"
          element={
            <AccessControl requiredRole={['admin']}>
              <LoginSession />
            </AccessControl>
          }
        ></Route>
        <Route
          path="family"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <FamilyMaster />
            </AccessControl>
          }
        ></Route>
        <Route
          path="/dashboard" index={true}
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <Dashboard />
            </AccessControl>
          }
        ></Route>
        <Route
          path="leads" index={true}
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <LeadsPage />
            </AccessControl>
          }
        ></Route>
        <Route
          path="teacher-master"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <TeacherMaster />
            </AccessControl>
          }
        ></Route>
        <Route
          path="class-master"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <ClassMaster />
            </AccessControl>
          }
        />
        <Route
          path="credit-note"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <Credit />
            </AccessControl>
          }
        />
        <Route
          path="student-login-sessions"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <StudentLoginSession />
            </AccessControl>
          }
        />
        {/* <Route
          path="/refund"
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <Refund />
            </AccessControl>
          }
        /> */}
        <Route
          path="users"
          element={
            <AccessControl requiredRole={['admin']}>
              <UserPage />
            </AccessControl>
          }
        ></Route>
        <Route
          path="my-exams"
          element={
            <AccessControl requiredRole="student">
              <Exams />
            </AccessControl>
          }
        ></Route>

        <Route
          path="buy-more"
          element={
            <AccessControl requiredRole="student">
              <BuyMore />
            </AccessControl>
          }
        ></Route>

        <Route
          path="my-exams/:enrolment_id/:exam_id/questions"
          element={
            <AccessControl requiredRole="student">
              <ProSidebarProvider>
                <ExamQuestions />
              </ProSidebarProvider>
            </AccessControl>
          }
        />
        {/* <Route
          path="/dashboard"
          element={
            <AccessControl requiredRole="student">
              <Dashboard />
            </AccessControl>
          }
        /> */}
        <Route
          path="my-exams/:enrolment_id/:exam_id/preview"
          element={<Preview />}
        />

        <Route
          path='my-class'
          element={
            <AccessControl requiredRole="teacher">
              <MyClass />
            </AccessControl>
          }
        />
        <Route
          path='teacher-schedule'
          element={
            <AccessControl requiredRole={['admin', 'manager', 'teacher']}>
              <TeacherSchedule />
            </AccessControl>
          }
        />
        <Route
          path='teacher-invoice'
          element={
            <AccessControl requiredRole={['admin', 'manager', 'teacher']}>
              <TeacherInvoice />
            </AccessControl>
          }
        />
        <Route
          path='teacher-payments'
          element={
            <AccessControl requiredRole={['admin', 'manager', 'teacher']}>
              <MyPayments />
            </AccessControl>
          }
        />
        <Route
          path='/parent-invoice'
          element={
            <AccessControl requiredRole={['parent']}>
              <ParentInvoices />
            </AccessControl>
          }
        />
        <Route
          path='/schedule'
          element={
            <AccessControl requiredRole={['parent']}>
              <ParentSchedule />
            </AccessControl>
          }
        />
        <Route
          path='/my-child'
          element={
            <AccessControl requiredRole={['parent']}>
              <MyChild />
            </AccessControl>
          }
        />
        <Route
          path='/setting/naplan'
          element={
            <AccessControl requiredRole={['admin', 'manager']}>
              <UploadedFiles />
            </AccessControl>
          }
        />
        <Route path='/setting'
          element={
            <AccessControl requiredRole={['admin', 'manager']}><SettingPage /></AccessControl>
          }
        />
        <Route
          path="/reports/:path"
          element={
            <AccessControl requiredRole={['admin']}>
              <ReportPage />
            </AccessControl>
          }
        />
      </Route>
      <Route path="resetpassword" element={<ResetAuth />} />
      <Route path="gradesheet" element={<GradeSheet />} />
      <Route path="*" element={<NotFound />} />
      <Route path="reset-password/:id" element={<ForgotPasssword />} />
      <Route path="enquiry-form" element={<EnrolmentPage />} />
      <Route path="login-steps" element={<Login_steps />} />
      <Route path="login-student-waiting" element={<StudentLoginWaiting />} />
      {/* <Route path="student-login-sessions" element={<StudentLoginSession />} /> */}
    </Routes>
  )
}

export default App
