import moment from 'moment'
import Button from '../button'
import { DocumentArrowDownIcon } from '@heroicons/react/20/solid'
import {
  ArrowUpTrayIcon,
  PhotoIcon,
  ArrowDownTrayIcon,
  FunnelIcon,
  XMarkIcon,
  CheckIcon,
  ClipboardDocumentListIcon
} from '@heroicons/react/24/outline'

// const role = JSON.parse(localStorage.getItem('moldkey')).role
// console.log(role)

export const service_columns = ({ onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.name}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Cost',
    accessor: 'cost',
    Cell: (cell) => {
      if (cell.row.original.cost) {
        return <span>${cell.row.original.cost}</span>
      } else {
        return null
      }
    },
  },
  {
    Header: 'Exam',
    Cell: (cell) => {
      if (cell.row.original.exams) {
        let tags = []
        tags = cell?.row?.original?.exams.map((l) => l?.exam.name)
        return (
          <div className="flex flex-wrap w-64">
            {tags.map((value, index) => {
              return (
                <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1">
                  {value}
                </span>
              )
            })}
          </div>
        )
      } else {
        return '-'
      }
    },
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: (cell) => {
      if (cell.row.original.description) {
        return (
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: `${cell.row.original.description}`,
            }}
          ></div>
        )
      } else {
        return null
      }
    },
  },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const crm_service_columns = ({ onEditOpen, onDeleteOpen,onActiveCategory }) => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.name}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Type of Service',
    accessor: 'typeOf',
  },
  {
    Header: 'Cost',
    accessor: 'cost',
    accessor: (d) =>
      d.cost ? (
        <>
          <span>$ {d.cost}</span>
        </>
      ) : (
        <>
          <span>-</span>
        </>
      ),
  },
  {
    Header: 'Grade',
    accessor: 'gradeMaster.grade',
  },
  {
    Header: 'Group Size',
    accessor: (d) => d.sessionType.toLocaleUpperCase(),
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: (cell) => {
      if (cell.row.original.description) {
        return (
          <div
            className="text-base"
            dangerouslySetInnerHTML={{
              __html: `${cell.row.original.description}`,
            }}
          ></div>
        )
      } else {
        return null
      }
    },
  },
  {
    Header: 'Status',
    id: 'archive',
    accessor: (d) =>
      d.archive == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          {cell.row.original.archive == true && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Inactive
              <span className="sr-only">, </span>
            </Button>
          </span>)}
          {cell.row.original.archive == false && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-500 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onActiveCategory(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Active
              <span className="sr-only">, </span>
            </Button>
          </span>)}
        </>
      )
    },
  },
]

export const batch_columns = ({ onEditOpen, onDeleteOpen, onNavigation }) => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.name}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Start Timestamp',
    id: 'start_timestamp',
    accessor: (d) => moment(d.start_timestamp).format('DD MMM YYYY hh:mm A'),
  },
  {
    Header: 'End Timestamp',
    id: 'end_timestamp',
    accessor: (d) => moment(d.end_timestamp).format('DD MMM YYYY hh:mm A'),
  },
  {
    Header: 'Location',
    id: 'location',
    accessor: (d) => d.location.name,
  },
  // {
  //   id: 'is_active',
  //   Header: 'Is Active',
  //   accessor: (d) => d.is_active.toString(),
  // },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onNavigation(`/batch-student/${cell.row.original.id}`)
              }}
              size="default"
              variant="outlined"
            >
              View Student
              <span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const termMaster_columns = ({ onEditOpen, onDeleteOpen,onActiveCategory,onEditLocationOpen }) => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.name}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'State',
    accessor: 'state',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  // onClick={() => {
                  //   onEditOpen(cell.row.original.id, cell.row.index)
                  // }}
                >
                  {Array.isArray(cell.row.original.state) ? cell.row.original.state[0].value : cell.row.original.state }
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Start Date',
    id: 'startDate',
    accessor: (d) => moment(d.startDate).format('DD MMM YYYY'),
  },
  {
    Header: 'End Date',
    id: 'endDate',
    accessor: (d) => moment(d.endDate).format('DD MMM YYYY'),
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (d) =>
      d.status == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          {cell.row.original.archive == true && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Inactive
              <span className="sr-only">, </span>
            </Button>
          </span>)}
          {cell.row.original.archive == false && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-500 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onActiveCategory(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Active
              <span className="sr-only">, </span>
            </Button>
          </span>)}
        </>
      )
    },
  },
]

export const location_columns = ({ onEditOpen, onDeleteOpen, columns }) => {
  const allColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-900 cursor-pointer text-base"
                    onClick={() => {
                      onEditOpen(cell.row.original.id, cell.row.index)
                    }}
                  >
                    {cell.row.original.name}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Point Of Contact',
      accessor: (d) =>
        (d.pointOfContact == null && <p>-</p>) || <p>{d.pointOfContact}</p>,
    },
    {
      Header: 'Address',
      // accessor :(e) =>  (e.address).slice(0, 25),
      accessor: '_address',
      Cell: (cell) => {
        return <>{`${cell.row.original.address.slice(0, 25)}...`}</>
      },
    },
    {
      Header: 'Phone Number',
      accessor: (d) =>
        (d.phoneNumber == null && <p>-</p>) || <p>{d.phoneNumber}</p>,
      // accessor :(e) =>  (e.address).slice(0, 25),
    },
    {
      Header: 'Email Address',
      // accessor :(e) =>  (e.address).slice(0, 25),
      accessor: (d) =>
        (d.emailAddress == null && <p>-</p>) || <p>{d.emailAddress}</p>,
    },
    {
      Header: 'ABN',
      // accessor :(e) =>  (e.address).slice(0, 25),
      // accessor: '_address',
      accessor: (d) => (d.abn == null && <p>-</p>) || <p>{d.abn}</p>,
    },
    {
      Header: 'GST Registration Date',
      accessor: (d) => (d.gst == null && <p>-</p>) || <p>{d.gst}</p>,
    },
    {
      Header: 'ABN Status',
      accessor: (d) => (d.abnStatus == null && <p>-</p>) || <p>{d.abnStatus}</p>,
    },
    {
      Header: 'Entity Name',
      accessor: (d) =>
        (d.entityName == null && <p>-</p>) || <p>{d.entityName}</p>,
    },
    {
      Header: 'State',
      accessor: (d) => (d.state == null && <p>-</p>) || <p>{d.state}</p>,
    },
    {
      Header: 'PostCode',
      accessor: (d) => (d.postCode == null && <p>-</p>) || <p>{d.postCode}</p>,
    },
    {
      Header: 'City',
      accessor: (d) => (d.city == null && <p>-</p>) || <p>{d.city}</p>,
    },
    {
      Header: 'Account Name',
      accessor: (d) =>
        (d.accountName == null && <p>-</p>) || <p>{d.accountName}</p>,
    },
    {
      Header: 'BSB',
      accessor: (d) => (d.BSB == null && <p>-</p>) || <p>{d.BSB}</p>,
    },
    {
      Header: 'Account Number',
      accessor: (d) =>
        (d.accountNumber == null && <p>-</p>) || <p>{d.accountNumber}</p>,
    },
    {
      Header: 'Google Business',
      accessor: (d) =>
        d.googleurl ? (
          <>
            <a
              href={d.googleurl}
              target="_blank"
              className="inline-flex px-2 text-base leading-5 rounded-full bg-blue-100 shadow m-1"
            >
              Preview
            </a>
          </>
        ) : (
          <>
            <span>-</span>
          </>
        ),
    },
    {
      Header: 'Facebook',
      accessor: (d) =>
        d.facebook ? (
          <>
            <a
              href={d.facebook}
              target="_blank"
              className="inline-flex px-2 text-base leading-5 rounded-full bg-blue-100 shadow m-1"
            >
              Preview
            </a>
          </>
        ) : (
          <>
            <span>-</span>
          </>
        ),
    },
    {
      Header: 'Xero API Key',
      Cell: (cell) => {
        var str = cell.row.original.xeroAPIKey
        if (str?.length > 10) str = str.substring(0, 10)
        return (
          <>
            <p className="text-base">{str}... </p>
          </>
        )
      },
    },
    {
      Header: 'Xero Theme ID',
      Cell: (cell) => {
        var str = cell.row.original.brandingThemeID
        if (str?.length > 10) str = str.substring(0, 10)
        return (
          <>
            <p className="text-base">{str}... </p>
          </>
        )
      },
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Created By',
      accessor: (d) => d.createdBy,
    },
    {
      Header: 'Updated By',
      accessor: (d) => d.updatedBy,
    },
    {
      Header: 'Action',

      Cell: (cell) => {
        return (
          <>
            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <Button
                onClick={() => {
                  onEditOpen(cell.row.original.id, cell.row.index)
                }}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </Button>
            </span>

            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onDeleteOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </Button>
            </span>
          </>
        )
      },
    },
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const contact_columns = ({ onEditOpen }) => [
  {
    Header: 'Parent name',
    accessor: 'parentFirstName',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.parentFirstName}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Email',
    accessor: 'parentEmail',
  },
  {
    Header: 'Student name',
    accessor: 'studentsFirstName',
  },
  {
    Header: 'City',
    accessor: 'city',
  },
  {
    Header: 'Grade',
    accessor: 'grade',
  },
  // {
  //   id: 'is_active',
  //   Header: 'Is Active',
  //   accessor: (d) => d.is_active.toString(),
  // },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const student_columns = ({
  onEditOpen,
  onInactive,
  onDeleteOpen,
  onActive,
  onFamilyEditOpen,
  columns,
  onEditLocationOpen
}) => {
  const allColumns = [
    {
      Header: 'Student First Name',
      accessor: 'studentsFirstName',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-900 cursor-pointer text-base"
                    onClick={() => {
                      onEditOpen(cell.row.original.id, cell.row.index)
                    }}
                  >
                    {cell.row.original.studentsFirstName}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Student Last Name',
      accessor: (d) => (d.studentsLastName ? d.studentsLastName : '-'),
    },
    {
      Header: 'Student Email',
      accessor: (d) => (d.studentsEmail ? d.studentsEmail : '-'),
    },
    {
      Header: 'Student Mobile No',
      accessor: (d) => (d.studentPhone ? d.studentPhone : '-'),
    },
    {
      Header: 'Grade',
      accessor: (d) => d.gradeMaster?.grade,
    },
    {
      Header: 'Location',
      Cell: (cell) => {
        if (cell.row.original.locations) {
          return (
            <div className="flex flex-wrap w-64">
              {cell?.row?.original?.locations.map((l) => {
                return (
                  <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1"
                    onClick={()=>{
                      onEditLocationOpen(l.location.id)}}
                  >
                    {l.location.name}
                  </span>
                )
              })}
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'Parent Name',
      accessor: "parentlastname",
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-800 cursor-pointer text-base"
                    onClick={() => {
                      onFamilyEditOpen(cell.row.original.familyId)
                    }}
                  >
                    {cell.row.original.familyMaster ? `${cell.row.original.familyMaster.lastName}, ${cell.row.original.familyMaster.firstName}` : cell.row.original.parentLastName ? `${cell.row.original.parentLastName}, ${cell.row.original.parentFirstName}` : '-'}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Parent Email',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-800 cursor-pointer text-base"
                    onClick={() => {
                      onFamilyEditOpen(cell.row.original.familyId)
                    }}
                  >
                    {cell.row.original.familyMaster ? `${cell.row.original.familyMaster.email}` : cell.row.original.parentLastName ? `${cell.row.original.parentEmail}` : '-'}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'City',
      accessor: (d) => {
        if (d.familyMaster) {
          return d.familyMaster.city
        } else {
          if (d.city) {
            return d.city
          } else {
            return '-'
          }
        }
      },
    },
    {
      Header: 'State',
      accessor: (d) => {
        if (d.familyMaster) {
          return d.familyMaster.parentState
        } else {
          if (d.city) {
            return d.state
          } else {
            return '-'
          }
        }
      },
    },
    {
      Header: 'Source',
      accessor: (d) => (d.source ? d.source : '-'),
    },
    {
      Header: 'Status',
      accessor: (d) =>
        d.active == 'Active' ? (
          <p className="text-green-500 font-semibold">Active</p>
        ) : d.active == 'Inactive' || d.active == 'Retired' ? (
          <p className="text-red-500 font-semibold">{d.active}</p>
        ) : (
          <p className="text-black-500 font-semibold">Prospective</p>
        ),
    },
    {
      Header: 'Inactivation Reason',
      accessor: (d) => (d.description ? d.description : '-'),
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Created By',
      accessor: (d) => d.createdBy,
    },
    {
      Header: 'Updated By',
      accessor: (d) => d.updatedBy,
    },
    {
      Header: 'Action',

      Cell: (cell) => {
        return (
          <>
            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <Button
                onClick={() => {
                  onEditOpen(cell.row.original.id, cell.row.index)
                }}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </Button>
            </span>
            {cell.row.original.active == 'Active' && (
              <>
                <span className="inline-flex ml-2 px-2 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                  <Button
                    onClick={() => {
                      onInactive(cell.row.original.id, cell.row.index)
                    }}
                    className=""
                    size="default"
                    variant="outlined"
                  >
                    Mark as inactive
                  </Button>
                </span>
              </>
            )}
            {cell.row.original.active != 'Active' && (
              <>
                <span className="inline-flex ml-2 px-2 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                  <Button
                    onClick={() => {
                      onDeleteOpen(cell.row.original.id, cell.row.index)
                    }}
                    className=""
                    size="default"
                    variant="outlined"
                  >
                    Delete<span className="sr-only">, </span>
                  </Button>
                </span>
              </>
            )}
          </>
        )
      },
    },
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const enrolment_columns = () => [
  {
    Header: 'Type',
    accessor: 'enrolment_type',
    Cell: (cell) => {
      if (cell.row.original.enrolment_type) {
        return (
          <span>{cell.row.original.enrolment_type.toLocaleUpperCase()}</span>
        )
      } else {
        return null
      }
    },
  },
  {
    Header: 'Email',
    accessor: 'contact.parentEmail',
  },
  {
    Header: 'Name',
    accessor: 'contact.parentFirstName',
  },
  {
    Header: 'Phone',
    accessor: 'contact.parentMobileNo',
  },
  {
    Header: 'Student Email',
    accessor: 'contact.studentsEmail',
  },
  {
    Header: 'Student Name',
    accessor: 'contact.studentsFirstName',
  },
  {
    Header: 'Student Number',
    accessor: 'contact.studentsMobileNo',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Invoice',
    accessor: (d) =>
      d.xero_invoice_url && (
        <a href={`${d.xero_invoice_url}`} target="_blank">
          <DocumentArrowDownIcon
            className="ml-2 -mr-1 h-5 w-5 text-violet-400 hover:text-violet-300"
            aria-hidden="true"
          />
        </a>
      ),
  },
]

export const crm_invoice_columns = ({
  onEditOpen,
  markPaidTeachwork,
  onMarkAsPaid,
  onFamilyEditOpen,
  onEditLocationOpen,
  columns
}) => {
  const allColumns = [
    {
      Header: 'Invoice Number',
      accessor: 'xero_invoice_no',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              <span className="inline-flex leading-5">
                <a href={`${cell.row.original.xero_invoice_url}`} target="_blank">
                  <DocumentArrowDownIcon
                    className="hover:h-7 hover:w-7 duration-500 h-6 w-6 text-violet-400 hover:text-white hover:bg-indigo-800 rounded-full hover:px-1 hover:py-1 mr-4"
                    aria-hidden="true"
                  />
                </a>
                <p
                  className="hover:text-indigo-800 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.xero_invoice_no}
                  <span className="sr-only">, </span>
                </p>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Customer Name',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-800 cursor-pointer text-base"
                  onClick={() => {
                    onFamilyEditOpen(cell.row.original.customerId, cell.row.index)
                  }}
                >
                  {cell.row.original.customerId ? `${cell.row.original.family.lastName}, ${cell.row.original.family.firstName}` : '-'}
                  <span className="sr-only">, </span>
                </p>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Date',
      accessor: (d) => moment(d.xero_invoice_date).format('DD-MM-YYYY'),
    },
    {
      Header: 'Due Date',
      accessor: (d) => moment(d.xero_invoice_due_date).format('DD-MM-YYYY'),
    },
    {
      Header: 'State',
      accessor: (d) => d.state ? d.state : "-",
    },
    {
      Header: 'Status',
      accessor: (d) => (d.xero_invoice_status ? d.xero_invoice_status : '-'),
    },
    {
      Header: 'Total Amount',
      accessor: (d) => {
        if (d.amountPayable == null) return '-';
        const isInteger = Number.isInteger(d.amountPayable);
        return isInteger ? `$${d.amountPayable}` : `$${d.amountPayable.toFixed(2)}`;
      }
    },
    {
      Header: 'Balance',
      accessor: (d) =>
        d.xero_invoice_balance ? '$' + parseFloat(d.xero_invoice_balance).toFixed(2) : '-',
    },
    {
      Header: 'Discount',
      accessor: (d) => (d.discount ? '$'+d.discount: '-' ),
    },
    {
      Header: 'Location',
      // accessor: (d) => (d.location?.name ? d.location?.name : '-'),
      Cell: (cell) => {
        return (
          <>
            <div className="flex flex-wrap">
              <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer"
               onClick={() => {
                onEditLocationOpen(cell.row.original.locationId, cell.row.index)
              }}>
                  {cell.row.original.locationId ? `${cell.row.original.location?.name}` : '-'}
                  <span className="sr-only">, </span>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Void Reason',
      accessor: (d) => (d.voidReason ? d.voidReason : '-'),
    },
    {
      Header: 'Last Payment Date',
      accessor: (d) => d.lastPaymentDate ? `${moment(d.lastPaymentDate).format('DD MMM YYYY hh:mm A')}` : '-',
    },
    {
      Header: 'Last Payment',
      accessor: (d) => d.lastPayment ? `$ ${d.lastPayment}` : '-',
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Created By',
      accessor: (d) => d.createdBy,
    },
    {
      Header: 'Updated By',
      accessor: (d) => d.updatedBy,
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {cell.row.original.xero_invoice_status != 'VOIDED' &&
                cell.row.original.xero_invoice_status != 'PAID' &&
                cell.row.original.source != 'Teachwork' && (
                  <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
                    <Button
                      onClick={() => {
                        onMarkAsPaid(cell.row.original.id, cell.row.index)
                      }}
                      size="default"
                      variant="outlined"
                    >
                      Register a payment
                      <span className="sr-only">, </span>
                    </Button>
                  </span>
                )}
              {cell.row.original.xero_invoice_status != 'VOIDED' &&
                cell.row.original.xero_invoice_status != 'PAID' &&
                cell.row.original.source == 'Teachwork' && (
                  <span className="inline-flex px-2 ml-3 text-sm leading-5 text-indigo-900 bg-indigo-100 rounded-full">
                    <Button
                      onClick={() => {
                        markPaidTeachwork(cell.row.original.id, cell.row.index)
                      }}
                      size="default"
                      variant="outlined"
                    >
                      Mark as paid
                      <span className="sr-only">, </span>
                    </Button>
                  </span>
                )}
            </div>
          </>
        )
      },
    },
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const batch_student_columns = ({ onEditOpen }) => [
  {
    Header: 'Name',
    accessor: (d) =>
      d.contact && (
        <p>
          {d.contact.studentsFirstName} {d.contact.studentsLastName}
        </p>
      ),
  },
  {
    Header: 'Email',
    accessor: 'contact.studentsEmail',
  },
  {
    Header: 'Contact',
    accessor: 'contact.studentsMobileNo',
  },
  {
    Header: 'Scored',
    accessor: (d) =>
      (d.total_score == null && <p>-</p>) || <p>{d.total_score}</p>,
  },
  {
    Header: 'Out of',
    accessor: (d) =>
      (d.out_of_score == null && <p>-</p>) || <p>{d.out_of_score}</p>,
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          {cell.row.original.grade_card && (
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full cursor-pointer">
              <Button size="default" variant="outlined">
                <a
                  className="hover:text-green-600"
                  href={`${process.env.REACT_APP_STATIC_URL}${cell.row.original.grade_card}`}
                  target="_blank"
                  // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                >
                  Gradesheet
                </a>
                <span className="sr-only">, </span>
              </Button>
            </span>
          )}

          {cell.row.original.certificate_url && (
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-orange-600 bg-orange-100 rounded-full">
              <Button size="default" variant="outlined">
                <a
                  className="hover:text-orange-600"
                  href={`${process.env.REACT_APP_STATIC_URL}${cell.row.original.certificate_url}`}
                  target="_blank"
                  // onClick={() => goToExam(ex?.id, ex?.exam?.id, ex.hasAttempted)}
                >
                  Certificate
                </a>
                <span className="sr-only">, </span>
              </Button>
            </span>
          )}
        </>
      )
    },
  },
]

export const category_columns = ({ onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: 'category',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.category}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Type',
    accessor: 'categoryType',
  },
  // {
  //   id: 'is_active',
  //   Header: 'Is Active',
  //   accessor: (d) => d.is_active.toString(),
  // },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const payments_columns = ({ onEditOpen, onDeleteOpen, sendRecieptMail, onFamilyEditOpen, onEditInvoiceOpen, columns,onEditLocationOpen }) => {
  const allColumns = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: (cell) => {
        if (cell.row.original.id) {
          let numId = cell.row.original.id.toString()
          let locationName = cell.row.original?.location?.name
          let prefix
          while (numId.length < 6) {
            numId = '0' + numId
          }
          if (
            locationName == 'Ringwood (Head Office)' ||
            locationName == 'Ringwood' ||
            locationName == 'Online' ||
            locationName == 'Narre Warren' ||
            locationName == 'Officer'||
            locationName == 'Beaconsfield' ||
            locationName == 'Dandenong'
          ) {
            prefix = 'RWPAY-'
          } else {
            prefix = 'CNPAY-'
          }
          return (
            <div
              className="flex flex-wrap"
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
            >
              <span className="inline-flex px-2 text-md leading-5 m-1 text-base">
                {prefix}
                {numId}
              </span>
            </div>
          )
        } else {
          return '-'
        }
      },
    },
    {
      Header: 'Invoice Number',
      // accessor: (d) => d?.invoice?.xero_invoice_no,
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-300 cursor-pointer text-base"
                    onClick={() => {
                      onEditInvoiceOpen(cell.row.original.invoiceId)
                    }}
                  >
                    {cell.row.original.invoice.xero_invoice_no}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Customer Name',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-300 cursor-pointer text-base"
                    onClick={() => {
                      onFamilyEditOpen(cell.row.original.invoice.family.id)
                    }}
                  >
                    {cell.row.original.invoice.family ? `${cell.row.original.invoice.family.lastName}, ${cell.row.original.invoice.family.firstName}` : '-'}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Amount',
      accessor: (d) => '$' + d.amount,
    },
    {
      Header: 'Payment Method',
      accessor: (d) => d.paymentMethod,
    },
    {
      Header: 'Payment Date',
      accessor: (d) => (d.date ? moment(d.date).format('DD-MM-YYYY') : '-'),
    },
    {
      Header: 'Location',
      accessor: (d) => d.location.name,
      Cell: (cell) => {
        return (
          <div className="flex flex-wrap">
            <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer" onClick={()=>onEditLocationOpen(cell.row.original?.locationId)}>
              {cell.row.original?.location?.name}
            </span>
          </div>
        )
      },
    },
    {
      Header: 'State',
      accessor: (d) => (d.state ? d.state : '-'),
    },
    {
      Header: 'Description',
      accessor: (d) => (d.description ? d.description : '-'),
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Action',

      Cell: (cell) => {
        return (
          <>
            <span className="inline-flex px-2 text-sm leading-5 text-green-800 bg-green-100 rounded-full">
              <Button
                onClick={() => {
                  sendRecieptMail(cell.row.original.id, cell.row.index)
                }}
                className="text-green-800 "
                size="default"
                variant="outlined"
              >
                Send Receipt<span className="sr-only">, </span>
              </Button>
            </span>
          </>
        )
      },
    }
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const discount_description_columns = ({ onEditOpen, onDeleteOpen,onActiveCategory }) => [
  {
    Header: 'Description',
    accessor: 'description',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.description}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Status',
    id: 'archive',
    accessor: (d) =>
      d.archive == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          {cell.row.original.archive == true && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Inactive
              <span className="sr-only">, </span>
            </Button>
          </span>)}
          {cell.row.original.archive == false && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-500 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onActiveCategory(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Active
              <span className="sr-only">, </span>
            </Button>
          </span>)}
        </>
      )
    },
  },
]
export const tuition_columns = ({ onEditOpen, onDeleteOpen,onActiveCategory }) => [
  {
    Header: 'Tuition-Purpose',
    accessor: 'tuition',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.tuition}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Status',
    id: 'archive',
    accessor: (d) =>
      d.archive == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          {cell.row.original.archive == true && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Inactive
              <span className="sr-only">, </span>
            </Button>
          </span>)}
          {cell.row.original.archive == false && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-500 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onActiveCategory(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Active
              <span className="sr-only">, </span>
            </Button>
          </span>)}
        </>
      )
    },
  },
]

export const grade_master_columns = ({ onEditOpen, onDeleteOpen,onActiveCategory }) => [
  {
    Header: 'Name',
    accessor: 'grade',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.grade}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'VCE',
    accessor: (d) => (d.vce ? 'True' : 'False'),
  },
  {
    Header: 'Created At',
    id: 'createdAt',
    accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
  },
  // {
  //   id: 'is_active',
  //   Header: 'Is Active',
  //   accessor: (d) => d.is_active.toString(),
  // },
  {
    Header: 'Status',
    id: 'archive',
    accessor: (d) =>
      d.archive == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          {cell.row.original.archive == true && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Inactive
              <span className="sr-only">, </span>
            </Button>
          </span>)}
          {cell.row.original.archive == false && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-500 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onActiveCategory(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Active
              <span className="sr-only">, </span>
            </Button>
          </span>)}
        </>
      )
    },
  },
]

export const subject_master_columns = ({ onEditOpen, onDeleteOpen,onActiveCategory }) => [
  {
    Header: 'Name',
    accessor: 'subject',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.subject}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'VCE',
    accessor: (d) => (d.vce ? 'True' : 'False'),
  },
  {
    Header: 'Created At',
    id: 'createdAt',
    accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
  },
  // {
  //   id: 'is_active',
  //   Header: 'Is Active',
  //   accessor: (d) => d.is_active.toString(),
  // },
  {
    Header: 'Status',
    id: 'archive',
    accessor: (d) =>
      d.archive == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          {cell.row.original.archive == true && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Inactive
              <span className="sr-only">, </span>
            </Button>
          </span>)}
          {cell.row.original.archive == false && (
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-500 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onActiveCategory(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Active
              <span className="sr-only">, </span>
            </Button>
          </span>)}
        </>
      )
    },
  },
]

export const session_type__columns = ({ onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: 'sessionType',
  },
  {
    Header: 'Created At',
    id: 'createdAt',
    accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
  },
  // {
  //   id: 'is_active',
  //   Header: 'Is Active',
  //   accessor: (d) => d.is_active.toString(),
  // },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const login_session_columns = ({ onDeleteOpen }) => [
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Lasted Logged In',
    id: 'updatedAt',
    accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
  },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 ml-0 text-sm leading-5 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              <span className="text-red-600">Delete</span>
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const mail_log_columns = ({onEditModal,onResendMail,onViewInvoice,onInvoiceResend,onEditLocationOpen}) => [
  {
    Header: 'Recipent',
    accessor: 'sendTo',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              cell.row.original.email_type == 'Invoice Sent' && (
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onViewInvoice(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.sendTo}
                  <span className="sr-only">, </span>
                </p>
              </span>)}
              {cell.row.original.email_type != 'Invoice Sent' && (
                <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditModal(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.sendTo}
                  <span className="sr-only">, </span>
                </p>
                </span>
              )}
          </div>
        </>
      )
    },
  },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    Header: 'Email Type',
    accessor: 'email_type',
  },
  {
    Header: 'Location',
    // accessor: (d) => (d?.location?.name ? d?.location?.name : '-'),
    accessor: (d) => (
      <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer" onClick={()=>onEditLocationOpen(d.locationId)}>
        {d?.location?.name}
      </p>
    ),
  },
  {
    Header: 'Time',
    id: 'createdAt',
    accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>  {cell.row.original.email_type != 'Invoice Sent' && (
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditModal(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              View<span className="sr-only">, </span>
            </Button>
          </span>)}
          {cell.row.original.email_type == 'Invoice Sent' && (
            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <Button
                onClick={() => {
                  onViewInvoice(cell.row.original.id, cell.row.index);
                }}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                View<span className="sr-only">, </span>
              </Button>
              {/* <a href={invoiceLink} target="_blank" rel="noopener noreferrer">
                <Button
                  onClick={() => {
                    onViewInvoice(cell.row.original.id, cell.row.index);
                  }}
                  className="text-indigo-900"
                  size="default"
                  variant="outlined"
                >
                  View<span className="sr-only">, </span>
                </Button>
              </a> */}
            </span>
          )}
          {cell.row.original.email_type != 'Invoice Sent' && (
          <span className="inline-flex px-2 mx-3 text-sm leading-5 text-green-800 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onResendMail(cell.row.original.id, cell.row.index)
              }}
              className="text-green-800 "
              type="button"
              size="default"
              variant="outlined"
            >
              Resend email <span className="sr-only">, </span>
            </Button>
          </span>
          )}
          {cell.row.original.email_type == 'Invoice Sent' && (
          <span className="inline-flex px-2 mx-3 text-sm leading-5 text-green-800 bg-green-100 rounded-full">
            <Button
              onClick={() => {
                onInvoiceResend(cell.row.original.id, cell.row.index)
              }}
              className="text-green-800 "
              type="button"
              size="default"
              variant="outlined"
            >
              Resend email <span className="sr-only">, </span>
            </Button>
          </span>
          )}
        </>
      )
    },
  },
]

export const lead_column = ({ onEditModal, onDeleteOpen,columns,onEditLocationOpen }) => {
  const allColumns = [
    {
      Header: 'Mark As Read',
      accessor: 'markAsRead',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {cell.row.original.markAsRead == 1 && (
                <>
                  <span className="inline-flex leading-5">
                    <p
                      className="text-red-700 cursor-pointer font-semibold"
                      onClick={cell.row.original.status != 'Retired' && cell.row.original.status != 'Enrolled' ? () => {
                        onEditModal(cell.row.original.id, cell.row.index)
                      } : undefined}
                    >
                      <XMarkIcon className="w-5 h-5" />
                      <span className="sr-only">, </span>
                    </p>
                  </span>
                </>
              )}
              {cell.row.original.markAsRead == 0 && (
                <>
                  <span className="inline-flex leading-5">
                    <p
                      className="text-gray-500 cursor-pointer font-semibold"
                      onClick={()=>{onEditModal(cell.row.original.id, cell.row.index)}}
                    >
                      <CheckIcon className="w-5 h-5" />
                      <span className="sr-only">, </span>
                    </p>
                  </span>
                </>
              )}
            </div>
          </>
        )
      },
    },
    {
      Header: 'Parent First Name',
      accessor: (d) => d?.familyMaster?.firstName,
    },
    {
      Header: 'Parent Last Name',
      accessor: (d) => d?.familyMaster?.lastName,
    },
    {
      Header: 'Parent Email',
      accessor: (d) => d.familyMaster?.email,
    },
    {
      Header: 'Parent Mobile No.',
      accessor: (d) => d?.familyMaster?.mobileNo,
    },
    {
      Header: 'Type Of Class',
      accessor: (d) => d?.groupSizeOptionsValue,
    },
    {
      Header: 'Status',
      accessor: (d) => d?.status,
    },
    {
      Header: 'Preferred Communication',
      id: 'contactType',
      accessor: (d) => d?.contactType,
    },
    {
      Header: 'Address Line 1',
      id: 'address1',
      accessor: (d) =>
        d?.familyMaster?.address1 ? d?.familyMaster?.address1 : '-',
    },
    {
      Header: 'Address Line 2',
      id: 'address2',
      accessor: (d) =>
        d?.familyMaster?.address2 ? d?.familyMaster?.address2 : '-',
    },
    {
      Header: 'City',
      id: 'city',
      accessor: (d) => (d?.familyMaster?.city ? d?.familyMaster?.city : '-'),
    },
    {
      Header: 'Post Code',
      id: 'postCode',
      accessor: (d) =>
        d?.familyMaster?.postCode ? d?.familyMaster?.postCode : '-',
    },
    {
      Header: 'Grade',
      id: 'grade',
      accessor: (d) =>
        d?.studentMaster?.gradeMaster?.grade
          ? d?.studentMaster?.gradeMaster?.grade
          : '-',
    },
    {
      Header: 'Message',
      id: 'message',
      accessor: (d) => (d?.message ? d?.message : '-'),
    },
    {
      Header: 'Location',
      id: 'center',
      Cell: (cell) => {
        if (cell.row.original.center) {
          return (
            <div className="flex flex-wrap">
              <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer"
              onClick={()=>onEditLocationOpen(cell.row.original.center)}>
                {cell.row.original.location.name}
              </span>
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'Student First Name',
      id: 'studentFirstName',
      accessor: (d) =>
        d?.studentMaster?.studentsFirstName
          ? d?.studentMaster?.studentsFirstName
          : '-',
    },
    {
      Header: 'Student Last Name',
      id: 'studentLastName',
      accessor: (d) =>
        d?.studentMaster?.studentsLastName
          ? d.studentMaster?.studentsLastName
          : '-',
    },
    {
      Header: 'Source',
      id: 'source',
      accessor: (d) => (d?.source ? d.source : '-'),
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: (d) => (d?.study_subjects ? d.study_subjects : '-'),
    },
    {
      Header: 'Tuition Reason',
      id: 'reason',
      accessor: (d) => d?.help ? d.help : '-'
    },
    {
      Header: 'Source Detail',
      accessor: (d) => d?.whereDid ? d.whereDid : '-',
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Created By',
      accessor: (d) => d.createdBy,
    },
    {
      Header: 'Updated By',
      accessor: (d) => d.updatedBy,
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            {cell.row.original.status != 'Retired' &&
              cell.row.original.status != 'Enrolled' && (
                <>
                  <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
                    <Button
                      onClick={() => {
                        onEditModal(cell.row.original.id, cell.row.index)
                      }}
                      className="text-indigo-900 "
                      size="default"
                      variant="outlined"
                    >
                      Edit<span className="sr-only">, </span>
                    </Button>
                  </span>
                </>
              )}
            {(cell.row.original.familyMaster?.active == 'Prospective' &&
              (cell.row.original.studentMaster?.id != null && cell.row.original.studentMaster?.active == 'Prospective')) && (
                <>
                  <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                    <Button
                      onClick={() => {
                        onDeleteOpen(cell.row.original.id, cell.row.index)
                      }}
                      size="default"
                      variant="outlined"
                    >
                      Delete
                      <span className="sr-only">, </span>
                    </Button>
                  </span>
                </>
              )}
            {cell.row.original.familyMaster?.active == 'Prospective' && cell.row.original.studentMaster?.id == null && (
              <>
                <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                  <Button
                    onClick={() => {
                      onDeleteOpen(cell.row.original.id, cell.row.index)
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Delete
                    <span className="sr-only">, </span>
                  </Button>
                </span>
              </>
            )}
          </>
        )
      },
    },
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const user_column = ({
  onEditOpen,
  onDeleteOpen,
  onStatusInactiveOpen,
  onStatusActiveOpen,
  columns
}) => {
  const allColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-900 cursor-pointer text-base"
                    onClick={() => {
                      onEditOpen(cell.row.original.id, cell.row.index)
                    }}
                  >
                    {cell.row.original.name}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Role',
      Cell: (cell) => {
        return (
          <>
            <span className="">
              {cell.row.original.role.charAt(0).toUpperCase() +
                cell.row.original.role.slice(1)}
            </span>
          </>
        )
      },
    },
    {
      Header: 'Location',
      id: 'location',
      Cell: (cell) => {
        if (cell.row.original.location) {
          let tags = []
          tags = cell?.row?.original?.location.map((l) => l?.location?.name)
          return (
            <div className="flex flex-wrap w-64">
              {tags.map((value, index) => {
                return (
                  <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1">
                    {value}
                  </span>
                )
              })}
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'Last Logged In',
      id: 'last_logged_in',
      accessor: (d) =>
        d.last_logged_in
          ? moment(d.last_logged_in).format('DD MMM YYYY hh:mm A')
          : '-',
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (d) =>
        d.is_active == true ? (
          <>
            <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
              ACTIVE
            </span>
          </>
        ) : (
          <>
            <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
              INACTIVE
            </span>
          </>
        ),
    },
    {
      Header: 'MFA Status',
      accessor: (d) =>
        d.mfa == true ? (
          <>
            <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
              ACTIVE
            </span>
          </>
        ) : (
          <>
            <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
              INACTIVE
            </span>
          </>
        ),
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            {cell.row.original.is_active == true && (
              <span className="inline-flex px-2 mr-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                <Button
                  onClick={() => {
                    onStatusInactiveOpen(cell.row.original.id, cell.row.index)
                  }}
                  className="text-red-700 "
                  size="default"
                  variant="outlined"
                >
                  Inactive<span className="sr-only">, </span>
                </Button>
              </span>
            )}
            {cell.row.original.is_active == false && (
              <span className="inline-flex px-2 mr-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
                <Button
                  onClick={() => {
                    onStatusActiveOpen(cell.row.original.id, cell.row.index)
                  }}
                  className="text-green-500 "
                  size="default"
                  variant="outlined"
                >
                  Active<span className="sr-only">, </span>
                </Button>
              </span>
            )}

            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <Button
                onClick={() => {
                  onEditOpen(cell.row.original.id, cell.row.index)
                }}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </Button>
            </span>

            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onDeleteOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </Button>
            </span>
          </>
        )
      },
    },
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const reference_columns = ({ onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.name}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Action',

    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]
export const family_columns = ({
  onEditOpen,
  onInactiveOpen,
  onDeleteOpen,
  columns,
  onEditLocationOpen
}) => {
  const allColumns = [
    {
      Header: 'Title',
      accessor: (d) => (d.title ? d.title : '-'),
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-900 cursor-pointer text-base"
                    onClick={() => {
                      onEditOpen(cell.row.original.id, cell.row.index)
                    }}
                  >
                    {cell.row.original.firstName}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Last Name',
      accessor: (d) => d.lastName,
    },
    {
      Header: 'Email Address',
      accessor: 'email',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-800 cursor-pointer text-base"
                    onClick={() => {
                      onEditOpen(cell.row.original.id, cell.row.index)
                    }}
                  >
                    {cell.row.original.email}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Secondary Email',
      accessor: (d) => (d?.additionalEmail ? d?.additionalEmail : '-'),
    },
    {
      Header: 'Mobile Number',
      accessor: (d) => (d?.mobileNo ? d?.mobileNo : '-'),
    },
    {
      Header: 'Work Number',
      accessor: (d) => (d?.workNo ? d?.workNo : '-'),
    },
    {
      Header: 'Home Number',
      accessor: (d) => (d?.homeNo ? d?.homeNo : '-'),
    },
    {
      Header: 'Address Line 1',
      accessor: (d) => d?.address1,
    },
    {
      Header: 'Address Line 2',
      accessor: (d) => d?.address2,
    },
    {
      Header: 'City',
      accessor: (d) => d?.city,
    },
    {
      Header: 'State',
      accessor: (d) => d?.parentState,
    },
    {
      Header: 'Postcode',
      accessor: (d) => d?.postCode,
    },
    {
      Header: 'Additional Info',
      accessor: (d) => d?.additionalInfo,
    },
    // {
    //   Header: 'Location',
    //   accessor: (d) => d?.location?.name,
    // },
    {
      Header: 'Location',
      Cell: (cell) => {
        return (
          <>
            <div className="flex flex-wrap">
              <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer"
               onClick={() => {
                onEditLocationOpen(cell.row.original.center, cell.row.index)
              }}>
                  {cell.row.original.center ? `${cell.row.original.location?.name}` : '-'}
                  <span className="sr-only">, </span>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Source',
      accessor: (d) => (d.source ? d.source : '-'),
    },
    {
      Header: 'Status',
      accessor: (d) =>
        d.active == 'Active' ? (
          <p className="text-green-500 font-semibold">Active</p>
        ) : d.active == 'Inactive' || d.active == 'Retired' ? (
          <p className="text-red-500 font-semibold">{d.active}</p>
        ) : (
          <p className="text-black-500 font-semibold">Prospective</p>
        ),
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Created By',
      accessor: (d) => d.createdBy,
    },
    {
      Header: 'Updated By',
      accessor: (d) => d.updatedBy,
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <Button
                onClick={() => {
                  onEditOpen(cell.row.original.id, cell.row.index)
                }}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </Button>
            </span>
            {cell.row.original.active == 'Active' && (
              <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                <Button
                  onClick={() => {
                    onInactiveOpen(cell.row.original.id, cell.row.index)
                  }}
                  size="default"
                  variant="outlined"
                >
                  Mark as inactive
                  <span className="sr-only">, </span>
                </Button>
              </span>
            )}
            {cell.row.original.active != 'Active' && (
              <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                <Button
                  onClick={() => {
                    onDeleteOpen(cell.row.original.id, cell.row.index)
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                  <span className="sr-only">, </span>
                </Button>
              </span>
            )}
          </>
        )
      },
    },
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const credit_columns=({onEditOpen,onDeleteOpen,onFamilyEditOpen,onEditLocationOpen})=>[
  {
    Header: 'Credit Number',
    accessor: (d) => d?.creditId,
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.creditId}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Customer Name',
    // accessor: (d) =>
    //   d?.family
    //     ? d.family?.lastName + ' , ' + d.family?.firstName
    //     : '-',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onFamilyEditOpen(cell.row.original.familyId, cell.row.index)
                  }}
                >
                  {cell.row.original.family?.lastName} , {cell.row.original.family?.firstName}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Credit amount',
    accessor: (d) => '$' + d.amount,
  },
  {
    Header: 'Credit Note Reason',
    accessor: (d) => d.description? d.description : '-',
  },
  {
    Header: 'Credit Balance',
    accessor: (d) => d.balanceAmount? '$' + d.balanceAmount:'$0',
  },
  {
    Header: 'Status',
    accessor: (d) => d.status ? d.status:'-',
  },
  {
    Header: 'Created Date',
    accessor: (d) => (d.date ? moment(d.date).format('DD-MM-YYYY') : '-'),
  },
  {
    Header: 'Location',
    accessor: (d) => d.location.name,
    Cell: (cell) => {
        return (
          <div className="flex flex-wrap">
            <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer" onClick={()=>onEditLocationOpen(cell.row.original.locationId)}>
              {cell.row.original?.location?.name}
            </span>
          </div>
        )
    },
  },
  // {
  //   Header: 'Action',
  //   Cell: (cell) => {
  //     return (
  //       <>
  //         {cell.row.original.status != 'PAID'  &&(
  //           <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
  //             <Button
  //               onClick={() => {
  //                 onEditOpen(cell.row.original.id, cell.row.index)
  //               }}
  //               size="default"
  //               variant="outlined"
  //             >
  //               Allocate
  //               <span className="sr-only">, </span>
  //             </Button>
  //           </span>
  //         )}
  //          {cell.row.original.status != 'PAID' && cell.row.original.markAsRefund != true && (
  //           <span className="inline-flex px-2 ml-3 text-sm leading-5 text-yellow-600 bg-yellow-100 rounded-full">
  //             <Button
  //               onClick={() => {
  //                 onDeleteOpen(cell.row.original.id, cell.row.index)
  //               }}
  //               size="default"
  //               variant="outlined"
  //             >
  //                 Refund
  //               <span className="sr-only">, </span>
  //             </Button>
  //           </span>
  //         )}
  //         {/* {(
  //           <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
  //             <Button
  //               onClick={() => {
  //                 onDeleteOpen(cell.row.original.id, cell.row.index)
  //               }}
  //               size="default"
  //               variant="outlined"
  //             >
  //               Reverse
  //               <span className="sr-only">, </span>
  //             </Button>
  //           </span>
  //         )} */}
  //       </>
  //     )
  //   },
  // },
]

export const refund_columns=({onEditOpen})=>[
  {
    Header: 'Credit Number',
    accessor: (d) => d?.creditId,
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                  onClick={() => {
                    onEditOpen(cell.row.original.id, cell.row.index)
                  }}
                >
                  {cell.row.original.credit.creditId}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },
  {
    Header: 'Customer Name',
    accessor: (d) =>
      d?.credit.family
        ? d.credit.family?.lastName + ' , ' + d.credit.family?.firstName
        : '-',
  },
  {
    Header: 'Amount',
    accessor: (d) => '$' + d.amount,
  },
  {
    Header: 'Status',
    accessor: (d) => d.status ? d.status:'-',
  },
  {
    Header: 'Payment Method',
    accessor: (d) => d.paymentMethod ? d.paymentMethod:'-',
  },
  {
    Header: 'Location',
    accessor: (d) => d.locationId.name,
    Cell: (cell) => {
        return (
          <div className="flex flex-wrap">
            <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1">
              {cell.row.original?.locationId?.name}
            </span>
          </div>
        )
    },
  },
  {
    Header: 'Created Date',
    accessor: (d) => (d.date ? moment(d.date).format('DD-MM-YYYY') : '-'),
  },
  // {
  //   Header: 'Action',
  //   Cell: (cell) => {
  //     return (
  //       <>
  //         {cell.row.original.status != 'PAID' &&(
  //           <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
  //             <Button
  //               onClick={() => {
  //                 onEditOpen(cell.row.original.id, cell.row.index)
  //               }}
  //               size="default"
  //               variant="outlined"
  //             >
  //                 Mark as paid
  //               <span className="sr-only">, </span>
  //             </Button>
  //           </span>
  //         )}
  //       </>
  //     )
  //   },
  // },
]
export const teacher_columns = ({ onEditOpen, onDeleteOpen, onInactiveOpen, onActiveOpen, location, columns,onEditLocationOpen }) => {
  const allColumns = [
    {
      Header: 'First Name',
      accessor: 'firstName',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p
                    className="hover:text-indigo-900 cursor-pointer text-base"
                    onClick={() => {
                      onEditOpen(cell.row.original.id, cell.row.index)
                    }}
                  >
                    {cell.row.original.firstName}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Email Address',
      accessor: 'email',
    },
    {
      Header: 'Mobile Number',
      accessor: 'mobileNo',
    },

    {
      Header: 'WWC Number',
      accessor: (d) => (d.wwcNo ? d.wwcNo : '-'),
    },
    {
      Header: 'VIT Number',
      accessor: (d) => (d.vitNumber ? d.vitNumber : '-'),
    },

    {
      Header: 'Qualification',
      accessor: 'qualification',
    },

    {
      Header: 'Subject',
      Cell: (cell) => {
        if (cell.row.original.subject) {
          let tags = cell.row.original.subject.map((subjectInfo => subjectInfo.subject.subject));
          return (
            <div className="flex flex-wrap w-64">
              {tags.map((value, index) => {
                return (
                  <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1">
                    {value}
                  </span>
                )
              })}
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'Rate Per Hour',
      accessor: (d) => (d.ratePerHour ? '$' + d.ratePerHour + '.00' : '-'),
    },
    {
      Header: 'Location',
      id: 'location',
      accessor: (d) => d.locationId,
      Cell: (cell) => {
        if (cell.row.original.locationId) {
          let tags = cell.row.original.locationId.map((l) => {
            const locationdata = location.find((e) => e.id === l);
            return locationdata ? locationdata : '';
          });
          return (
            <div className="flex flex-wrap w-64">
              {tags.map((value, index) => {
                return (
                  <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer" onClick={()=>onEditLocationOpen(value.id)}>
                    {value.name}
                  </span>
                )
              })}
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'BSB Number',
      accessor: (d) => (d.BSB ? d.BSB : '-'),
    },
    {
      Header: 'Account Number',
      accessor: (d) => (d.accountNo ? d.accountNo : '-'),
    },
    {
      Header: 'ABN Number',
      accessor: (d) => (d.abn ? d.abn : '-'),
    },
    {
      Header: 'TFN Number',
      accessor: (d) => (d.tfn ? d.tfn : '-'),
    },
    {
      Header: 'Super Number',
      accessor: (d) => (d.super ? d.super : '-'),
    },
    {
      Header: 'Membership Number',
      accessor: (d) => (d.membershipNumber ? d.membershipNumber : '-'),
    },
    {
      Header: 'Company ABN Number',
      accessor: (d) => (d.abnorusi ? d.abnorusi : '-'),
    },
    {
      Header: 'USI Number',
      accessor: (d) => (d.usi ? d.usi : '-'),
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (d) =>
        d.active == true ? (
          <>
            <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
              ACTIVE
            </span>
          </>
        ) : (
          <>
            <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
              INACTIVE
            </span>
          </>
        ),
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <Button
                onClick={() => {
                  onEditOpen(cell.row.original.id, cell.row.index)
                }}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </Button>
            </span>
            {cell.row.original.active == true && (
              <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
                <Button
                  onClick={() => {
                    onInactiveOpen(cell.row.original.id, cell.row.index)
                  }}
                  size="default"
                  variant="outlined"
                >
                  Inactive
                  <span className="sr-only">, </span>
                </Button>
              </span>
            )}
            {cell.row.original.active == false && (
              <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
                <Button
                  onClick={() => {
                    onActiveOpen(cell.row.original.id, cell.row.index)
                  }}
                  size="default"
                  variant="outlined"
                >
                  Active
                  <span className="sr-only">, </span>
                </Button>
              </span>
            )}
            {cell.row.original.active == false && (
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onDeleteOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </Button>
            </span>
            )}
          </>
        )
      },
    },
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const class_column = ({ onEditOpen, onDeleteOpen, onTeacherOpen, onEditLocationOpen, onInactiveOpen, columns }) => {
  const allColumns = [
    {
      Header: 'Class name',
      accessor: 'firstName',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <a href={`${cell.row.original.urlSchedule}`} target="_blank">
                    <DocumentArrowDownIcon
                      className="hover:h-7 hover:w-7 duration-500 h-6 w-6 text-violet-400 hover:text-white hover:bg-indigo-800 rounded-full hover:px-1 hover:py-1 mr-4"
                      aria-hidden="true"
                    />
                  </a>
                  <p
                    className="hover:text-indigo-900 cursor-pointer text-base"
                    onClick={() => {
                      onEditOpen(cell.row.original.id, cell.row.index)
                    }}
                  >
                    {cell.row.original.className}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Service',
      Cell: (cell) => {
        if (cell.row.original.serviceId) {
          return (
            <div className="flex flex-wrap">
              <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1">
                {cell.row.original.service.name}
              </span>
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'Class mode',
      accessor: (d) => d.classMode ? d.classMode : '-',
    },
    {
      Header: 'Subject',
      accessor: (d) => d.subjectId ? d.subject.subject : '-',
    },
    {
      Header: 'Teacher',
      Cell: (cell) => {
        if (cell.row.original.teacherId) {
          return (
            <div className="flex flex-wrap">
              <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 hover:text-indigo-900 cursor-pointer"
                onClick={() => onTeacherOpen(cell.row.original.teacher.id)}>
                {cell.row.original.teacher.firstName + ' ' + cell.row.original.teacher.lastName}
              </span>
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: `Teacher Hourly Rate`,
      accessor: (d) => '$' + d.teacherHourlyRate
    },
    {
      Header: `Duration`,
      accessor: (d) => d.duration + ' min'
    },
    {
      Header: 'Status',
      accessor: (d) =>
        d.status == true ? (
          <p className="text-green-500 font-semibold">Active</p>
        ) : (
          <p className="text-red-500 font-semibold">Inactive</p>
        ),
    },
    {
      Header: 'Location',
      Cell: (cell) => {
        if (cell.row.original.locationId) {
          return (
            <div className="flex flex-wrap">
              <span className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer"
              onClick={()=>onEditLocationOpen(cell.row.original.locationId)}>
                {cell.row.original.location.name}
              </span>
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'Start Date',
      accessor: (d) => moment(d.startDate).format('DD-MM-YYYY'),
    },
    {
      Header: 'Term',
      accessor: (d) => d.termData.name ? d.termData.name : '-',
    },
    {
      Header: 'Number of Sessions',
      accessor: (d) => d.frequency,
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Created By',
      accessor: (d) => d.createdBy,
    },
    {
      Header: 'Updated By',
      accessor: (d) => d.updatedBy,
    },
    {
      Header: 'Action',
      Cell: (cell) => {
        return (
          <>
            <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <Button
                onClick={() => {
                  onEditOpen(cell.row.original.id, cell.row.index)
                }}
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </Button>
            </span>

            {/* {cell.row.original.students.length==0 &&  
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        } */}
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onInactiveOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Mark as Inactive
                <span className="sr-only">, </span>
              </Button>
            </span>
          </>
        )
      },
    },
  ]
  const cols = columns.map((column) => column.Header)
  const filteredColumns = allColumns.filter((column) =>
    cols.includes(column.Header) || column.Header === 'Action')
  return filteredColumns;
}

export const teacherInvoic_column = ({ onEditOpen, onDeleteOpen, userRole,onTeacherOpen,onEditLocationOpen }) => {
  const columns = [
{
        Header: 'Invoice Number',
        Cell: (cell) => (
          <div className="flex items-center">
            <span className="inline-flex leading-5">
              <p
                className="hover:text-indigo-900 cursor-pointer text-base"
                onClick={() => {
                  onEditOpen(cell.row.original.id, cell.row.index);
                }}
              >
                {cell.row.original.invoiceNumber}
                <span className="sr-only">, </span>
              </p>
            </span>
          </div>
        ),
      },
      userRole != 'teacher'?
      {
        Header: 'Name',
        accessor: (d) => (
          <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer"
            onClick={()=>onTeacherOpen(d.teacherId)}
          >
            { d.teacher.firstName +' '+  d.teacher.lastName}
          </p>
        ),
      } : null,  
    {
      Header: 'Amount',
      accessor: (d) => (d.amount ? `$ ${d.amount}` : '-'),
    },
    {
      Header: 'Balance',
      accessor: (d) => (d.balance ? `$ ${d.balance}` : 0),
    },
    {
      Header: 'Status',
      accessor: (d) => (d.status ? d.status : '-'),
    },
    userRole != 'teacher'?
    {
      Header: 'Location',
      accessor: (d) => (
        <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer" onClick={()=>onEditLocationOpen(d.locationId)}>
          {d.location?.name}
        </p>
      ),
    }:{
      Header: 'Location',
      accessor: (d) => (
        <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1">
          {d.location?.name}
        </p>
      ),
    },
    {
      Header: 'Rejection Reason',
      accessor: (d) => (d.rejectionReason ? d.rejectionReason : '-'),
    },
    userRole !== 'teacher'
      ? {
        Header: 'Action',
        Cell: (cell) => {
          return (<>
            {cell.row.original.status === 'Invoice Issue' || cell.row.original.status != 'Paid' && (
                <span className="inline-flex px-2 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
                  <Button
                    onClick={() => {
                      onDeleteOpen(cell.row.original.id, cell.row.index);
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Mark as paid
                    <span className="sr-only">, </span>
                  </Button>
                </span>
            )}
          </>)
        },
      }
      : {
        Header: 'Action',
        Cell: (cell) => (
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index);
              }}
              size="default"
              variant="outlined"
            >
              View
              <span className="sr-only">, </span>
            </Button>
          </span>
        ),
      },
  ]
  return columns.filter((column) => column !== null)
}

export const teacherPayment_column = ({ onEditOpen, onDeleteOpen, downloadInvoice, userRole,onTeacherOpen,onEditLocationOpen }) => {
  const columns = [ {
        Header: 'Invoice Number',
        Cell: (cell) => (
          <div className="flex items-center">
            <span className="inline-flex leading-5">
            {userRole === 'teacher'  &&
            <DocumentArrowDownIcon
                    className="hover:h-7 hover:w-7 duration-500 h-6 w-6 cursor-pointer text-violet-400 hover:text-white hover:bg-indigo-800 rounded-full hover:px-1 hover:py-1 mr-4"
                    aria-hidden="true"
                    onClick={()=>{downloadInvoice(cell.row.original.id)}}
                  />
                  }
              <p
                className="hover:text-indigo-900 cursor-pointer text-base"
                onClick={() => {
                  onEditOpen(cell.row.original.id, cell.row.index);
                }}
              >
                {cell.row.original.invoiceNumber}
                <span className="sr-only">, </span>
              </p>
            </span>
          </div>
        ),
      } ,
      userRole != 'teacher'?
      {
        Header: 'Name',
        accessor: (d) => (
          <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer"
            onClick={()=>onTeacherOpen(d.teacherId)}
          >
            { d.teacher.firstName +' '+  d.teacher.lastName}
          </p>
        ),
      } : null,
    {
      Header: 'Amount',
      accessor: (d) => (d.amount ? `$ ${d.amount}` : '-'),
    },
    {
      Header: 'Status',
      accessor: (d) => (d.status ? d.status : '-'),
    },
    {
      Header: 'Payment Date',
      accessor: (d) => (d.updatedAt ? moment(d.updatedAt).format('DD-MM-YYYY') : '-')
    },
    userRole != 'teacher'?
    {
      Header: 'Location',
      accessor: (d) => (
        <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer" onClick={()=>onEditLocationOpen(d.locationId)}>
          {d.location?.name}
        </p>
      ),
    }:{
      Header: 'Location',
      accessor: (d) => (
        <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1">
          {d.location?.name}
        </p>
      ),
    },
  ]
  return columns.filter((column) => column !== null)
}

export const TeacherSchedule_column = ({onEditOpen,onTeacherOpen,onEditLocationOpen}) =>[
  {
    Header: 'Class Name',
    accessor: (d) => (d.classId ? ` ${d.class.className}` : '-'),
  },
  {
    Header: 'Teacher Name',
    accessor: (d) => (
      <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer"
        onClick={()=>onTeacherOpen(d.teacherId)}
      >
        { d.teacher.firstName +' '+  d.teacher.lastName}
      </p>
    ),
  },
  {
    Header: 'Grade',
    accessor: (d) => (d.classId ?d.class.grade.grade: '-')
  },
  {
    Header: 'Location',
    id: 'locationId',
    accessor: (d) => (
      <p className="inline-flex px-2 text-sm leading-5 rounded-full bg-blue-100 shadow m-1 cursor-pointer" onClick={()=>onEditLocationOpen(d.class.locationId)}>
        {d.class.location.name}
      </p>
    ),
  },
  {
    Header: 'Date',
    accessor: (d) => (d.date ? moment(d.date).format('DD/MM/YYYY') : '-'),
  },
  {
    Header: 'From Time',
    accessor: (d) => (d.date ? moment(d.start_time).format('h:mm A') : '-'),
  },
  {
    Header: 'To Time',
    accessor: (d) => (d.date ? moment(d.end_time).format('h:mm A') : '-'),
  },
]

export const parentInvoice = ({ onEditOpen }) => [
  {
    Header: 'Invoice Number',
    accessor: 'xero_invoice_no',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            <span className="inline-flex leading-5">
              <a href={`${cell.row.original.xero_invoice_url}`} target="_blank">
                <DocumentArrowDownIcon
                  className="hover:h-7 hover:w-7 duration-500 h-6 w-6 text-violet-400 hover:text-white hover:bg-indigo-800 rounded-full hover:px-1 hover:py-1 mr-4"
                  aria-hidden="true"
                />
              </a>
              <a href={`${cell.row.original.xero_invoice_url}`} target="_blank">
                <p
                  className="hover:text-indigo-800 cursor-pointer text-base"
                >
                  {cell.row.original.xero_invoice_no}
                  <span className="sr-only">, </span>
                </p>
              </a>
            </span>
          </div>
        </>
      )
    },
  },
  {
    Header: 'Date',
    accessor: (d) => moment(d.xero_invoice_date).format('DD-MM-YYYY'),
  },
  {
    Header: 'Due Date',
    accessor: (d) => moment(d.xero_invoice_due_date).format('DD-MM-YYYY'),
  },
  {
    Header: 'Status',
    accessor: (d) => (d.xero_invoice_status ? d.xero_invoice_status : '-'),
  },
  {
    Header: 'Total Amount',
    accessor: (d) => (d.amountPayable ? '$' + d.amountPayable : '-'),
  },
  {
    Header: 'Balance',
    accessor: (d) => d.xero_invoice_balance ? '$' + d.xero_invoice_balance : '-',
  },
  {
    Header: 'Location',
    accessor: (d) => (d.location?.name ? d.location?.name : '-'),
  },
  {
    Header: 'Action',
    Cell: (cell) => (
      <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
        <a href={`${cell.row.original.xero_invoice_url}`} target="_blank">
          <p
            className="hover:text-indigo-800 text-indigo-700 cursor-pointer text-base"

          >
            View
            <span className="sr-only">, </span>
          </p>
        </a>
      </span>
    ),
  },
]

export const naplan_columns = ({ onDeleteOpen,serviceOpen }) => [
  {
    Header: 'File Name',
    accessor: 'fileName',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
            {
              <span className="inline-flex leading-5">
                <a href={`${process.env.REACT_APP_STATIC_URL}${cell.row.original.path}`} target="_blank">
                  <DocumentArrowDownIcon
                    className="hover:h-7 hover:w-7 duration-500 h-6 w-6 text-violet-400 hover:text-white hover:bg-indigo-800 rounded-full hover:px-1 hover:py-1 mr-4"
                    aria-hidden="true"
                  />
                </a>
                <p
                  className="hover:text-indigo-900 cursor-pointer text-base"
                >
                  {cell.row.original.fileName}
                  <span className="sr-only">, </span>
                </p>
              </span>
            }
          </div>
        </>
      )
    },
  },  
  {
    Header: 'Service',
    accessor: (d) => (d.service ?d.service[0]?.service.name: '-'),
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center">
              <span className="inline-flex leading-5">
                <p className="hover:text-indigo-800 cursor-pointer text-base"
                onClick={()=>{serviceOpen(cell.row.original.service[0].serviceId)}}>
                  {cell.row.original.service?cell.row.original.service[0]?.service.name:null}
                  <span className="sr-only">, </span>
                </p>
              </span>
          </div>
        </>
      )
    },
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]


export const report_column = ({ column, types }) => {
  const invoiceColumns = [
    {
      Header: 'Invoice Number',
      accessor: 'xero_invoice_no',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              <span className="inline-flex leading-5">
                <a href={`${cell.row.original.xero_invoice_url}`} target="_blank">
                  <DocumentArrowDownIcon
                    className="hover:h-7 hover:w-7 duration-500 h-6 w-6 text-violet-400 hover:text-white hover:bg-indigo-800 rounded-full hover:px-1 hover:py-1 mr-4"
                    aria-hidden="true"
                  />
                </a>
                <p className="text-base">
                  {cell.row.original.xero_invoice_no}
                  <span className="sr-only">, </span>
                </p>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Customer Name',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              <span className="inline-flex leading-5">
                <p className="text-base" >
                  {cell.row.original.customerId ? `${cell.row.original.family.lastName}, ${cell.row.original.family.firstName}` : '-'}
                  <span className="sr-only">, </span>
                </p>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Date',
      accessor: (d) => moment(d.xero_invoice_date).format('DD-MM-YYYY'),
    },
    {
      Header: 'Due Date',
      accessor: (d) => moment(d.xero_invoice_due_date).format('DD-MM-YYYY'),
    },
    {
      Header: 'Status',
      accessor: (d) => (d.xero_invoice_status ? d.xero_invoice_status : '-'),
    },
    {
      Header: 'Total Amount',
      accessor: (d) => (d.amountPayable ? '$' + d.amountPayable : '-'),
    },
    {
      Header: 'Balance',
      accessor: (d) =>
        d.xero_invoice_balance ? '$' + d.xero_invoice_balance : '-',
    },
    {
      Header: 'Discount',
      accessor: (d) => (d.discount ? '$' + d.discount : '-'),
    },
    {
      Header: 'Location',
      // accessor: (d) => (d.location?.name ? d.location?.name : '-'),
      Cell: (cell) => {
        return (
          <>
            <div className="flex flex-wrap">
              <span className="text-base">
                {cell.row.original.locationId ? `${cell.row.original.location?.name}` : '-'}
                <span className="sr-only">, </span>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Void Reason',
      accessor: (d) => (d.voidReason ? d.voidReason : '-'),
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
  ]

  const paymentColumns = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: (cell) => {
        if (cell.row.original.id) {
          let numId = cell.row.original.id.toString()
          let locationName = cell.row.original?.location?.name
          let prefix
          while (numId.length < 6) {
            numId = '0' + numId
          }
          if (
            locationName == 'Ringwood (Head Office)' ||
            locationName == 'Ringwood' ||
            locationName == 'Online' ||
            locationName == 'Narre Warren' ||
            locationName == 'Officer' ||
            locationName == 'Beaconsfield' ||
            locationName == 'Dandenong'
          ) {
            prefix = 'RWPAY-'
          } else {
            prefix = 'CNPAY-'
          }
          return (
            <div className="flex flex-wrap">
              <span className="inline-flex px-2 text-md leading-5 m-1 text-base">
                {prefix}
                {numId}
              </span>
            </div>
          )
        } else {
          return '-'
        }
      },
    },
    {
      Header: 'Invoice Number',
      // accessor: (d) => d?.invoice?.xero_invoice_no,
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p className="text-base">
                    {cell.row.original.invoice.xero_invoice_no}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Customer Name',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p className="text-base">
                    {cell.row.original.invoice.family ? `${cell.row.original.invoice.family.lastName}, ${cell.row.original.invoice.family.firstName}` : '-'}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Amount',
      accessor: (d) => '$' + d.amount,
    },
    {
      Header: 'Payment Method',
      accessor: (d) => d.paymentMethod,
    },
    {
      Header: 'Payment Date',
      accessor: (d) => (d.date ? moment(d.date).format('DD-MM-YYYY') : '-'),
    },
    {
      Header: 'Location',
      accessor: (d) => d.location.name,
    },
    {
      Header: 'Description',
      accessor: (d) => (d.description ? d.description : '-'),
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
  ]

  const familyColumn = [
    {
      Header: 'Title',
      accessor: (d) => (d.title ? d.title : '-'),
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p className="text-base" >
                    {cell.row.original.firstName}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Last Name',
      accessor: (d) => d.lastName,
    },
    {
      Header: 'Email Address',
      accessor: 'email',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p className="text-base">
                    {cell.row.original.email}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Mobile Number',
      accessor: (d) => (d?.mobileNo ? d?.mobileNo : '-'),
    },
    {
      Header: 'Address Line 1',
      accessor: (d) => d?.address1,
    },
    {
      Header: 'Address Line 2',
      accessor: (d) => d?.address2,
    },
    {
      Header: 'City',
      accessor: (d) => d?.city,
    },
    {
      Header: 'State',
      accessor: (d) => d?.parentState,
    },
    {
      Header: 'Postcode',
      accessor: (d) => d?.postCode,
    },
    {
      Header: 'Location',
      Cell: (cell) => {
        return (
          <>
            <div className="flex flex-wrap">
              <span className="text-base">
                {cell.row.original.center ? `${cell.row.original.location?.name}` : '-'}
                <span className="sr-only">, </span>
              </span>
            </div>
          </>
        )
      },
    },
    {
      Header: 'Source',
      accessor: (d) => (d.source ? d.source : '-'),
    },
    {
      Header: 'Status',
      accessor: (d) =>
        d.active == 'Active' ? (
          <p className="text-green-500 font-semibold">Active</p>
        ) : d.active == 'Inactive' || d.active == 'Retired' ? (
          <p className="text-red-500 font-semibold">{d.active}</p>
        ) : (
          <p className="text-black-500 font-semibold">Prospective</p>
        ),
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
  ]

  const studentColumn = [
    {
      Header: 'Student First Name',
      accessor: 'studentsFirstName',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p className="text-base">
                    {cell.row.original.student.studentsFirstName}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Student Last Name',
      accessor: (d) => (d.student.studentsLastName ? d.student.studentsLastName : '-'),
    },
    {
      Header: 'Student Email',
      accessor: (d) => (d.student.studentsEmail ? d.student.studentsEmail : '-'),
    },
    {
      Header: 'Student Mobile No',
      accessor: (d) => (d.student.studentPhone ? d.student.studentPhone : '-'),
    },
    {
      Header: 'Grade',
      accessor: (d) => d.student.gradeMaster?.grade,
    },
    {
      Header: 'Service Name',
      accessor: (d) => d.crmServiceMaster.name
    },
    {
      Header: 'Location',
      Cell: (cell) => {
        if (cell.row.original.student.locations) {
          return (
            <div className="flex flex-wrap w-64">
              {cell?.row?.original?.student?.locations.map((l) => {
                return (
                  <span className="text-base">
                    {l.location.name}
                  </span>
                )
              })}
            </div>
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'Parent Name',
      accessor: "parentlastname",
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p className="text-base" >
                    {cell.row.original.student.familyMaster ? `${cell.row.original.student.familyMaster.lastName}, ${cell.row.original.student.familyMaster.firstName}` : cell.row.original.student.parentLastName ? `${cell.row.original.student.parentLastName}, ${cell.row.original.student.parentFirstName}` : '-'}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Parent Email',
      Cell: (cell) => {
        return (
          <>
            <div className="flex items-center">
              {
                <span className="inline-flex leading-5">
                  <p className="text-base">
                    {cell.row.original.student.familyMaster ? `${cell.row.original.student.familyMaster.email}` : cell.row.original.student.parentLastName ? `${cell.row.original.student.parentEmail}` : '-'}
                    <span className="sr-only">, </span>
                  </p>
                </span>
              }
            </div>
          </>
        )
      },
    },
    {
      Header: 'Created At',
      id: 'createdAt',
      accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
    },
    {
      Header: 'Updated At',
      id: 'updatedAt',
      accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
    },
  ]

  const cols = column.map((c) => c.Header)
  let filteredColumns
  if (types == 'student') {
    const callColumn = studentColumn
    filteredColumns = callColumn.filter((c) =>
      cols.includes(c.Header) || c.Header == 'Service Name')
  } else {
    const callColumn = types == 'invoice' ? invoiceColumns : types == 'family' ? familyColumn : paymentColumns
    filteredColumns = callColumn.filter((c) =>
      cols.includes(c.Header))
  }
  return filteredColumns;
}


export const examTableColumn = ({ onEditOpen, setEnabled, onDeleteOpen, onNavigation, onDuplicate }) => [
  {
    Header: 'Name',
    Cell: (cell) => {
      return (
        <>
          <div className="flex items-center gap-2 cursor-pointer group" onClick={(e)=> {e.stopPropagation();onNavigation(cell.row.original.id)}}>
            <ClipboardDocumentListIcon className="size-7 group-hover:text-indigo-900 max-w-7 min-w-7 min-h-7 max-h-7" aria-hidden="true"/>
            <div className='flex flex-col gap-1'>
                <span className='block group-hover:text-indigo-900'>{cell.row.original.name}</span>
                <span className='block text-sm text-gray-500 group-hover:text-gray-600'>{cell.row.original.questions.length || 0} Questions</span>
            </div>
          </div>
        </>
      )
    }
  },
  {
    Header: 'Duration',
    accessor: (d) => `${d.duration} Minutes` ,
  },
  {
    Header: 'exprire in day',
    accessor: (d) => d.expireInDays,
  },
  // {
  //   Header: 'Active',
  //   accessor: (d) => (d.is_active ? "Online" : '-'),
  // },
  {
    Header: 'Status',
    // accessor: (d) => (d.status ? d.status : '-'),
    Cell: (cell) => {
      return (
        <>
            {
              cell.row.original.status == "active" ? <>
                <div className="flex justify-center bg-green-100 rounded-full items-center gap-2 p-2">
                    <div className='w-2 h-2 bg-green-600 rounded-full'></div>
                    <div>
                        <span className='text-green-600'>Active</span>
                    </div>
                </div>
              </> 
              : <div className="flex justify-center bg-red-100 rounded-full items-center gap-2 p-2">
                    <div className='w-2 h-2 bg-red-600 rounded-full'></div>
                    <div>
                        <span className='text-red-600'>InActive</span>
                    </div>
                </div>
            }
        </>
      )
    },
  },
  // {
  //   Header: 'Created At',
  //   id: 'createdAt',
  //   accessor: (d) => moment(d?.createdAt).format('DD MMM YYYY hh:mm A'),
  // },
  {
    Header: 'Last Edited',
    id: 'updatedAt',
    accessor: (d) => moment(d?.updatedAt).format('DD MMM YYYY hh:mm A'),
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return(
       <div className='flex justify-center items-center gap-2'>
        <span className="inline-flex px-2 text-sm leading-5 bg-yellow-100 rounded-full">
          <p
            className="hover:text-yellow-800 text-yellow-700 cursor-pointer text-base"
            onClick={(e)=>{e.stopPropagation(); onDuplicate(cell.row.original.id, cell.row.index); setEnabled(cell.row.original.previewMode)}}
          >
            Duplicate
            <span className="sr-only">, </span>
          </p>
      </span>
      <span className="inline-flex px-2 text-sm leading-5 bg-indigo-100 rounded-full">
          <p
            className="hover:text-indigo-800 text-indigo-700 cursor-pointer text-base"
            onClick={(e)=>{e.stopPropagation();onNavigation(cell.row.original.id)}}
          >
            View
            <span className="sr-only">, </span>
          </p>
      </span>
      { JSON.parse(localStorage.getItem('moldKey')).role !== 'exam_view_admin' ?
        (<>
              <span className="inline-flex px-2 text-sm leading-5 bg-green-100 rounded-full">
              <p
                className="hover:text-green-800 text-green-700 cursor-pointer text-base"
                onClick={(e)=>{e.stopPropagation();onEditOpen(cell.row.original.id, cell.row.index);setEnabled(cell.row.original.previewMode)}}
              >
                Edit
                <span className="sr-only">, </span>
              </p>
          </span>
          <span className="inline-flex px-2 text-sm leading-5 bg-red-100 rounded-full">
              <p
                className="hover:text-red-800 text-red-700 cursor-pointer text-base"
                onClick={(e)=>{e.stopPropagation();onDeleteOpen(cell.row.original.id, cell.row.index)}}
              >
                Delete
                <span className="sr-only">, </span>
              </p>
          </span>
        </>) : <></>
      }      
      </div>
    )},
  },
]


export const StudentLoginSessionColumn =({
  handleOpenModal,  
}) => [
  {
    Header: 'username',
    accessor: (d) => `${d.username}` ,
  },
  {
    Header: 'Login Attemp Time',
    id: 'createdAt',
    accessor: (d) => `${moment(d.createdAt).format('DD MMM YYYY hh:mm A')}` ,
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>         
          {cell.row.original.isApproved == false && (
            <span className="inline-flex px-2 mr-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
              <Button
                onClick={() => {
                  handleOpenModal({...cell.row.original, index : cell.row.index})
                }}
                className="text-green-500 "
                size="default"
                variant="outlined"
              >
                Approve<span className="sr-only">, </span>
              </Button>
            </span>
          )}          
        </>
      )
    },
  },
]