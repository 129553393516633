import { useState, useEffect, useCallback } from 'react'
import app_api from '../../config/api'
import Table from '../../components/tables/table'
import {
  StudentLoginSessionColumn,
  user_column,
} from '../../components/tables/tableheader'
import { Sidebar } from '../../components/navigation/sidebar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const StudentLoginSession = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('moldKey'))
  )

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const [studentLoginReqData, setStudentLoginReqData] = useState([])

  const getStudentLoginData = async () => {
    setLoading(true)
    const moldKey = JSON.parse(localStorage.getItem('moldKey'))
    const { role, id: user_id, locationId: location_id } = moldKey || {}
    const payload = { role }

    if (role === 'manager') {
      payload.user_id = user_id
      payload.location_id = location_id
    }

    await app_api
      .post('login-session/getStudentLoginReqData', payload)
      .then((res) => {
        setLoading(false)
        setStudentLoginReqData(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setError(
          err?.response?.data?.message || 'Error fetching Student login data'
        )
      })
  }

  useEffect(() => {
    getStudentLoginData(); // Initial fetch
  
    // Function to fetch data periodically
    const fetchInterval = setInterval(() => {
      getStudentLoginData();
    }, 5000); // Fetch data every 5 seconds
  
    // Cleanup interval on component unmount
    return () => clearInterval(fetchInterval);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(null)

  const handleOpenModal = (student) => {
    console.log(student)
    setSelectedStudent(student)
    setIsModalOpen(true)
  }

  const handleConfirm = async () => {
    if (selectedStudent) {
      await updateStudentLoginData(selectedStudent)
    }
    setIsModalOpen(false)
  }

  const updateStudentLoginData = async (data) => {
    console.log(data)
    setLoading(true)
    const body = {
      id: data.id,
      action: !data.isApproved,
    }

    console.log(body)

    try {
      await app_api.post('login-session/studentLoginReqAction', body)
      setLoading(false)
      getStudentLoginData()
    } catch (err) {
      getStudentLoginData()
      setLoading(false)
      setError(
        err?.response?.data?.message || 'Error fetching Student login data'
      )
    }
  }

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={page}
          totalCount={count}
          columns={StudentLoginSessionColumn({ handleOpenModal })}
          data={studentLoginReqData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [studentLoginReqData])

  return (
    <Sidebar>
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">
              Student Login Request
            </h1>
          </div>
        </div>
        {/* {loading ? (
          <div className="flex items-center justify-center">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <> */}
            {renderTable()}
            <nav
              className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value)
                      setPage(0)
                    }}
                    value={limit}
                  >
                    <option value={count}>{count}</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>{' '}
                  of <span className="font-medium">{count}</span> results
                </p>
              </div>
              <div className="flex items-center md:justify-end">
                <span
                  onClick={goPrev}
                  className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Previous
                </span>
                <span className="text-sm">
                  Page{' '}
                  <input
                    className="min-w-0 flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm"
                    type="number"
                    value={page + 1}
                    onChange={(e) => setPage(e.target.value - 1)}
                    max={totalPages}
                    min="1"
                  />
                  <span className="ml-2"></span>/ {Math.ceil(totalPages)}
                </span>
                <span
                  onClick={goNext}
                  className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Next
                </span>
              </div>
            </nav>
          {/* </>
        )} */}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />

      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setIsModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="flex flex-col items-center">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Confirm Action
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to approve the login request for <strong>{selectedStudent?.name}</strong>?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={handleConfirm}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Sidebar>
  )
}

export default StudentLoginSession
