import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Sidebar } from '../../components/navigation/sidebar'
import Button from '../../components/button'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DataCard from '../../components/DataCard'
import app_api from '../../config/api'
import { CiFilter } from 'react-icons/ci'
import moment from 'moment'

export default function Dashboard() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [locationFilter, setLocationFilter] = useState([])
  const [location, setLocation] = useState([])
  const [locationData, setLocationData] = useState([])
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [dashboardData, setDashboardData] = useState(null)
  const sidebarRef = useRef(null)
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('moldKey'))
  )

  // const getLocation = () => {
  //   const email = JSON.parse(localStorage.getItem('moldKey'))?.email
  //   const url = `location/all?email=${email}`

  //   app_api
  //     .get(url)
  //     .then((res) => setLocation(res.data?.data || []))
  //     .catch((err) => {
  //       setError(err?.response?.data?.message || 'Error fetching locations')
  //     })
  //   setUserData(JSON.parse(localStorage.getItem('moldKey')))
  // }

  const getLocationByState = async () => {
    const url = `location/getLocationByState`
    let payload = {}
    if (selectedState) {
      payload.state = selectedState
    }

    await app_api
      .post(url, payload)
      .then((res) => {
        console.log(res)
        setLocationData(res.data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Error fetching locations')
      })
  }

  const formatNumber = (num) => {
    const numericValue = parseFloat(num)
    return !isNaN(numericValue) ? numericValue.toFixed(2) : '0.00'
  }

  const [termsData, setTermsData] = useState([])
  const [term, setTerm] = useState()

  const getTermData = async () => {
    let url = `/terms-master/getAll`
    let payload = {}
    if (selectedState) {
      payload.selected_location_id = null
      payload.state = selectedState
    }
    if (locationFilter && locationFilter.length) {
      payload.state = null
      payload.selected_location_id = locationFilter.map((loc) => loc.value)
    }
    await app_api
      .post(url, payload)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setTermsData(res.data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const [managerLocation, setManagerLocation] = useState([])

  const [stateData, setStateData] = useState()
  const getStateDataByBackend = async () => {
    await app_api
      .post('/state-city/state')
      .then((res) => {
        setError(null)
        let data = res.data
        setStateData(
          data.map((item, index) => {
            return { label: item.name, value: item.name }
          })
        )
        if (userData.role == 'admin') {
          data.map((item, index) => {
            if (item.name == 'Victoria') {
              return setSelectedState([{ label: item.name, value: item.name }])
            }
          })
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const [selectedState, setSelectedState] = useState([
    { label: 'Victoria', value: 'Victoria' },
  ])
  const getStateByManager = async () => {
    const userData = JSON.parse(localStorage.getItem('moldKey'))
    let url = `/location/getStateByLocation`
    let payload = {
      location_id: managerLocation,
      manager_id: userData.id,
    }

    await app_api
      .post(url, payload)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setSelectedState([{ value: res[0].state, label: res[0].state }])
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getDashboardData = async () => {
    setLoading(true)
    const moldKey = JSON.parse(localStorage.getItem('moldKey'))
    const { role, id: user_id, locationId: location_id } = moldKey || {}
    const payload = { role }

    if (role === 'manager') {
      payload.user_id = user_id
      payload.location_id = location_id
    }

    if (selectedState) {
      payload.selected_location_id = null
      payload.state = selectedState
    }

    if (term) {
      payload.term = term
    }

    if (startDate && endDate) {
      payload.start_date = moment(startDate).format('DD-MM-YYYY')
      payload.end_date = moment(endDate).format('DD-MM-YYYY')
    }

    if (locationFilter && locationFilter.length) {
      payload.state = null
      payload.selected_location_id = locationFilter.map((loc) => loc.value)
    }

    if (
      (selectedState && selectedState.length) ||
      (locationFilter && locationFilter.length)
    ) {
      await app_api
        .post('dashboard/getDashboardData', payload)
        .then((res) => {
          setLoading(false)
          setDashboardData(res.data)
        })
        .catch((err) => {
          setLoading(false)
          setError(
            err?.response?.data?.message || 'Error fetching dashboard data'
          )
        })
    }
  }
  useEffect(() => {
    if (managerLocation && managerLocation.length > 0) {
      getStateByManager()
    }
  }, [managerLocation])

  useEffect(() => {
    if (userData && userData.role && userData.role == 'manager') {
      setManagerLocation(userData.locationId)
    }
  }, [userData])

  useEffect(() => {
    getStateDataByBackend()
  }, [])

  const handleClearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setLocationFilter([])
    setSelectedState([{ value: 'Victoria', label: 'Victoria' }])
    setTerm(null)
    setIsFilterSidebarOpen(false)
  }

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsFilterSidebarOpen(false)
    }
  }

  useEffect(() => {
    if (isFilterSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isFilterSidebarOpen])

  useEffect(() => {
    setDashboardData(null)
    getDashboardData()
  }, [startDate, endDate, locationFilter, selectedState, term])

  useEffect(() => {
    if (selectedState && selectedState.length !== 0) {
      getLocationByState()
      getTermData()
    }
  }, [selectedState])

  return (
    <Sidebar>
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl mt-3 font-semibold text-gray-900">
            Dashboard{' '}
            {(!locationFilter || locationFilter.length == 0) && selectedState && selectedState.length !== 0
              ? `- ${selectedState[0].label}`
              : locationFilter && locationFilter.length !== 0
              ? `- ( ${locationFilter
                  .map((data) => {
                    return data.label
                  })
                  .join(', ')} )` : ""}          
          </h1>
          {!isFilterSidebarOpen && (
            <div
              onClick={() => setIsFilterSidebarOpen((prev) => !prev)}
              className="rounded-full py-2 px-2 w-10 cursor-pointer shadow-sm bg-[#08285b] text-white"
            >
              <CiFilter className="w-6 h-6" />
            </div>
          )}
        </div>
        {loading && (!dashboardData || dashboardData.length == 0) ? (
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
              {dashboardData && dashboardData.length ? (
                dashboardData
                  .filter((term) => term.isPrevTerm || term.isActiveTerm)
                  .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
                  .reverse()
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      <h4 className="text-2xl mt-3 font-bold text-gray-900 col-span-full">
                        {item.name} |{' '}
                        {moment(item.startDate).format('DD-MM-YYYY')} To{' '}
                        {moment(item.endDate).format('DD-MM-YYYY')}
                      </h4>
                      <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                        Leads
                      </h4>
                      <DataCard
                        name="Total Leads"
                        count={item.leadsCount.total_leads || 0}
                        url="/leads"
                      />
                      <DataCard
                        name="Total Enrolled"
                        count={item.leadsCount.total_enrolled || 0}
                        url="/leads"
                      />
                      <DataCard
                        name="Total Prospective"
                        count={item.leadsCount.total_prospective || 0}
                        url="/leads"
                      />
                      <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                        Invoice
                      </h4>
                      <DataCard
                        name="Total Invoice Created"
                        count={item.total_invoice_created || 0}
                        url="/leads"
                      />
                      <DataCard
                        name="Total Pending Invoice"
                        count={item.pendingInvoice || 0}
                        url="/leads"
                      />
                      <DataCard
                        name="Total Active Students"
                        count={item.activeStudensData || 0}
                        url="/leads"
                      />
                      <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                        Payments
                      </h4>
                      <DataCard
                        name="Total Amount Payable"
                        count={`$ ${formatNumber(
                          item.paymentData.total_amount_payable -
                            (item.paymentData.total_discount || 0)
                        )}`}
                      />
                      <DataCard
                        name="Total Paid Amount"
                        count={`$ ${formatNumber(
                          item.paymentData.total_paid_amount
                        )}`}
                      />
                      <DataCard
                        name="Total Pending Amount"
                        count={`$ ${formatNumber(
                          item.paymentData.total_amount_payable -
                            (item.paymentData.total_discount || 0) -
                            (item.paymentData.total_paid_amount || 0)
                        )}`}
                      />
                    </React.Fragment>
                  ))
              ) : (
                <React.Fragment>
                  <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                    Leads
                  </h4>
                  <DataCard name="Total Leads" count={0} url="/leads" />
                  <DataCard name="Total Enrolled" count={0} url="/leads" />
                  <DataCard name="Total Prospective" count={0} url="/leads" />
                  <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                    Invoice
                  </h4>
                  <DataCard
                    name="Total Invoice Created"
                    count={0}
                    url="/leads"
                  />
                  <DataCard
                    name="Total Pending Invoice"
                    count={0}
                    url="/leads"
                  />
                  <DataCard
                    name="Total Active Students"
                    count={0}
                    url="/leads"
                  />
                  <h4 className="text-xl mt-3 font-bold text-gray-900 col-span-full">
                    Payments
                  </h4>
                  <DataCard name="Total Amount Payable" count={`$ 0`} />
                  <DataCard name="Total Paid Amount" count={`$ 0`} />
                  <DataCard name="Total Pending Amount" count={`$ 0`} />
                </React.Fragment>
              )}
            </div>
          </>
        )}
        {isFilterSidebarOpen && (
          <div className="h-full" ref={sidebarRef}>
            <div className="absolute bg-white border rounded-lg shadow-lg w-[90%] sm:w-[90%] md:w-[50%] lg:w-[40%]  top-[5%] md:top-[10%] right-[5%] z-50 p-2">
              <div className="flex justify-between mb-2">
                <span className="text-sm text-gray-700">Filters</span>
                <span
                  className="text-sm text-indigo-700 cursor-pointer"
                  onClick={handleClearFilters}
                >
                  Reset All
                </span>
              </div>
              <hr />
              {/* <div className="w-full flex flex-col p-2">
                <div className="flex justify-between mb-2">
                  <span className="text-sm text-gray-700">Date Range</span>
                  <span
                    className="text-sm text-indigo-700 cursor-pointer"
                    onClick={() => setStartDate(null) && setEndDate(null)}
                  >
                    Reset
                  </span>
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={(dates) => {
                    const [start, end] = dates
                    setStartDate(start)
                    setEndDate(end)
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  isClearable
                  maxDate={new Date()}
                  dateFormat="MMM dd, yyyy"
                  placeholderText="Select Date Range"
                  className="text-center block py-2 px-2 border rounded-md w-full"
                />
              </div>
              <hr className="" /> */}
              {userData.role == 'admin' && (
                <>
                  <div className="w-full p-2">
                    <div className="flex justify-between mb-2">
                      <span className=" text-sm text-gray-700 ">State</span>
                      <span
                        className="text-sm text-indigo-700 cursor-pointer"
                        onClick={() => {
                          setSelectedState(null)
                          setTerm(null)
                          setLocationFilter(null)
                        }}
                      >
                        Reset
                      </span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="state"
                      id="state"
                      isSearchable
                      loadingMessage="Getting State..."
                      placeholder="State"
                      options={stateData?.map((l) => ({
                        label: l.label,
                        value: l.value,
                      }))}
                      onChange={(option) => {
                        if (option) {
                          setSelectedState([option])
                          setLocationFilter(null)
                          setTerm(null)
                        }
                      }}
                      value={selectedState ? selectedState[0] : null}
                    />
                  </div>
                </>
              )}
              <hr />
              <div className="w-full p-2">
                <div className="flex justify-between mb-2">
                  <span className="text-sm text-gray-700">Location</span>
                  <span
                    className="text-sm text-indigo-700 cursor-pointer"
                    onClick={() => {
                      setLocationFilter([])
                    }}
                  >
                    Reset
                  </span>
                </div>
                <Select
                  options={locationData.map((l) => ({
                    label: l.name,
                    value: l.id,
                  }))}
                  isMulti
                  isClearable
                  isDisabled={!selectedState || selectedState.length == 0}
                  value={locationFilter ? locationFilter : null}
                  onChange={(option) => {
                    if (locationFilter && locationData.length == 0) {
                      setLocationFilter(option ? [option] : [])
                    } else {
                      setLocationFilter(option)
                    }
                    setTerm(null)
                  }}
                  placeholder="Select Location"
                  className="w-full"
                />
              </div>
              <hr className="" />
              <div className="w-full p-2">
                <div className="flex justify-between mb-2">
                  <span className=" text-sm text-gray-700 ">Term</span>
                  <span
                    className="text-sm text-indigo-700 cursor-pointer"
                    onClick={() => {
                      setTerm(null)
                    }}
                  >
                    Reset
                  </span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="term"
                  id="term"
                  isSearchable
                  loadingMessage="Getting Term..."
                  placeholder="Term"
                  isDisabled={!selectedState || selectedState.length == 0}
                  options={termsData?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setTerm(option)
                  }}
                  value={term}
                />
              </div>
              <hr />
              <div className="flex justify-between gap-2 p-2">
                <Button
                  onClick={handleClearFilters}
                  className="bg-red-500 hover:bg-red-700"
                >
                  Reset All
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Sidebar>
  )
}
